import { getInstituition } from "../pages/configuration/http";
import removeAccents from "../utils/removeAccents";


export const fetchInstitutionInsurances = async (currentHealthPlaceUser, setHealthInsuranceAllOptions, setHealthInsurancesResult) => {
        if (currentHealthPlaceUser?.health_place?.id) {
          try {
            const params = { id: currentHealthPlaceUser?.health_place?.id }
            const inst = await getInstituition(params)
            setHealthInsuranceAllOptions(inst?.[0]?.health_insurances)
            setHealthInsurancesResult(inst?.[0]?.health_insurances)
          } catch (err) {
            console.error('Failed to fetch institution: ', err)
            setHealthInsuranceAllOptions([])
            setHealthInsurancesResult([])
          }
        }
      }

      export const handleSearchHealthInsurance = (event, insuranceOptions, setFilteredOptions) => {
        const searchValue = removeAccents(event.target.value.toUpperCase().trim());
    
        if (!insuranceOptions || !Array.isArray(insuranceOptions)) return;
    
        let timeout = setTimeout(() => {
            const filteredInsurenceOptions = insuranceOptions.filter(hi =>
                removeAccents(hi.name.toUpperCase()).includes(searchValue)
            );
    
            setFilteredOptions(filteredInsurenceOptions);
        }, 400);
    
        return () => clearTimeout(timeout);
    };