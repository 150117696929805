import api from '../../helpers/api'
import { getParams } from '../../utils'

export async function getInstituition(e) {
	let params = `?`
	e = { ...e, limit: e?.limit ? e.limit : 20, offset: e?.offset ? e.offset : 0 }
	Object.keys(e).map((par, i) => {
		params = `${i === 0 ? params : params + '&'}${par}=${e[par]}`
	})

	const url = `health_place/config/${params}`
	const response = await api.get(url)
	return response.data.results
}

export async function patchInstitution(institution_id, payload) {
	const url = `health_place/config/${institution_id}/`
	const response = await api.patch(url, payload)
	return response
}

export async function putInstituiton(e) {
	const url = `health_place/config/${e.id}/`
	await api.put(url, e.paramsValue)
}

export async function getPersonMySelf() {
	const url = 'person/?myself=true'
	return await api.get(url)
}

export async function putMySelfProfileImg(payload={}) {
	const url = 'health_place_user/update/profile_img/'
	return await api.put(url, payload)
}

export async function patchHealthPlace(health_place_id, payload) {
	const url = `health_place/${health_place_id}/`
	return await api.patch(url, payload)
}

export async function putHealthPlaceLogo(payload={}) {
	const url = 'health_place/update/health_place_logo/'
	return await api.put(url, payload)
}

export async function getHealthPlaceBranches(params) {
	const url = `health_place/branch/${getParams(params)}`
	return await api.get(url)
}

export async function postHealthPlaceBranch(payload) {
	const url = 'health_place/branch/'
	return await api.post(url, payload)
}

export async function createHealthPlaceBranch(payload) {
	const url = 'health_place/create/health_place_branch/'
	return await api.post(url, payload)
}

export async function checkIfHealthPlaceExists(params) {
	const url = `health_place/check-health-place/${getParams(params)}`
	return await api.get(url)
}

export async function patchHealthPlaceBranch(health_place_id, payload) {
	const url = `health_place/branch/${health_place_id}/`
	return await api.patch(url, payload)
}

export async function postCouncil(payload) {
	const url = '/health_place_user/council/'
	return await api.post(url, payload)
}

export async function patchCouncil(id, payload) {
	const url = `/health_place_user/council/${id}/`
	return await api.patch(url, payload)
}

export async function getHealthInsurances(params={}, url = null) {
	const endpoint = url ? url : 'health-plan/insurance/'
	const response = await api.get(`${endpoint}${params}`)

	return response
}

export async function getHealthInsurancesWithName(name) {
	const response = await api.get(`health-plan/insurance/?name=${name}&limit=20`)
	
	return response
}