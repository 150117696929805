import './AppointmentsSchedule.css'
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../../../../../../helpers/api';
import { IcoEstetoscopio } from '../../../../../../component';
import IcoQuickly from '../../../../../../component/icon/quickly';
import IcoSchedule from '../../../../../../component/icon/schedule';
import { PatientIcon } from '../../../../../../component/icon/patient';
import Button from '../../../../../../newcomponents/button/button';
import NextAppointmentInfo from './NextAppointmentInfo';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import choices from './choices.json'
import AppointmentsSummaryDoughnut from './AppointmentsDoughnutSummary';
import { getFormatedTimeFromDate } from '../../../../../AttendanceManager/helpers';
import { getAttendancesHistory } from '../../../../../AttendanceManager/http';
import { formatDateWithTimezoneFromDateObj } from '../../../../../../utils/convertDate';
import { useApp } from '../../../../../../layout/App';
import NoteCheckIco from '../../../../../../component/icon/note_check';


export default function AppointmentsSchedule({ setIsLoading, currentHealthPlace }) {
    const [appointmentsOfTheDay, setAppointmentsOfTheDay] = useState([]);
    const [activeScheduledAppointments, setactiveScheduledAppointments] = useState([])
    const [healthProfessionalId, setHealthProfessionalId] = useState(null);
    const history = useHistory()
    const {isArrivalFilter, setIsArrivalFilter } = useApp()
    

    const DECISIONS_WITH_TIME = [
        "OPEN","WAIT","GO_PLACE", "CHECK_IN", "WAITING_FOR_SCREENING", "SCREENING_STARTED", "SCREENING_COMPLETED", "SCHEDULE_CONFIRMED"
    ]

    const fetch_params = (ordering, decision) => {
        return ({classification__in: null,
        decision__in: decision,
        doctor__id__in: healthProfessionalId,
        health_insurance__id__in: null,
        name_or_cpf: "",
        offset: 0,
        ordering: ordering,
        person__address__city__in: null,
        person__birthday__gte: null,
        person__birthday__lte: null,
        priority__in: null,
        related_diseases_code: null,
        result__in: null,
        service_location__id__in: null,
        specialty__id__in: null,
        start__gte: null,
        start__lte: null})
    };
    
    const location = useLocation();

    const getHealthProfessionalId = () => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('health_place_user__user__person');
    };

    // Fetch for appointments in waiting room
    const fetchAttendancesHistory = async (params, filters) => {
            params = { 
                offset: 0,
                ...filters, 
                ...params, 
                limit: 18,
                has_priority: true,
                health_place__id: currentHealthPlace.id,
                start__gte: formatDateWithTimezoneFromDateObj(new Date(), '00', '00'),
                start__lte: formatDateWithTimezoneFromDateObj(new Date(), '23', '59'),
            }
    
            try {
                let response = await getAttendancesHistory(params)
                return response.data.results
            } catch (err) {
                console.error('fetchAttendancesHistory ~ ', err)
            }
        }

    // Fetch for appointments in agenda
    // const fetchFilledTimesOfTheDay = async () => {
    //     const today = new Date();
    //     const year = today.getFullYear();
    //     const month = String(today.getMonth() + 1).padStart(2, '0');
    //     const day = String(today.getDate()).padStart(2, '0');
    //     const date = `${year}-${month}-${day}`;

    //     try {
    //         const res = await api.get(`schedule/doctor/${healthProfessionalId}/day/?date=${date}`);
    //         return res.data || [];
    //     } catch (error) {
    //         console.error('Erro ao buscar horários preenchidos:', error);
    //         return [];
    //     }
    // };

    const setScheduleInfo = async () => {
        setIsLoading(true)
        
        let appointments = await fetchAttendancesHistory(fetch_params('start', 'SCHEDULE,OPEN,WAIT,GO_PLACE,CHECK_IN,SCHEDULE_CONFIRMED'));
        let allAppointments = await fetchAttendancesHistory()
        if (!Array.isArray(appointments) || !Array.isArray(allAppointments)) {
            console.error('Dados inválidos recebidos:', appointments);
            return;
        }

        allAppointments.filter(allAppointment => allAppointment?.health_place?.id == currentHealthPlace.id)
        setAppointmentsOfTheDay(allAppointments);

        let healthPlaceAppointments = appointments.filter(appointment => appointment?.health_place?.id == currentHealthPlace.id)

        if (isArrivalFilter) {
            appointments = await fetchAttendancesHistory(fetch_params('decision_datetime', 'WAIT'));
            healthPlaceAppointments = appointments.filter(appointment => appointment?.health_place?.id == currentHealthPlace.id)
        } 
        
        const excludedStatuses = ['CLOSE', 'CANCEL', 'ABSENT'];

        const activeScheduledAppointments = healthPlaceAppointments.filter(
            appointment => !excludedStatuses.includes(appointment.decision)
        );
        setactiveScheduledAppointments(activeScheduledAppointments);
        
        setIsLoading(false)
    };

    const redirectToAgenda = () => {
        const currentUrl = new URL(window.location.href); 
        const searchParams = currentUrl.search; 

        const newPath = '/atendimentos/agendamento';
        const newUrl = `${newPath}${searchParams}`;

        history.push(newUrl);
    };

    const mapFromChoices = (choices, id) => {
        const item = choices.find(choice => choice.id === id);
        return item || null;
    };

    // useEffect para capturar o ID e buscar dados
    useEffect(() => {
        const id = getHealthProfessionalId();
        setHealthProfessionalId(id);
    }, [location]);

    useEffect(() => {
        if (healthProfessionalId) {
            setScheduleInfo();
        }
    }, [healthProfessionalId, isArrivalFilter]);

    return (
        <div className='Appointments-Info'>
            {/* <div className='Appointments-Info-Header'>
                <p className='Appointments-Info-Header-Title'>Agendamentos:</p>
            </div> */}
            <div className='NextAppointments'>

                <div className='NextAppointments-Chart'>
                    <div className='NextAppointments-Charts-Header'>
                        <div className='NextAppointments-Charts-Header-Title-Container'>
                        <p className='NextAppointments-Charts-Header-Title'>Próximos Atendimentos:</p>
                            <div className='NextAppointments-Charts-Header-NumberBox'>
                                <p>{activeScheduledAppointments.length}</p>
                            </div>
                        </div>
                        <div className='NextAppointments-Charts-Header-Actions'>
                            <div className='NextAppointments-Charts-Header-Toggle-ArrivalFilter'
                                style={{
                                    border: isArrivalFilter ? '2px solid var(--primary-color)' : ''
                                }}
                                onClick={() => setIsArrivalFilter(!isArrivalFilter)}
                                title={isArrivalFilter ? 'Desativar filtro por ordem de chegada' : 'Ativar filtro por ordem de chegada'} 
                                >
                                <NoteCheckIco  
                                    width="20px" 
                                    height="20px" 
                                    style={{
                                        stroke: isArrivalFilter ? 'var(--primary-color)' : ''
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                    <div className='NextAppointments-Container'>
                        {activeScheduledAppointments.length > 0 ?
                            activeScheduledAppointments.map(appointment => {
                                const decisionData = mapFromChoices(choices.DECISION_CHOICES, appointment.decision);
                                
                                const decisionTime = getFormatedTimeFromDate(appointment?.decision_datetime)
                                let status = decisionData.name
                                if (DECISIONS_WITH_TIME.includes(decisionData.id)) {
                                        const decisionTime = getFormatedTimeFromDate(appointment?.decision_datetime) || ''
                                    status = `${decisionData.name} às ${decisionTime}`
                                }
                            
                                const classificationData = mapFromChoices(choices.CLASSIFICATION_CHOICES, appointment.classification);
                                const resultData = mapFromChoices(choices.RESULT_CHOICES, appointment.result)

                                return (

                                    <div className='Appointment-Item'>
                                        <div className='Appointment-Item-IconBox'>
                                            <IcoEstetoscopio style={{ fill: 'var(--primary-font-color)', fontSize: '21px' }} />
                                        </div>
                                        <div className='Appointment-Item-Info'>
                                            <div className='Appointment-Item-PatientInfo'>
                                                <p className='Appointment-Item-PatientName'>{appointment.person.name}</p>
                                            </div>
                                            <div className='Appointment-Item-AttendanceInfo'>
                                                <div className='Appointment-Item-TimeScheduled-Item'>
                                                    <div className='Appointment-Item-TimeScheduled-IcoBox'>
                                                        <IcoQuickly></IcoQuickly>
                                                    </div>
                                                    <p>{
                                                        new Date(appointment.start)
                                                            .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                                    }</p>
                                                    -
                                                    <p>{
                                                        new Date(appointment.end)
                                                            .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                                    }</p>
                                                </div>
                                                <div className='Appointment-Item-AttendanceInfo-Item'>
                                                    <p>
                                                        {classificationData.name}
                                                    </p>
                                                </div>
                                                <div className='Appointment-Item-AttendanceInfo-Item'>
                                                    <p>
                                                        {resultData.name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <p className={`Appointment-Item-AttendanceInfo-Status`} style={{ color: decisionData.color, backgroundColor: decisionData.secondColor }}>
                                                {status}
                                            </p>
                                        </div>
                                    </div>

                                )
                            }) : <div className='NoNextAppointments-Container'>
                                <PatientIcon style={{ width: '2.5rem', fill: '#aaa' }} ></PatientIcon>
                                <p className='NoNextAppointments-Message'>{isArrivalFilter? 'Nenhum paciente aguardando atendimento' : 'Nenhum atendimento agendado para hoje'}</p>
                                <Button disabled={false} label='Ver agenda completa' onClick={redirectToAgenda}></Button>
                            </div>
                        }
                    </div>
                </div>
                    <NextAppointmentInfo activeScheduledAppointments={activeScheduledAppointments} mapFromChoices={mapFromChoices}></NextAppointmentInfo>
            </div>
            {/* <div className='Appointments-Summary-Chart'>
                    <div className='Appointments-Summary-Chart-Header'>
                    <p className='Appointments-Summary-Chart-Header-Title'>Resumo:</p>
                    </div>
                    <div className='Appointments-Summary-Info'>
                    <div className='Appointments-Summary-Info-Item total'><IcoSchedule /><span>{totalScheduleTimes} </span> Total de horários na agenda</div>
                    <div className='Appointments-Summary-Info-Item schedule'><IcoSchedule /><span>{appointmentsOfTheDay.length} </span> Total de atendimentos agendados</div>
                    <div className='Appointments-Summary-Info-Item available'><IcoSchedule /><span>{openScheduleTimes} </span> Horário(s) em aberto</div>
                    <div className='Appointments-Summary-Info-Item close'><IcoSchedule /><span>{closedAppointments} </span> Atendimento(s) finalizado(s)</div>
                    </div>
                    </div> */}
            <div className='Appointments-Statistic'>
                {appointmentsOfTheDay ? <AppointmentsSummaryDoughnut appointments={appointmentsOfTheDay}></AppointmentsSummaryDoughnut> : <div></div>}

            </div>

        </div>

    );
}
