import './LogsAppointments.css'
import React from "react";
import { Input, List } from "../../../../../../../../component";
import { formatISO8601Str } from '../../../../../../../../utils/convertDate';
import NewSelect from '../../../../../../../../newcomponents/form/select/select';

const headDefault = [
    { colunm: 'h_name', text: 'Nome', width: '300px' },
    { colunm: 'h_content', text: 'Conteúdo' },
    { colunm: 'h_date', text: 'Data e Hora', width: '180px' },
]

export default function LogsAppointments({ openSection, setOpenSection=() => null }) {
    
    return <div>
        <div className='LogsAppointmentsBookSection-Header'>
            <div className='LogsAppointmentsBookSection-Header-Tabs'>
                <div
                    className={`LogsAppointmentsBookSection-Header-Tabs-Btn ${openSection === 'appointments' ? 'active' : ''}`} 
                    onClick={() => setOpenSection('appointments')}
                >
                    Atendimentos
                </div>
                <div
                    className={`LogsAppointmentsBookSection-Header-Tabs-Btn ${openSection === 'records' ? 'active' : ''}`} 
                    onClick={() => setOpenSection('records')}
                >
                    Prontuário
                </div>
            </div>
            <div className='LogsAppointmentsBookSection-Header-Filters'>
                <NewSelect 
					label=''
					id='paginating'
					onSelect={(event) => null}
					selected={''}
					options={[]}
					canBeEmpty={false}
                />
                <Input
					label=''
					name='start__gte'
					placeholder='DD/MM/YYYY'
					actionFocus={() => null}
					value={formatISO8601Str(new Date())}
				/>
                <span> Para </span>
                <Input
					label=''
					name='start__lte'
					placeholder='DD/MM/YYYY'
					actionFocus={() => null}
					value={formatISO8601Str(new Date())}
				/>
            </div>
        </div>
        <div className='LogsAppointmentsBookSection-Body'>
            <List 
                head={headDefault}
                data={[]}
                noData='Sem registros'
                listCustom={(instance, i) => {
                    instance['h_date'] = '-'
                    instance['h_action'] = '-'

                    return instance
                }}
            />
        </div>
    </div>
}