import './MaterialAssignment.css';
import { useEffect, useState, useRef } from "react";
// import { Input, AddAlert, AlertType } from "../../../../../../../../component";
import { Input, AddAlert, AlertType } from '../../../../../../../../../../component';
import NewSelect from "../../../../../../../../../../newcomponents/form/select/select";
import NewButton, { ButtonModel } from "../../../../../../../../../../newcomponents/button/button";
import { getInventoryMaterialLocations } from '../../../../../ConfigInventoryMaterials/subcomponents/LocationsOptions/http';
import { getHealthPlacePatients } from '../../../../../../../../../pacientes/http';
// import { MaterialFlowExitForm } from '../../../InsertInventoryMaterialsModal/helpers';
import { MaterialAssignmentForm } from './helper';
import { batch, useDispatch } from 'react-redux';
import { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal';
import { loaded, loading } from '../../../../../../../../../../layout/redux/AppActions';
import { postInventoryMaterialAssignment } from '../../../../../../http';
import { useApp } from '../../../../../../../../../../layout/App';
import { IcoClose } from '../../../../../../../../../../component';
import IcoBoxReady from '../../../../../../../../../../component/icon/box_ready';


const defaultOptions = require('../../../../../../../../../../component/form/select/options.json');

const itemReasonExitOptions = [
    { id: 'allocation_to_patient', name: 'Alocação para paciente' },
    { id: 'sale', name: 'Venda' },
    { id: 'return', name: 'Devolução' },
    { id: 'transfer', name: 'Transferência' },
    { id: 'expiry', name: 'Vencimento' },
    { id: 'drug_stability', name: 'Estabilidade da droga' },
    { id: 'waste_breakage', name: 'Quebra ou desperdício' },
    { id: 'External_loan', name: 'Empréstimo externo' },
];

export default function MaterialAssignment({ batchInfo,  onSubmit = () => {}, closeForm}) {
    
    const { currentHealthPlaceUser } = useApp();
    console.log(batchInfo, 'logg');
    
    const dispatch = useDispatch();
    
    const [materialItem, setMaterialItem] = useState({
        requested_amount: 0,
        reason: '',
        invoice: '',
        patient: '',
        justification: '',
    });

    const [materialItemErr, setMaterialItemErr] = useState({});
    const [patientsOptions, setPatientsOptions] = useState([]);

    const searchTimeout = useRef(null);

    useEffect(() => {
        fetchPatientsList();
    }, []);


    const fetchPatientsList = async (params = {}) => {
        try {
            let res = await getHealthPlacePatients({ ...params });
            setPatientsOptions(res.data.results.map(patient => ({
                id: patient.id,
                name: `${patient.name} | ${patient.document_type === 'CPF' ? patient.cpf || 'Documento não cadastrado' : patient.rg || 'Documento não cadastrado'}`,
            })));
        } catch (err) {
            setPatientsOptions([]);
            console.error('fetchPatientsList', err);
        }
    };

    const handleChange = (event) => {
        let value = event.target.value;
        if (event.target.name === 'requested_amount') {
            value = value === "" ? 0 : Number(value);
            if (value < 0) value = 0;
        }
        setMaterialItem(prev => ({ ...prev, [event.target.name]: value }));
        setMaterialItemErr(prev => ({ ...prev, [event.target.name]: false }));
    };

    const handleSelect = (event) => {
        setMaterialItem(prev => ({ ...prev, [event.target.id]: event.target.selected }));
        setMaterialItemErr(prev => ({ ...prev, [event.target.id]: false }));
    };


    const handleSubmit = async () => {
        let payload = new MaterialAssignmentForm({
            ...materialItem,
            health_place: currentHealthPlaceUser?.health_place?.id,
            material: batchInfo?.material?.id,
            material_batch: batchInfo?.id,
            health_place_user: currentHealthPlaceUser.id,
        });

        console.log('paylaod',  payload);
        
        if (batchInfo.remaining < materialItem.requested_amount) {
            const errors = {requested_amount: `Quantidade indisponível.`}
            return setMaterialItemErr(errors);
        }

        if (!payload.isValid()) {
            const errors = payload.getErrors();
            console.log(errors , 'erros');
            
            if (Object.keys(errors).length) return setMaterialItemErr(errors);
        }

        dispatch(loading());
        
        try {
            await postInventoryMaterialAssignment(payload);
            dispatch(AddAlert('Fluxo de Materiais', 'Requisição de material feita com sucesso!', AlertType.SUCCESS));
            onSubmit();
        } catch (err) {
            console.error('handleSubmit ~ ', err);
        }

        dispatch(loaded());
    };

    return (
            <div className='MaterialAssignment'>
                <div className='MaterialAssignment-Content'>

                    <div className='MaterialAssignment-Header'>
                        <p className='MaterialAssignment-Title'>Requisitar material:</p>
                        <div className='MaterialAssignment-Actions'>
                            {/* <div className='MaterialAssignment-SubmitBtn' title='Confirmar requisição' onClick={handleSubmit}>
                                <IcoBoxReady/>
                            </div> */}
                            <div className='MaterialAssignment-CloseBtn' onClick={closeForm} title='Cancelar requisição'>
                                <IcoClose size={24} />
                            </div>
                        </div>
                    </div>
                    <div className='MaterialAssignment-Form'>

                        <div className='MaterialAssignment-Input MaterialAssignment-Input-W'>
                            <span><b className='MaterialAssignment-Asterisk'>*</b> Motivo</span>
                            <NewSelect 
                                id='reason'
                                onSelect={handleSelect}
                                selected={materialItem.reason}
                                options={itemReasonExitOptions}
                                optionStrKey='name'
                                error={materialItemErr.reason}
                            />
                        </div>
                        <div className='MaterialAssignment-Input MaterialAssignment-Input-W'>
                            <span><b className='MaterialAssignment-Asterisk'>*</b> Quantidade <i className='MaterialAssignment-Italic'>(max {batchInfo.remaining} {batchInfo.remaining > 1 ? 'unidades' : 'unidade'})</i></span>
                            <Input
                                name='requested_amount'
                                action={handleChange}
                                value={materialItem.requested_amount}
                                errors={{ error: materialItemErr, message: materialItemErr.requested_amount }}
                                type='number'
                                min='0'
                                max={'10000000'}
                                placeholder='Ex.: 10, 25, 50'
                            />
                        </div>
                        <div className='MaterialAssignment-Input MaterialAssignment-Input-W'>
                            <span> NF associada</span>
                            <Input
                                name='invoice'
                                action={handleChange}
                                value={materialItem.invoice}
                                errors={{ error: materialItemErr, message: materialItemErr.invoice }}
                            />
                        </div>

                        {materialItem?.reason === 'allocation_to_patient' && (
                            <div className='MaterialAssignment-Input MaterialAssignment-Input-W'>
                                <span>Paciente</span>
                                <NewSelect id='patient' options={patientsOptions} selected={materialItem.patient} onSelect={handleSelect} error={materialItemErr.patient} />
                            </div>
                        )}
                    </div>

                    <div className='MaterialAssignment-Input MaterialAssignment-Input-FullRow'>
                        <span>Observações</span>
                        <Input
                            name='justification'
                            action={handleChange}
                            value={materialItem.justification}
                        />
                    </div>
                </div>
                <div className='MaterialAssignment-Footer'>
                    <NewButton label='Confirmar requisição' onClick={handleSubmit} model={ButtonModel.PRIMARY} />
                </div>
            </div>
    );
}
