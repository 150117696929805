import './WaitingRoomAttendancesFilters.css'
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { AddAlert, AlertType, Input, MaskDataHora, adicionaZero } from '../../../../../../component';
import { uniqueIDs } from '../../../../../../utils/uniqueIDs';
import { stringifyCollaboratorOption } from '../../../../../../utils/stringifyCollaboratorOption';
import { getParams } from '../../../../../../utils';
import { getHealthInsurances } from '../../../../../configuration/http';
import { getSpecialties } from '../../../../../agenda/SelectScheduleHealthPlaceUserManager/http';
import { useApp } from '../../../../../../layout/App';
import IcoCleanFilter from '../../../../../../component/icon/cleanFilter';
import  Modal, { MODAL_INIT_PROPS } from '../../../../../../newcomponents/modal/modal';
import CalendarDateModal from '../../../../../../component/calendar/CalendarDateModal';
import { formatDateWithTimezoneFromDateObj, formatISO8601Str } from '../../../../../../utils/convertDate';
import NewMultiSelect from '../../../../../../newcomponents/form/select/multiselect';
import { getCollaborators } from '../../../../../collaborators/http';
import api from '../../../../../../helpers/api';
import { parseAppointmentsURLQuerysetParams } from '../../../../helpers';
import { uniqueCIDs } from '../../../../../consultation/subcomponents/ConsultationManager/subcomponents/SOAP/subcomponents/Assessment/helpers';
import { getCIDTen } from '../../../../../consultation/http';
import NewSelect from '../../../../../../newcomponents/form/select/select';
import { getAppointmentServiceLocation } from '../../../../../configuration/subcomponents/HealthUnitConfig/HealthPlaceInstitutionalMenu/http';
import NewAppointmentModal from '../../../AttendanceHistory/subcomponents/NewAppointmentModal/NewAppointmentModal';
import { PatientIcon } from '../../../../../../component/icon/patient';
import NoteCheckIco from '../../../../../../component/icon/note_check';

const defaultOptions = require('../../../../../../component/form/select/options.json')

export default function WaitingRoomAttendancesFilters({ filters=null, setFilters= () => null, fetchAttendancesHistory=() => null, setModalProps=() => null, INIT_WAITING_ROOM_APPOINTMENT_FILTERS }) {
    const dispatch = useDispatch()
	const { currentHealthPlaceUser, isHealthProfessionalOrIsNurse,isArrivalFilter, setIsArrivalFilter } = useApp()
	const searchTimeOut = useRef(null)
	const [serviceLocationOptions, setServiceLocationOptions] = useState([])
	const [citiesOptions, setCitiesOptions] = useState([])
	const [collaboratorsOptions, setCollaboratorsOptions] = useState([])
	const [healthInsurancesOptions, setHealthInsurancesOptions] = useState([])
    const [specialtiesOptions, setSpecialtiesOptions] = useState([])
	const [cidsOptions, setCidsOptions] = useState([])
	const [secondaryModal, setSecondaryModal] = useState(MODAL_INIT_PROPS)

	useEffect(() => {
		fetchCIDTenCodes()
		fetchServiceLocationsOptions({ offset: 0 })
		fetchCitiesOptions({ offset: 0 })
        fetchCollaborators({ offset: 0 })
		fetchHealthInsurancesOptions({ offset: 0 })
		fetchSpecialtiesOptions({ offset: 0 })
    }, [])

    const fetchCollaborators = async params => {
		await getCollaborators({
			...params,
			health_place__id: currentHealthPlaceUser?.health_place?.id,
			limit: 500,
			profile__in: 'DOCTOR%2CDENTIST%2CPSYCHOLOGIST%2CNUTRITIONIST%2CNURSE',
			has_person: true
		})
			.then(res => setCollaboratorsOptions(res.data.results.map(instance => stringifyCollaboratorOption(instance))))
			.catch(err => {
				console.error('fetchCollaborators', err)
				setCollaboratorsOptions([])
			})
	}
	
	const fetchSpecialtiesOptions = async (params={}) => {
        params = { ...params, limit: 50 }

        try {
            let res = await getSpecialties(params)

            setSpecialtiesOptions(res.data.results)
        } catch(err) {
            console.error('fetchSpecialtiesOptions ~ ', err)
        }
    }

	const fetchHealthInsurancesOptions = async (params={}) => {
		params = getParams({ ...params, limit: 50 })

		try {
			let res = await getHealthInsurances(params)
			setHealthInsurancesOptions(res.data.results)
		} catch (err) {
			console.error('fetchHealthInsurancesOptions ~ ', err)
			dispatch(AddAlert('Planos & convênios', 'Erro ao listar planos e convênios', AlertType.ERROR))
		}
	}

	const fetchCitiesOptions = async (params={}) => {
		params = getParams({ ...params, limit: 50 })

		try {
			let res = await api.get(`address/city/${params}`)
			setCitiesOptions(res.data.results)
		} catch (err) {
			console.error('fetchCitiesOptions ~ ', err)
			dispatch(AddAlert('Listar cidades', 'Erro ao obter cidades', AlertType.ERROR))
		}
	}

	const fetchCIDTenCodes = async (params) => {
        try {
            const cids = await getCIDTen(params)
            setCidsOptions(cids)
        } catch (err) {
            setCidsOptions([])
        }
    }

	const fetchServiceLocationsOptions = async (params={}) => {
        try {
            let res = await getAppointmentServiceLocation({...params, limit: 500 })
            setServiceLocationOptions(res.data.results)
        } catch (err) {
            setServiceLocationOptions([])
            console.error('fetchServiceLocationsOptions ~ ', err)
        }
    }

	const handleSearchCollaborators = (event) => {
		if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

		searchTimeOut.current = setTimeout(() => {
			fetchCollaborators({ 'name_cpf_or_email': event.target.value })
		}, 400, event.target.value)
	}

	const handleSearchHealthInsurance = (event) => {
		if (searchTimeOut.current) clearTimeout(searchTimeOut.current)
	
		searchTimeOut.current = setTimeout(() => {
			fetchHealthInsurancesOptions({ 'name': event.target.value })
		}, 400, event.target.value)
	}

	const handleSearchSpecialties = (event) => {
        if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

        searchTimeOut.current = setTimeout(() => {
            fetchSpecialtiesOptions({ 'name__icontains': event.target.value })
        }, 400, event.target.value)
    }

	const handleSearchCity = (event) => {
		if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

		searchTimeOut.current = setTimeout(() => {
			fetchCitiesOptions({ 'name': event.target.value })
		}, 400, event.target.value)
	}
	
	const handleSearchCIDs = (search) => {
        if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

        searchTimeOut.current = setTimeout((search) => {
            fetchCIDTenCodes({ search });
        }, 400, search)
    };

	const handleSearchServiceLocations = (event) => {
        if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

        searchTimeOut.current = setTimeout(() => {
            fetchServiceLocationsOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }

	const openCalendarDateModal = (filterKey, modalTitle) => {
		const date_cursor = filters?.[filterKey];
		const currentDate = date_cursor ? new Date(date_cursor) : new Date();
	
		const currentDay = String(adicionaZero(currentDate.getDate()));
		const currentMonth = String(adicionaZero(currentDate.getMonth() + 1)); // Months are zero-indexed
		const currentYear = String(currentDate.getFullYear());
		
		setSecondaryModal(prev => ({
			...prev,
			open: true,
			title: modalTitle,
			content: (
				<CalendarDateModal
					name={filterKey}
					currentDay={currentDay}
					currentMonth={currentMonth}
					currentYear={currentYear}
					renderNextSelectedDay={(dateEvent) => {
						handleSelectDateObj(filterKey, dateEvent);
						setSecondaryModal(MODAL_INIT_PROPS);
					}}
				/>
			)
		}));
	};
	
	const openCalendarBirthdayGteDateModal = () => {
		openCalendarDateModal('person__birthday__gte', 'De (Data de Nascimento)');
	};
	
	const openCalendarBirthdayLteDateModal = () => {
		openCalendarDateModal('person__birthday__lte', 'Até (Data de Nascimento)');
	};

	const handleSelectDateObj = (name, {yearSearchCursor, monthSearchCursor, daySearchCursor}) => {
		let nextDate = new Date(yearSearchCursor, parseInt(monthSearchCursor, 10) - 1, daySearchCursor)
		setFilters(prev => ({ ...prev, [name]: nextDate }))

		let formattedNextDate = name.endsWith('__gte') ? formatDateWithTimezoneFromDateObj(nextDate, '00', '00') :  formatDateWithTimezoneFromDateObj(nextDate, '23', '59')
		if (name.startsWith('person__birthday__')) {
			formattedNextDate = formattedNextDate.substring(0, 10)
		}

		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(() => {
				fetchAttendancesHistory({ offset: 0, [name]: formattedNextDate })

			}, 300)
		} catch (err) {
			console.error('FetchAttendancesHistory', err)
		}
	}

	const toggleOption = (loadKey, optionsList, fieldNameRef, optionRef) => {		
		const selectedOption = optionsList.find(option => option[fieldNameRef] === optionRef);
		const isExisting = filters?.[loadKey].find(option => option[fieldNameRef] === optionRef);
		
		let nextState = filters
		if (isExisting) {
			nextState = { ...filters, [loadKey]: filters[loadKey].filter(option => option[fieldNameRef] !== optionRef) }
		} else {
			nextState = { ...filters, [loadKey]: [...filters[loadKey], selectedOption] }
		}
		setFilters(nextState)

	};

	const handleSearch = async target => {
		let nextState = filters

		nextState = { ...filters, [target.name]: target.value }
		
		setFilters(nextState)

	}

	const handleClearFilters = () => {
		if (isArrivalFilter) {
			setArrivalModeFilters()
		} else {
			setFilters(INIT_WAITING_ROOM_APPOINTMENT_FILTERS)
		}
	}

	const handleToggleIsArrivalMode = () => {
		setIsArrivalFilter(prevIsArrivalFilter => {
			return !prevIsArrivalFilter;
		});
	};
	
	const setArrivalModeFilters = () => { 
		const currentUserFilter = isHealthProfessionalOrIsNurse ?
				[{
					id: currentHealthPlaceUser?.user?.person?.id,
					str: currentHealthPlaceUser?.user?.person?.str
				}]
				: []
			
				setFilters(INIT_WAITING_ROOM_APPOINTMENT_FILTERS)

				setFilters(prevFilters => ({
					...prevFilters,
					ordering: 'priority_order,decision_datetime',
					decision__in: defaultOptions.DECISION_CHOICES.filter(decision => decision.id === 'WAIT'),
					doctor__id__in: currentUserFilter
				}));
	}


	useEffect(() => {
		
		if (isArrivalFilter) {
			setArrivalModeFilters()
		} else {			
			setFilters(prevFilters => ({
				...prevFilters,
				ordering: 'priority_order,start',
				decision__in: isHealthProfessionalOrIsNurse? [
					{ id: "OPEN", name: "Aberto atendimento", color: "#fd7e14", secondColor: "#ffe5cc" },
					{ id: "WAIT", name: "Aguardando atendimento", color: "#856404", secondColor: "#fff3cd" },
				] : [
					{ id: "SCHEDULE", name: "Agendado atendimento", color: "#17a2b8", secondColor: "#e2f1f8" },
					{ id: "OPEN", name: "Aberto atendimento", color: "#fd7e14", secondColor: "#ffe5cc" },
					{ id: "WAIT", name: "Aguardando atendimento", color: "#856404", secondColor: "#fff3cd" },
					{ id: "GO_PLACE", name: "Check-in solicitado", color: "#6f42c1", secondColor: "#e7e1f3" },
					{ id: "CHECK_IN", name: "Check-in confirmado", color: "#2c7a7b", secondColor: "#e6fffa" },
					{ id: "SCHEDULE_CONFIRMED", name: "Confirmado agendamento"}
				]
			}));
		}
	}, [isArrivalFilter]);

	useEffect(() => {
		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(() => {
				fetchAttendancesHistory({ offset: 0, ...parseAppointmentsURLQuerysetParams(filters) })
			}, 300)
		} catch (err) {
			console.error('FetchAttendancesHistory', err)
		}
	},[filters])
		
	return <div>
		<Modal {...secondaryModal} dismissFn={() => setSecondaryModal(MODAL_INIT_PROPS)} />
        <section className='WaitingRoomAttendancesFilters-SideBarLeft'>
			<div className='WaitingRoomAttendancesFilters-ActionRow'>
				<div
					onClick={handleClearFilters}
					title="Limpar filtro"
				>
					<IcoCleanFilter />
				</div>
				<div 
					style={{
						border: isArrivalFilter ? '2px solid var(--primary-color)' : ''
					}}
					onClick={handleToggleIsArrivalMode}
					title={isArrivalFilter ? 'Desativar filtro por ordem de chegada' : 'Ativar filtro por ordem de chegada'} 
				>
					<NoteCheckIco  
						width="20px" 
						height="20px" 
						style={{
							stroke: isArrivalFilter ? 'var(--primary-color)' : ''
						}}
					/>
				</div>
				<div
					onClick={() =>
						setModalProps({
							open: true,
							title: `Novo atendimento | ${MaskDataHora(new Date())?.data} | ${MaskDataHora(new Date())?.hora}`,
							content: <NewAppointmentModal
								fetchAppointments={fetchAttendancesHistory}
								setModalInfo={setModalProps}
								initAppointment={ isArrivalFilter ? {
									classification: 'ROUTINE',
									decision: 'WAIT'
								}: {}
								}
								filters={filters}
							/>
						})
					}
					title='Novo atendimento'
				>
					<PatientIcon />
				</div>
			</div>


			<div className='WaitingRoomAttendancesFilters-Separator' />
			<div className='WaitingRoomAttendancesFilters-Selects'>
				<NewSelect
					label='Ordenar por'
                    id='ordering'
                    onSelect={(event) => handleSearch({ 'name': 'ordering', 'value': event.target.selected  })}
                    selected={filters.ordering}
                    options={defaultOptions.APPOINTMENT_ORDERING_OPTIONS}
					canBeEmpty={false}
					disabled={isArrivalFilter}
                />
				<Input
					label='Paciente'
					name='name_or_cpf'
					action={event => handleSearch(event.target)}
					autoComplete='false'
					placeholder='Filtrar por Nome | CPF | RG'
				/>
				<NewMultiSelect
					label='Profissionais'
					filterNode={
						<div className='NewAppointmentModal-NewSelect'>
							<Input
								placeholder='Pesquisar por e-mail, nome ou cpf'
								action={handleSearchCollaborators}
								actionFocus={() => fetchCollaborators()}
							/>
						</div>
					}
					defaultText={filters?.doctor__id__in?.length ? `${filters?.doctor__id__in.length} selecionados` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => {
						toggleOption('doctor__id__in', collaboratorsOptions, 'id', optionRef)
						fetchCollaborators({ offset: 0 })
					}}
					selectedOptions={filters?.doctor__id__in?.map(({id}) => id)}
					optionRefKey='id'
					optionStrKey='str'
					options={collaboratorsOptions}
				/>
				<NewMultiSelect
					label='Locais de Atendimento'
					filterNode={<div className='NewAppointmentModal-NewSelect'>
						<Input 
							placeholder='Pesquisar por nome'
							action={handleSearchServiceLocations}
							actionFocus={()=> fetchServiceLocationsOptions()}
						/>
					</div>
					}
					defaultText={filters?.service_location__id__in?.length ? `${filters?.service_location__id__in.length} selecionados` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => {
						toggleOption('service_location__id__in', serviceLocationOptions, 'id', optionRef)
						fetchServiceLocationsOptions({ offset: 0 })
					}}
					selectedOptions={filters?.service_location__id__in?.map(({id}) => id)}
					optionRefKey='id'
					optionStrKey='name'
					options={serviceLocationOptions}
				/>
				<NewMultiSelect
					label='Especialidades'
					filterNode={
						<div className='NewAppointmentModal-NewSelect'>
							<Input
								placeholder='Pesquisar por nome'
								action={handleSearchSpecialties}
								actionFocus={() => fetchSpecialtiesOptions({ offset: 0 })}
							/>
						</div>
					}
					defaultText={filters.specialty__id__in?.length ? `${filters.specialty__id__in.length} selecionadas` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => {
						toggleOption('specialty__id__in', specialtiesOptions, 'id', optionRef)
						fetchSpecialtiesOptions({ offset: 0 })
					}}
					selectedOptions={filters.specialty__id__in?.map(specialty => specialty.id)}
					optionRefKey='id'
					optionStrKey='strf'
					options={uniqueIDs(filters.specialty__id__in, specialtiesOptions)}
				/>
				<NewMultiSelect
					label='Convênios'
					filterNode={
						<div className='NewAppointmentModal-NewSelect'>
							<Input
								placeholder='Pesquisar por nome'
								action={handleSearchHealthInsurance}
								actionFocus={() => fetchHealthInsurancesOptions()}
							/>
						</div>
					}
					defaultText={filters.health_insurance__id__in?.length ? `${filters.health_insurance__id__in.length} selecionados` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => {
						toggleOption('health_insurance__id__in', healthInsurancesOptions, 'id', optionRef)
						fetchHealthInsurancesOptions()
					}}
					selectedOptions={filters.health_insurance__id__in?.map(insurance => insurance.id)}
					optionRefKey='id'
					optionStrKey='name'
					options={uniqueIDs(filters.health_insurance__id__in, healthInsurancesOptions)}
				/>
				<NewMultiSelect
					label='Residência do Paciente'
					filterNode={
						<div className='NewAppointmentModal-NewSelect'>
							<Input 
								placeholder='Pesquisar por cidade'
								action={handleSearchCity}
								actionFocus={()=> fetchCitiesOptions()}
							/>
						</div>
					}
					defaultText={filters.person__address__city__in.length ? `${filters.person__address__city__in.length} selecionados` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => toggleOption('person__address__city__in', citiesOptions, 'id', optionRef)}
					selectedOptions={filters.person__address__city__in.map(option => option.id)}
					optionRefKey='id'
					optionStrKey='name'
					options={uniqueIDs(filters.person__address__city__in, citiesOptions)}
				/>
				<NewMultiSelect
					label='CIDs'
					filterNode={<div className='WaitingRoomAttendancesFilters-NewMultiSelect-FilterNode'>
						<Input 
							placeholder='Pesquisar por CID ou Título'
							action={({ target: { value } }) => handleSearchCIDs(value)}
							actionFocus={()=> fetchCIDTenCodes()}
						/>
					</div>}
					defaultText={filters.related_diseases_code.length ? `${filters.related_diseases_code.length} selecionados` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => toggleOption('related_diseases_code', cidsOptions, 'code', optionRef)}
					selectedOptions={filters.related_diseases_code?.map(cid => cid.code) || []}
					optionRefKey='code'
					optionStrKey='title'
					options={filters.related_diseases_code
						? uniqueCIDs([...filters.related_diseases_code, ...cidsOptions]).map(cid => ({ ...cid, title: `${cid.code} | ${cid.title}` }))
						: []
					}
				/>
				<NewMultiSelect
					label='Categorias'
					filterNode={null}
					defaultText={filters.classification__in?.length ? `${filters.classification__in.length} selecionadas` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => toggleOption('classification__in', defaultOptions.CLASSIFICATION_CHOICES, 'id', optionRef)}
					selectedOptions={filters?.classification__in?.map(classification => classification.id)}
					optionRefKey='id'
					optionStrKey='name'
					options={defaultOptions.CLASSIFICATION_CHOICES}
				/>
				<NewMultiSelect
					label='Status'
					filterNode={null}
					defaultText={filters.decision__in.length ? `${filters.decision__in.length} selecionadas` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => toggleOption('decision__in', defaultOptions.DECISION_CHOICES, 'id', optionRef)}
					selectedOptions={filters.decision__in.map(decision => decision.id)}
					optionRefKey='id'
					optionStrKey='name'
					options={defaultOptions.DECISION_CHOICES}
					disabled={isArrivalFilter}
				/>
				<NewMultiSelect
					label='Prioridades'
					filterNode={null}
					defaultText={filters.priority__in.length ? `${filters.priority__in.length} selecionadas` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => toggleOption('priority__in', defaultOptions.PRIORITY_CHOICES, 'id', optionRef)}
					selectedOptions={filters.priority__in.map(priority => priority.id)}
					optionRefKey='id'
					optionStrKey='name'
					options={defaultOptions.PRIORITY_CHOICES}
				/>
				<NewMultiSelect
					label='Tipos'
					filterNode={null}
					defaultText={filters.result__in.length ? `${filters.result__in.length} selecionados` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => toggleOption('result__in', defaultOptions.RESULT_CHOICES, 'id', optionRef)}
					selectedOptions={filters.result__in.map(result => result.id)}
					optionRefKey='id'
					optionStrKey='name'
					options={defaultOptions.RESULT_CHOICES}
				/>
				<div>
					<b>Data de Nascimento</b>
					<div className='WaitingRoomAttendancesFilters-AttendanceDateDiv'>
						<Input
							label='De'
							name='person__birthday__gte'
							placeholder='DD/MM/YYYY'
							actionFocus={openCalendarBirthdayGteDateModal}
							value={formatISO8601Str(filters?.person__birthday__gte)}
						/>
						<Input
							label='Até'
							name='person__birthday__lte'
							placeholder='DD/MM/YYYY'
							actionFocus={openCalendarBirthdayLteDateModal}
							value={formatISO8601Str(filters?.person__birthday__lte)}
						/>
					</div>
				</div>
			</div>

		</section>
    </div>
}