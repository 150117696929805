import React, { useEffect, useState } from 'react'
import { List } from '../../newcomponents'
import { Input } from '../../component/form/input/input'
import Button, { ButtonModel } from '../../newcomponents/button/button'
import { IcoAdd, IcoBin, IcoPencil } from '../../component'
import Loading from '../../newcomponents/loading/loading'
import CostsForm from './costs-form'
import { getBRLCurrency } from '../../helpers/numbers'
import { apiV2 } from '../../helpers'
import ProcedureAutoComplete from './autocomplete'
import InfoLine from '../../component/info-line/info-line'
import FormButtons from './form-buttons'
import ToolTip from '../../component/tooltip/tooltip'

const maxCodeLength = 8

const referenceValues = {
    "custom": "Tabela própria",
    "tuss": "Tabela TUSS"
}

export default function ProcedureForm({procedure, onClose, onUpdate}) {

    const [localProcedure, setLocalProcedure] = useState(procedure ? {...procedure} : null)
    const [costsEditorState, setCostsEditorState] = useState(
        {
            open: false,
            cost: null,
            index: -1
        })
    const [autoCompleteItems, setAutoCompleteItems] = useState([])
    const [codeError, setCodeError] = useState('')

    useEffect(() => {
        init()
    }, [])


    const init = () => {
        if (!procedure) {
            setLocalProcedure({
                code: null,
                description: '',
                reference: 'CUSTOM',
                costs: []
            })

        }
    }

    const handleEditCostClick = (cost, index) => setCostsEditorState({open: true, cost: cost, index: index}) 

    const handleAutocompleteSelect = item => {
        setLocalProcedure({ ...localProcedure, ...item, reference: 'TUSS' })
        setAutoCompleteItems([])
    }

    const handleDescriptionChange = e => {
        const { value } = e.target

        setLocalProcedure({...localProcedure, description: value, reference: 'CUSTOM'})
    }

    const handleCodeInput = async(e) => {
        const { value } = e.target

        setLocalProcedure({...localProcedure, code: value})

        const { data } = await apiV2.post('/finance/procedures/autocomplete', {
            term: value
        })

        setAutoCompleteItems(data)
    }

    const handleSave = async() => {
        const action = procedure ? apiV2.put : apiV2.post
        const id = procedure ? `/${procedure.id}` : ''

        if (procedure) {
            delete localProcedure.id
        }

        localProcedure.code = Number(localProcedure.code)

        await action(`/finance/procedures${id}`, localProcedure)

        onUpdate()
    }

    const saveCost = (cost, index) => {

        setCostsEditorState({open: false, cost: null, index: -1})

        if (index < 0) {
            localProcedure.costs.push(cost)
            setLocalProcedure({...localProcedure})
            return
        }

        localProcedure.costs[index] = cost
        setLocalProcedure({...localProcedure})
    }

    const deleteCost = (index) => {

        if (localProcedure.costs.length === 1) {
            setLocalProcedure({...localProcedure,costs: []})
            return
        }

        localProcedure.costs.splice(index, 1)
        
        setLocalProcedure({
            ...localProcedure,
        })
    }

    const validateCode = () => {
        if (!localProcedure) return

        setCodeError('')

        if (isNaN(localProcedure.code) || localProcedure.code < 0){
            setCodeError('Código inválido')
            return
        }

        if (String(localProcedure.code).length > maxCodeLength){
            setCodeError(`O código deve conter nomáximo ${maxCodeLength} caracteres`)
            return
        }

    }

    useEffect(validateCode, [localProcedure])

    const costActions = (cost, index) => <div className='procedure-actions'>
        <div className='procedure-action' onClick={() => handleEditCostClick(cost, index)}>
            <IcoPencil></IcoPencil>
        </div>
        <div className='procedure-action' onClick={() => deleteCost(index)}>
            <IcoBin></IcoBin>
        </div>
    </div>

    if (!localProcedure) return <Loading></Loading>

    return <div className='procedure-form'>

        {!costsEditorState.open && <div className='procedure-content'>
            <form>
                <Input
                    label='Código'
                    value={localProcedure.code}
                    action={handleCodeInput}
                    actionFocus={() => setAutoCompleteItems([])}
                    actionBlur={validateCode}
                    color='#000'
                ></Input>
                <div className='code-error'>{codeError}</div>

                <ProcedureAutoComplete
                    items={autoCompleteItems}
                    onSelect={handleAutocompleteSelect}
                    onClickOutside={() => setAutoCompleteItems([])}
                ></ProcedureAutoComplete>

                <Input
                    label='Descrição'
                    value={localProcedure.description}
                    action={handleDescriptionChange}
                ></Input>

                <InfoLine label='Referência' 
                    value={referenceValues[localProcedure.reference?.toLowerCase()]}
                ></InfoLine>
            </form>

            <div className='subtitle'>Lista de valores</div>

            <List
                columns={[
                    {header: 'Tipo'},
                    {header: 'Valor', size: 150},
                    {header: 'Ações', size: 100}
                ]}
                rows={localProcedure.costs.map((item, i) => [
                    item.type,
                    getBRLCurrency(item.cost),
                    costActions(item, i)
                ])}
            ></List>

            <div className="finance-add-button">
                <ToolTip message={"Adicionar"}>
                    <Button
                        Icon={<IcoAdd></IcoAdd>}
                        model={ButtonModel.ROUNDICON}
                        onClick={() => setCostsEditorState({open: true, cost: null, index: -1})}
                    ></Button>
                </ToolTip>
            </div>

            <FormButtons 
                onClose={onClose}
                onSave={handleSave}
                disabled={codeError !== '' || localProcedure.description === ''}
            ></FormButtons>
        </div>}


        {costsEditorState.open && <div className={`costs-add-content ${costsEditorState.open ? 'open' : ''}`}>
            <CostsForm 
                cost={costsEditorState.cost}
                index={costsEditorState.index}
                onClose={() => setCostsEditorState({open: false, cost: null})}
                onSave={saveCost}
            ></CostsForm>
        </div>}

    </div>    
}