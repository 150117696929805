import React, { useEffect, useState } from "react";
import { Input } from "../../../component";
import NewSelect from "../../../newcomponents/form/select/select";
import Modal from "../../../newcomponents/modal/modal";
import InfoLine from "../../../component/info-line/info-line";
import { getBRLCurrency } from "../../../helpers/numbers";

export default function ReceiptItemModal({open, procedureList, onAdd, onClose, receiptItem, onUpdate}) {

    const [selectedProcedure, setSelectedProcedure] = useState({id: -1})
    const [description, setDescription] = useState('')
    const [quantity, setQuantity] = useState(1)
    const [unitCost, setUnitCost] = useState(0)
    const [value, setValue] = useState(0)
    const [procedureOptions, setProcedureOptions] = useState([])
    const [filteredProcedureOptions, setFilteredProcedureOptions] = useState([])

    useEffect(() => {
        if (receiptItem) {
            setSelectedProcedure({
                id: receiptItem.selectedId,
                name: receiptItem.description,
                cost: receiptItem.unitCost
            })
            setDescription(receiptItem.description)
            setQuantity(receiptItem.quantity)
        }
    }, [open])

    useEffect(() => {

        if(selectedProcedure.id !== -1){
            setUnitCost(selectedProcedure.cost)
            setValue(selectedProcedure.cost * quantity)
        }
        
    }, [quantity, selectedProcedure])

    useEffect(() => {
        if (procedureList) {
            const options = computeProcedureOptions()
            setProcedureOptions(options)
            setFilteredProcedureOptions(options)
        }
    }, [procedureList])

    const handleQuantityInput = e => {
        setQuantity(Number(e.target.value))
    }

    const computeProcedureOptions = () => {
        let options = []


        for (let procedure of procedureList) {
            for (let i = 0; i < procedure.costs.length; i++) {
                const option =  {name: procedure.description}
                option.id = `${procedure.code}-${i+1}`
                option.name = `${procedure.costs[i].type} - ${procedure.description}`
                option.cost = procedure.costs[i].cost

                options.push(option)
            }
        }

        return options
    }

    const searchProcedure = e => {
        const term = e.target.value

        setFilteredProcedureOptions(
            procedureOptions.filter(procedure => procedure.name.indexOf(term) >= 0)
        )
    }

    const getProcedureOption = (id) => {
        for (let option of procedureOptions) {
            if (option.id === id) {
                return option
            }
        }
    }

    const handleSelect = e => {
        const index = e.target.selected

        const procedure = getProcedureOption(index)
        setSelectedProcedure(procedure)
        setDescription(procedure.name)
        setValue(procedure.cost)
        setFilteredProcedureOptions(procedureOptions)
    }

    const handleConfirm = () => {
        let procedure = {
            selectedId: selectedProcedure.id,
            description: description,
            quantity: quantity,
            unitCost: unitCost,
            value: value
        }

        onAdd(procedure, receiptItem?.index)

        clearState()
    }

    const clearState = () => {
        setSelectedProcedure({id: -1})
        setDescription('')
        setQuantity(1)
        setUnitCost(0)
        setValue(0)
    }

    const handleClose = () => {
        onClose()
        clearState()
    }

    const content = <div className="receipt-item-modal">
        <NewSelect
            label='Procedimento'
            options={filteredProcedureOptions}
            selected={selectedProcedure.id}
            onSelect={handleSelect}
            filterNode={
                <Input
                    placeholder='Pesquisar procedimento'
                    action={searchProcedure}
                />
            }
        >
        </NewSelect>

        <Input
            label='Quantidade'
            value={quantity}
            type='number'
            action={handleQuantityInput}
        ></Input>

        <div className="receipt-item-values">
            <InfoLine
                label='Valor unitário'
                value={getBRLCurrency(unitCost)}
            ></InfoLine>
            <InfoLine
                label='Valor total'
                value={getBRLCurrency(value)}
            ></InfoLine>
        </div>

    </div>

    return <Modal
        open={open}
        title='Adicionar procedimento'
        content={content}
        confirmText={'Salvar'}
        confirmFn={handleConfirm}
        dismissFn={handleClose}
        disableConfirm={selectedProcedure.id < 0}
    >
    </Modal>
}