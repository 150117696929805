import './FilterEntryBatchParams.css'
import React, { useEffect, useRef, useState } from "react";
import { Input } from '../../../../../../../component';
import NewSelect from '../../../../../../../newcomponents/form/select/select';
import { getHealthPlacePatients } from '../../../../../../pacientes/http';
import { getCollaborators } from '../../../../../../collaborators/http';
import { stringifyCollaboratorOption } from '../../../../../../../utils/stringifyCollaboratorOption';
import { useApp } from '../../../../../../../layout/App';
import IcoCleanFilter from '../../../../../../../component/icon/cleanFilter';
import { IcoClose } from '../../../../../../../component';

const { INVENTORY_MATERIAL_ENTRY_ORDERING_OPTIONS } = require('../../../../../../../component/form/select/options.json')

export default function FilterEntryBatchParams({ filterParams={}, setFilterParams= () => null, fetchAssignments=() => null, INIT_FILTER_PARAMS={} }) {
    
    const searchTimeout = useRef(null)
    const selectSearchTimeout = useRef(null)
    const [filtersList, setFiltersList] = useState([])
    const [collaboratorOptions, setCollaboratorOptions] = useState([])
    const { currentHealthPlaceUser } = useApp()

    useEffect(() => {
        fetchCollaborators()
    }, [])

    const handleChange = (event) => {
        setFilterParams(prev => ({ ...prev, [event.target.name]: event.target.value }))

        try {
            searchTimeout.current && clearTimeout(searchTimeout.current)
            searchTimeout.current = setTimeout(async () => {
                let fetchParams = { ...filterParams, [event.target.name]: event.target.value, offset: 0 }
                updateFilterList(fetchParams)
            }, 500)
        } catch (err) {
            console.error('FilterEntryBatchParams ~ handleChange ~ ', err)
        }
    }

    const handleSelect = (event) => {
        setFilterParams(prev => ({ ...prev, [event.target.id]: event.target.selected }))
        

        try {
            searchTimeout.current && clearTimeout(searchTimeout.current)
            searchTimeout.current = setTimeout(async () => {
                let fetchParams = { ...filterParams, [event.target.id]: event.target.selected, offset: 0 }
                
                updateFilterList(fetchParams)
            }, 500)
        } catch (err) {
            console.error('FilterEntryBatchParams ~ handleSelect ~ ', err)
        }
    }

    const handleSearchCollaborators = async (event) => {
        if (selectSearchTimeout.current) clearTimeout(selectSearchTimeout.current)
    
        selectSearchTimeout.current = setTimeout(async () => {
            await fetchCollaborators({ 'name_cpf_or_email': event.target.value })
        }, 400, event.target.value)
    }

    const fetchCollaborators = async (params={}) => {
        await getCollaborators({
            ...params,
            health_place: currentHealthPlaceUser?.health_place?.id || null,
            limit: 500,
            has_person: true
        })
            .then(res => setCollaboratorOptions(res.data.results.map((instance) => stringifyCollaboratorOption(instance))))
            .catch(err => {
                console.error('fetchCollaborators', err)
                setCollaboratorOptions([])
            })
    }

    const updateFilterList = (newParam={}) => {
        const tempFilterList = Object.entries({ ...filterParams, ...newParam })
        
        
        setFiltersList(() => {
            const newFilters = tempFilterList
            
            .filter(item => (item[1] !== '' && item[1] !== null && item[1] !== undefined) && item[0] !== 'offset' && item[0] !== 'ordering' && item[0] !== 'material__active')
            .map(item => {
                switch (item[0]) {
                    case 'material__name__icontains':
                        return ['Nome', 'material__name__icontains']
                        case 'material__code__icontains':
                            return ['Código', 'material__code__icontains']
                                case 'responsible__id':
                                    return ['Responsável ', 'responsible__id']
                                    default:
                                        return item[0]
                                    }
                                })
                return newFilters
        })
    }

    const clearFilter = async (targetFilter) => {
        if (targetFilter === 'all') {
            setFiltersList([])
            setFilterParams(INIT_FILTER_PARAMS)
        } else if (targetFilter) {
            setFiltersList(filtersList.filter(item => item[1] != targetFilter))
            setFilterParams( prev => {
                let newFilter = { ...prev, [targetFilter]: '' }
                fetchAssignments(newFilter)
    
                return newFilter
            })
        }
    }

    return <div className='ListEntryBatchParams-Filters'>
        <div className='ListEntryBatchParams-Filters-Body'>
            <div className='FilterEntryBatchParams-Select'>
                <span>Material</span>
                <Input
                    name='material__name__icontains'
                    action={handleChange}
                    value={filterParams.material__name__icontains}
                />
            </div>
            <div className='FilterEntryBatchParams-Input'>
                <span>Código</span>
                <Input
                    name='material__code__icontains'
                    action={handleChange}
                    value={filterParams.material__code__icontains}
                />
            </div>
            <div className='ListEntryBatchParams-SelectRequester FilterEntryBatchParams-Select'>
                <span>Responsável</span>
                <NewSelect
                    id='responsible__id'
                    options={collaboratorOptions}
                    selected={filterParams.responsible__id}
                    optionStrKey='str'
                    optionRefKey='health_place_user__id'
                    onSelect={handleSelect}
                    filterNode={<div className='ListEntryBatchParams-NewSelect'>
                        <Input FilterEntryBatchParams
                            placeholder='Pesquisar por e-mail, nome ou cpf'
                            action={handleSearchCollaborators}
                            actionFocus={()=> fetchCollaborators()}
                        />
                    </div>
                    }
                />
            </div>
            <div className='FilterEntryBatchParams-Select'>
                <span>Ordenar por</span>
                <NewSelect 
                    id='ordering'
                    onSelect={handleSelect}
                    selected={filterParams.ordering}
                    options={INVENTORY_MATERIAL_ENTRY_ORDERING_OPTIONS}
                />
            </div>
        </div>
        <div className='FilterEntryBatchParams-ActiveContainer-Wrapper'>
            {
                filtersList?.length
                ? 
                <div className='FilterEntryBatchParams-ActiveContainer'>
                    <div className='FilterEntryBatchParams-SideBarLeft-ActionRow'>
                        <div
                            onClick={() => clearFilter('all')}
                            title="Limpar filtro"
                        >
                        <IcoCleanFilter />
                    </div>
                    
                    </div>
                    <div className='FilterEntryBatchParams-ActiveFilters'>
                        <p className='FilterEntryBatchParams-Title'>Filtros Ativos:</p>
                        <ul className='FilterEntryBatchParams-List'>
                            {filtersList.map((item, index) => { 
                                return (
                                    <li key={index}>
                                        <button onClick={() => clearFilter(item[1])} className='FilterEntryBatchParams-Button'>
                                            <p className='FilterEntryBatchParams-Name'>
                                                {item[0]}
                                            </p>
                                            <div className='FilterEntryBatchParams-Remove'>
                                                <IcoClose></IcoClose>
                                            </div>
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>

                </div>
                : null
            }   

        </div>
    </div>
}