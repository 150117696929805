import './EditInstitutionPartnersModal.css'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddAlert, AlertType } from '../../../../../../../../component'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import { listaCidades } from '../../../../../../../../layout/redux/AppActions'
import { useConfiguration } from '../../../../../../configuration'
import { getParams } from '../../../../../../../../utils'
import { getHealthInsurances } from '../../../../../../http'
import NewButton from '../../../../../../../../newcomponents/button/button'
import { Input } from '../../../../../../../../component'
import NewMultiSelect from '../../../../../../../../newcomponents/form/select/multiselect'

const strpHealthPlace = (health_place) => {
	
	return {
		id: health_place?.id,
		health_insurances: health_place?.health_insurances || [],
		citys: health_place?.citys || []
	}
}

export default function EditInstitutionPartnersModal() {
	const { institution, saveInstitution, setModalProps, fetchInstitution } = useConfiguration()
	const dispatch = useDispatch()
	const [healthInsurances, setHealthInsurances] = useState([])
	const [holdingHealthPlace, setHoldingHealthPlace] = useState(strpHealthPlace(institution))
	const [next, setNext] = useState(null)
	const searchTimeout = useRef(null)


	useEffect(() => {
		
	}, [holdingHealthPlace,healthInsurances])

	useEffect(() => {
		dispatch([listaCidades({ limit: 50 })])
		fetchHealthInsurances({offset: 0})
	}, []) // eslint-disable-line

	const handlePartners = (event) => {
		const selectedOption = healthInsurances.find(option => option.id === event.target.selected);
	
		if (selectedOption) {
			setHoldingHealthPlace(prev => {
				const alreadySelected = prev.health_insurances.some(item => item.id === selectedOption.id);
	
				return {
					...prev,
					health_insurances: alreadySelected
						? prev.health_insurances.filter(item => item.id !== selectedOption.id) 
						: [...prev.health_insurances, selectedOption] 
				};
			});
		}
	};
	

	const fetchHealthInsurances = async (params={}, nextUrl) => {
		params = getParams({ ...params })

		try {
			let res = await getHealthInsurances(params, nextUrl)
			if (params.includes("name=")) {
				setHealthInsurances(res.data.results)
			} else {
				setHealthInsurances(prevHealthInsurances => [...prevHealthInsurances, ...res.data.results]);
			}
			setNext(res.data.next)
		} catch (err) {
			console.error('fetchHealthInsurances ~ ', err)
			dispatch(AddAlert('Planos & convênios', 'Erro ao listar planos e convênios', AlertType.ERROR))
		}
	}

	const handleFetchInsurances = (event) => {
		const searchTerm = event.target.value;
	
		if (searchTimeout.current) {
			clearTimeout(searchTimeout.current);
		}
	
		searchTimeout.current = setTimeout(() => {
			fetchHealthInsurances({ name: searchTerm });
			searchTimeout.current = null; 
		}, 400);
	};

	const loadMoreHealthInsurances = async () => {
		if (next) {
			const params = {}
			await fetchHealthInsurances(params, next);
		}
	}

	const handleSubmit = async () => {
		const payload = {
			health_insurances: holdingHealthPlace.health_insurances.map((e) => e.id),
			citys: holdingHealthPlace.citys.map((e) => e.id),
		}
		await saveInstitution(holdingHealthPlace.id, payload)
			.then(() => {
				fetchInstitution()
				setModalProps(MODAL_INIT_PROPS)
			})
	}

	return (<div className='EditInstitutionPartnersModal'>
		<div>
			<div className='EditInstitutionPartnersModal-PersonalInfo'>
                <div className='EditInstitutionPartnersModal-Subtitle-Header'>
                    <div className='EditInstitutionPartnersModal-Subtitle-Header-Icon'>P</div>
                    <div className='EditInstitutionPartnersModal-Subtitle-Header-Title'>Planos e Convênios Parceiros</div>
                </div>
            </div>
			<div className='EditInstitutionPartnersModal-Select'>
			<NewMultiSelect 
				label='Planos de Saúde'
				name='health_insurances'
				options={healthInsurances}
				defaultText={holdingHealthPlace.health_insurances.length ? `${holdingHealthPlace.health_insurances.length} selecionados` : 'Selecione'}
				onlyDefaultText
				selectedOptions={holdingHealthPlace?.health_insurances.map(e => e.id)}
				onSelect={({ optionRef }) => {
					setHoldingHealthPlace(prev => {
						const alreadySelected = prev.health_insurances.some(item => item.id === optionRef);

						return {
							...prev,
							health_insurances: alreadySelected
								? prev.health_insurances.filter(item => item.id !== optionRef) 
								: [...prev.health_insurances, healthInsurances.find(opt => opt.id === optionRef)]
						};
					});
				}}
				loadMoreText={ next ? 'Carregar mais' : ''}
				loadMoreFn={loadMoreHealthInsurances}

				filterNode={
					<div className='EditInstitutionPartnersModal-Select-Filter'>
						<Input
							placeholder='Filtrar por nome ou código'
							onFocus={() => fetchHealthInsurances()}
							action={(e) => handleFetchInsurances(e)}
						/>
					</div>
				}
				closeDropwndownOnSelect={false}
			/>

			</div>
		</div>
		<div className='EditInstitutionPartners-BtnBox'>
			<NewButton label='Atualizar' onClick={handleSubmit} />
		</div>
	</div>)
}
