import './RemoveCouncilModal.css'
import React from "react";
import { useDispatch } from 'react-redux';
import NewButton, { ButtonModel } from '../../../../../../../../../../newcomponents/button/button';
import { AddAlert, AlertType } from '../../../../../../../../../../component';
import { loaded, loading } from '../../../../../../../../../../layout/redux/AppActions';
import { patchCouncil } from '../../../../../../../../http';
import { useApp } from '../../../../../../../../../../layout/App';
import { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal';


export default function RemoveCouncilModal({ council, setSecondaryModalProps=() => null }) {
    const dispatch = useDispatch()
	const { fetchUserCouncils, currentHealthPlaceUser, loged } = useApp()

    let profile = currentHealthPlaceUser?.profile

    const councilNames = {
		DENTIST: 'CRO',
		PSYCHOLOGIST: 'CRP',
		NUTRITIONIST: 'CRN',
		NURSE: 'COREN',
		DOCTOR: 'CRM',
	}

	const handleRemove = async () => {
		dispatch(loading())
		try {
			await patchCouncil(council?.id, { situation: 'D'})
			await loged()
			dispatch([loaded(), AddAlert(`Remoção de ${councilNames[profile]}`, `${councilNames[profile]} removido com sucesso`, AlertType.SUCCESS)])
			setSecondaryModalProps(MODAL_INIT_PROPS)
		} catch (err) {
			console.error('handleRemove', err)
		}
		fetchUserCouncils()
	}



    return <div className='RemoveCouncilModal'>
        <p>Tem certeza que deseja remover este registro de conselho?</p>
        <div className='RemoveCouncilModal-BtnBox'>
            <NewButton 
                label='Remover'
                onClick={handleRemove}
                model={ButtonModel.DANGER}
            />
        </div>
    </div>
}
