import React, { useEffect, useState } from 'react'
import List from '../../newcomponents/list/list'
import useFinanceService from '../../services/finance'
import { IcoBin, IcoPencil } from '../../component'
import { getBRLCurrency } from '../../helpers/numbers'
import FinanceAddButton from './finance-add-button'
import ReceiptModal from './receipts/receipt-modal'

export default function RevenuesSection() {
    const [receipts, setReceipts] = useState([])
    const [receiptModalOpen, setReceiptModalOpen] = useState(false)
    const [receiptToEdit, setReceiptToEdit] = useState(null)

    const finance = useFinanceService()

    const fetchReceipts = async() => {
        let data = await finance.fetchReceipts()

        setReceipts(data)
    }

    useEffect(() => {
        fetchReceipts()
    },[])

    useEffect(() => {
        receiptToEdit && setReceiptModalOpen(true)
    }, [receiptToEdit])

    const getValue = receipt => {
        let total = receipt.procedures.reduce((acc, procedure) => acc + procedure.value, 0)

        return total * (1-(receipt.discount / 100))
    }

    const handleOnClose = () => {
        setReceiptModalOpen(false)
        setReceiptToEdit(null)
    }

    const edit = (receipt, index) => {
        receipt.index = index
        setReceiptToEdit(receipt)
    }

    const deleteReceipt = async(receipt) => {
        await finance.deleteReceipt(receipt)
        fetchReceipts()
    }

    return <div className='revenues-section'>

        <div className='subtitle'>Lista de receitas</div>

        <List
            columns={[
                {header:'Paciente'},
                {header:'Valor', size: 150},
                {header: 'Ações', size: 100}
            ]}
            rows={receipts.map((receipt, index) => [
                receipt.patient.name,
                getBRLCurrency(getValue(receipt)),
                <div className='receipt-actions'>
                    <span onClick={() => edit(receipt, index)}>
                        <IcoPencil onClick={() => edit(receipt, index)}></IcoPencil>
                    </span>
                    <span onClick={() => deleteReceipt(receipt)}>
                        <IcoBin onClick={() => deleteReceipt(receipt)}></IcoBin>
                    </span>
                </div>
            ])}
        ></List>

        <FinanceAddButton
            onClick={() => setReceiptModalOpen(true)}
        ></FinanceAddButton>

        <ReceiptModal
            open={receiptModalOpen}
            onClose={handleOnClose}
            onSaved={() => fetchReceipts()}
            receipt={receiptToEdit}
        ></ReceiptModal>
    </div>
}