import React, { useEffect, useState } from "react";
import { Input } from "../../component";

import "./insurance-selector.css"
import { getHealthInsurancesWithName } from "../../pages/configuration/http";

export default function InsuranceSelector({open, onSelect, onClose}) {

    const [insuranceList, setinsuranceList] = useState([])
    const [filter, setFilter] = useState("")

    useEffect(() => {
        if (filter !== "") {
            fetchUsers()
        }
    }, [filter])

    document.onkeydown = evt => {
        if (evt.key === "Escape") {
            clearState()
            onClose()
        }
    }

    const fetchUsers = async() => {
        let response = await getHealthInsurancesWithName(filter)
        setinsuranceList(response.data.results)
    }

    const clearState = () => {
        setinsuranceList([])
        setFilter("")
    }

    const handleSelect = insurance => {
        clearState()
        onSelect(insurance)
    }

    return <div className={`insurance-selector ${open && 'open'}`}>

        <Input
            label='Digite o nome'
            value={filter}
            action={e => setFilter(e.target.value)}
        ></Input>

        {
            insuranceList.length > 0 && <div className="list"> 
                {
                    insuranceList.map(insurance => {
                        return <div className="insurance" onClick={() => handleSelect(insurance)}>
                            <div className="insurance-name">
                                {insurance.name}
                            </div>
                        </div>
                    })
                }
            </div>
        }

    </div>
}