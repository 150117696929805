import React, { useState } from "react";
import InfoLine from "../../../component/info-line/info-line";
import { IcoSearch, Input } from "../../../component";
import InsuranceSelector from "../../../newcomponents/insurance-selector/insurance-selector";

export default function ReceiptInsurance({insurance, onSelect}) {

    const [selectinsurance] = useState(insurance.id === 0)
    const [selectOpen, setSelectOpen] = useState(false)

    const handleSelect = insurance => {
        setSelectOpen(false)
        onSelect(insurance)
    }

    if (!selectinsurance) {
        return <InfoLine
            label='Convênio'
            value={insurance.name || 'Nenhum'}
        ></InfoLine>
    }

    return <div className="receipt-select receipt-select-anchor">
        <div>
            <Input 
                label='Convênio'
                value={insurance.name}
            ></Input>
            <InsuranceSelector
                open={selectOpen}
                onSelect={handleSelect}
                onClose={() => setSelectOpen(false)}
            ></InsuranceSelector>
        </div>
        <div className="search" onClick={() => setSelectOpen(true)}>
            <IcoSearch></IcoSearch>
        </div>
    </div>
}