import './WaitingRoomUnit.css'
import React from "react";
import { useHistory } from 'react-router-dom'
import { MaskDataHora } from '../../../../../../component';
import InfoLine from '../../../../../../component/info-line/info-line';
import NewAppointmentModal from '../../../AttendanceHistory/subcomponents/NewAppointmentModal/NewAppointmentModal';
import { useApp } from '../../../../../../layout/App';
import CallPatientModal from './subcomponents/CallPatientModal';
import { MODAL_INIT_PROPS } from '../../../../../../newcomponents/modal/modal';
import { getFormatedTimeFromDate } from '../../../../helpers';
import { DECISIONS_WITH_TIME } from '../../../../consts';

const { DECISION_CHOICES, CLASSIFICATION_CHOICES, RESULT_CHOICES, PRIORITY_CHOICES } = require('../../../../../../component/form/select/options.json')

export default function WaitingRoomUnit({ attendance=null, setModalProps=()=> null, fetchAttendancesHistory=async () => null, filters={} }) {
    const history = useHistory()
    const { isHealthProfessional } = useApp()
    const attendanceDate = MaskDataHora(attendance?.start)
    
    const openConsultation = (id) => {
        history.push(`/consultation/${id}`)
    }
    
    const attendancePriority = PRIORITY_CHOICES.find(({ id }) => id === attendance?.priority)
    const attendanceDecision = DECISION_CHOICES?.find(({ id }) => id === attendance?.decision)
    
    let decisionStatus = attendanceDecision.name
        if (DECISIONS_WITH_TIME.includes(attendance.decision)) {
              const attendanceDecisionTime = getFormatedTimeFromDate(attendance?.decision_datetime) || ''
          decisionStatus = `${attendanceDecision.name} às ${attendanceDecisionTime}`
        }

    const callPatientExtraAction = async () => {
        await fetchAttendancesHistory({ offset: 0 })
        setModalProps(MODAL_INIT_PROPS)
    }

    return <div className='WaitingRoomUnit'>
        <div className='WaitingRoomUnit-Header'>
            <div>
                <div className='WaitingRoomUnit-Header-TitleBox'>
                    <b className='WaitingRoomUnit-Header-TitleBox-PatientName'>{attendance.person?.social_name || attendance.person?.name || 'Paciente não encontrado'}</b>
                </div>
                <span className='WaitingRoomUnit-Header-AltText'>{attendance?.protocol || '-'} em {attendanceDate?.data && attendanceDate?.hora ? `${attendanceDate?.data} ${attendanceDate?.hora}` : '-'}{attendance?.person?.social_name ? (` | Nome de registro: ${attendance.person.name}` || ' Nome não registrado') : ''}</span>
                <div className='WaitingRoomUnit-Header-TitleBox-AlertsBox'>
                    { 
                        attendancePriority && attendancePriority.id !== 'NO_PRIORITY'
                        ? <div 
                            className='WaitingRoomUnit-Header-TitleBox-PriorityBox'
                            style={{ 
                                color: attendancePriority.color, 
                                backgroundColor: attendancePriority.backgroundColor, 
                                border: `1px solid ${attendancePriority.color}`}
                            }
                        >
                            <b>{attendancePriority?.name}</b>
                        </div>
                        : null
                    }
                    {
                        attendanceDecision?.name
                        ? <div 
                            className='WaitingRoomUnit-AppointmentBox-Subtitle-DecisionBox' 
                            style={{ 
                                'border': `1px solid ${attendanceDecision.color}`,
                                'backgroundColor': attendanceDecision.secondColor,
                                'color': attendanceDecision.color,
                            }}>
                            <b>{decisionStatus}</b>
                        </div> 
                        : null
                    }
                    {
                        CLASSIFICATION_CHOICES?.find(ob => ob?.id === attendance?.classification)?.name
                        ? <div className='WaitingRoomUnit-AppointmentBox-Subtitle-DecisionBox'>
                            <b>{CLASSIFICATION_CHOICES?.find(ob => ob?.id === attendance?.classification)?.name}</b>
                        </div>
                        : null
                    }
                    {
                        RESULT_CHOICES?.find(ob => ob?.id === attendance?.result)?.name
                        ? <div className='WaitingRoomUnit-AppointmentBox-Subtitle-DecisionBox'>
                            <b>{RESULT_CHOICES?.find(ob => ob?.id === attendance?.result)?.name}</b>
                        </div>
                        : null
                    }
                    {
                        attendance?.specialty?.name 
                        ? <div className='WaitingRoomUnit-AppointmentBox-Subtitle-DecisionBox'>
                            <b>{attendance?.specialty?.name}</b>
                        </div>
                        : null
                    }
                </div>
            </div>
        </div>
        <div>
            <div className='WaitingRoomUnit-AppointmentBox-Subtitle'>
                <b>Resumo</b>
            </div>
            <div className='WaitingRoomUnit-AppointmentBox-Grid'>
                <InfoLine 
                    label='Profissional'
                    value={
                        attendance?.doctor?.name
                        ? <span className='WaitingRoomUnit-AppointmentBox-Grid-AltText'>{`${attendance?.doctor?.sex === 'F' ? 'Dra. ' : 'Dr. '} ${attendance?.doctor?.name}`}</span>
                        : attendance?.extra_kwargs?.external_doctor_name || '-'
                    }
                />
                {
                    attendance?.health_insurance?.name
                    ? <InfoLine 
                        label='Convênio'
                        value={<span className='WaitingRoomUnit-AppointmentBox-Grid-AltText'>{attendance?.health_insurance?.name}</span>}
                    />
                    : null
                }
                <InfoLine 
                    label='Local de Atendimento'
                    value={<span className='WaitingRoomUnit-PatientBox-Grid-AltText'>{attendance?.service_location?.name || '-'}</span>}
                />
                <InfoLine
                    label='CPF'
                    value={<span className='WaitingRoomUnit-PatientBox-Grid-AltText'>{attendance?.person?.cpf || '-'}</span>}
                />

            </div>
        </div>
        <div className='WaitingRoomUnit-ActionRow'>
            <div
                className='WaitingRoomUnit-ActionRow-Btn' 
                onClick={() => setModalProps({
                    open: true,
                    title: `Chamar paciente`,
                    content: <CallPatientModal 
                        appointment={attendance} 
                        extraAction={callPatientExtraAction}
                    />
                })}
            >
                {
                    !attendance?.ticket_called
                    ? <b>Chamar paciente</b>
                    : attendance?.ticket_called?.status === 'CALLED'
                    ? <b>Chamar novamente</b>
                    : attendance?.ticket_called?.status === 'SERVED'
                    ? <b>Chamado atendido</b>
                    : attendance?.ticket_called?.status === 'CANCEL'
                    ? <b>Chamado cancelado</b>
                    : null
                }
                
            </div>
            {
                isHealthProfessional
                ? <div 
                    className='WaitingRoomUnit-ActionRow-Btn' 
                    onClick={() => openConsultation(attendance?.id)}
                >
                    <b>Ver atendimento</b>
                </div>
                : null
            }
            <div
                className='WaitingRoomUnit-ActionRow-Btn' 
                onClick={() => setModalProps({
                    open: true,
                    title: `Editar atendimento | ${MaskDataHora(attendance.start)?.data} | ${MaskDataHora(attendance.start)?.hora}`,
                    content: <NewAppointmentModal
                        initAppointment={attendance}
                        fetchAppointments={fetchAttendancesHistory}
                        setModalInfo={setModalProps}
                        filters={filters}
                    />
                })}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}

