import './MaterialFlowModal.css'
import { useEffect, useRef, useState } from "react"
import { useApp } from '../../../../../../../../layout/App';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { AddAlert, AlertType, Input } from "../../../../../../../../component"
import { adicionaZero, MaskDataHora} from '../../../../../../../../component';
import CalendarDateModal from '../../../../../../../../component/calendar/CalendarDateModal';
import NewSelect from "../../../../../../../../newcomponents/form/select/select"
import Modal from '../../../../../../../../newcomponents/modal/modal';
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button';
import { postInventoryMaterialAssignment, postMaterialBatch } from '../../../../http';
import { getCollaborators } from '../../../../../../../collaborators/http';
import { stringifyCollaboratorOption } from '../../../../../../../../utils/stringifyCollaboratorOption';
import { MaterialFlowEntryForm, MaterialFlowExitForm } from '../InsertInventoryMaterialsModal/helpers';
import { useDispatch } from 'react-redux';
import { getInventoryMaterialLocations, postInventoryMaterialLocation } from '../../../ConfigInventoryMaterials/subcomponents/LocationsOptions/http';
import { getInventoryMaterialManufacturers,postInventoryMaterialManufacturer } from '../../../ConfigInventoryMaterials/subcomponents/ManufacturersOptions/http';

const defaultImage = require('../../../../../../../../media/defaultImage.png')
const defaultOptions = require('../../../../../../../../component/form/select/options.json')

const itemSourceEntryOptions = [
    {'id': 'purchase', 'name':'Compra'},
    {'id': 'free_sample', 'name':'Amostra Grátis'},
    {'id': 'bonus', 'name':'Bonificação'},
    {'id': 'material_utiliation', 'name':'Aproveitamento de material'},
    {'id': 'external_loan', 'name':'Emprestimo externo'},
    {'id': 'transfer', 'name':'Transferência'},
    {'id': 'return', 'name':'Devolução'},
    {'id': 'donation', 'name':'Doação'},
]

const strpMaterialItem = (instance=null) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    let [year, month, day] = regex.test(instance?.expiration_date)
        ? instance.expiration_date.split('-').map(value => Number(value))
        : ['', '', '']

    return {
        batch_quantity: instance?.batch_quantity || 0,
        day: day ? `${day < 10 ? '0': ''}${day}` : '',
		month: month ? `${month < 10 ? '0': ''}${month}` : '',
		year: year ? `${year}` : '',
        location: instance?.location?.id || '',
        invoice: instance?.invoice || '',
        responsible: instance?.responsible?.id || '',
        manufacturer: instance?.manufacturer?.id || '',
        presentation: instance?.presentation || '',
        source: instance?.source || '',
        note: instance?.note || '',
        requested_amount: instance?.requested_amount || 0,
        patient: instance?.patient || '',
        type: instance?.product_type || '',
        justification: instance?.justification || '',
    }
}

export default function MaterialFlowModal({ initMaterialData, flowType, setModalInfo, fetchMaterials, maxExitItems=null, onSubmit=()=>{} }) {
    const { currentHealthPlaceUser } = useApp()
    const dispatch = useDispatch()
    const [materialItem, setMaterialItem] = useState(strpMaterialItem(initMaterialData))
    const [materialItemErr, setMaterialItemErr] = useState({})
    const searchTimeout = useRef(null)
    
    const [collaboratorsOptions, setCollaboratosOptions] = useState([])

    const [activeLocations, setActiveLocations] = useState([])
    const [locationInputValue, setLocationInputValue] = useState('')

    const [activeManufacturers, setActiveManufacturers] = useState([])
    const [manufacturerInputValue, setManufacturerInputValue] = useState('')
    const [secondaryModalInfo, setSecondaryModalInfo] = useState(MODAL_INIT_PROPS)
    
    
    useEffect(() => {
        fetchActiveLocationsOptions()
        setResponsibleAsUser()
        fetchCollaboratorsOptions({ offset: 0 })
        fetchActiveManufacturersOptions()
    }, [])

    const setResponsibleAsUser = () => {
        setMaterialItem(prev => ({ ...prev, ['responsible']: currentHealthPlaceUser?.user?.person?.id }))
    }

    const fetchActiveLocationsOptions = async (params={}) => {
        try {
            let res = await getInventoryMaterialLocations({ active: true, ...params })
            setActiveLocations(res.data.results)
        } catch (err) {
            setActiveLocations([])
            console.error('fetchActiveLocationsOptions ~ ', err)
        }
    }

    const fetchCollaboratorsOptions = async params => {
		await getCollaborators({
			...params,
			health_place__id: currentHealthPlaceUser?.health_place?.id,
            is_active: true,
            has_person: true,
			limit: 500,
		})
			.then(res => {
                setCollaboratosOptions(res.data.results.map(instance => stringifyCollaboratorOption(instance)))
            })
			.catch(err => {
				console.error('fetchCollaboratorsOptions', err)
				setCollaboratosOptions([])
			})
	}

    const fetchActiveManufacturersOptions = async (params={}) => {
        try {
            let res = await getInventoryMaterialManufacturers({ active: true, ...params })
            setActiveManufacturers(res.data.results)
        } catch (err) {
            setActiveManufacturers([])
            console.error('fetchActiveManufacturersOptions ~ ', err)
        }
    }

    const handleSearchLocation = (event) => {
        setLocationInputValue(event.target.value)
        if (searchTimeout.current) clearTimeout(searchTimeout.current)
    
        searchTimeout.current = setTimeout(() => {
            fetchActiveLocationsOptions({ 'name__icontains': event.target.value })
        }, 400, event.target.value)
    }

    const handleSearchCollaboratorsOptions = event => {
		if (searchTimeout.current)
			clearTimeout(searchTimeout.current)

		searchTimeout.current = setTimeout(() => {
			fetchCollaboratorsOptions({ name_cpf_or_email: event.target.value })
		}, 400, event.target.value)
	}

    const handleChange = (event) => {
        
        let value = event.target.value
        
        if ((event.target.name === 'batch_quantity' && value > 10000000)) {
            value = 10000000
        } else if (maxExitItems && event.target.name === 'requested_amount' && value > maxExitItems) {
            value = maxExitItems
        } else if ((event.target.name === 'batch_quantity' || event.target.name === 'requested_amount') && value < 0) {
            value = 0
        } else if (event.target.name === 'batch_quantity' || event.target.name === 'requested_amount') {
            value = Number(value)
        } 

        setMaterialItem(prev => ({ ...prev, [event.target.name]: value }))
        setMaterialItemErr(prev => ({ ...prev, [event.target.name]: false }))
    }

    const handleSelect = (event) => {
        setMaterialItem(prev => ({ ...prev, [event.target.id]: event.target.selected }))

        let field = event.target.id
        if (field === 'day' || field === 'month' || field === 'year') {
            setMaterialItemErr(prev => ({ ...prev, 'day': false, 'month': false, 'year': false }))
        } else {
            setMaterialItemErr(prev => ({ ...prev, [event.target.id]: false }))
        }
    }

    const handleClearFields = () => {
        setMaterialItem(strpMaterialItem())
        setMaterialItemErr(strpMaterialItem())
    }

    const handleSubmit = async () => {
        
        const payload = new MaterialFlowEntryForm({ ...materialItem, health_place: currentHealthPlaceUser?.health_place?.id, material: initMaterialData?.id });

        if (!payload.isValid()) {
            const errors = payload.getErrors();
            if (Object.keys(errors).length) return setMaterialItemErr(errors);
        }
        
        dispatch(loading());
        
        try {
            if (flowType === 'entry') {
                await postMaterialBatch(payload);
                dispatch(AddAlert('Fluxo de Materiais', 'Entrada de material salva com sucesso!', AlertType.SUCCESS));
            } else {
                await postInventoryMaterialAssignment(payload)
            }

            fetchMaterials({ offset: 0 });
            onSubmit()
            setModalInfo(MODAL_INIT_PROPS);
        } catch (err) {
            console.error('handleSubmit ~ ', err);
        }

        dispatch(loaded()); 
    }

    const handleAddNewLocation = async (value) => {
        if (!value) {
            dispatch(AddAlert('Estoque de Materiais', 'Preencha o nome da localização no campo de pesquisa antes de clicar em "Adicionar opção".', AlertType.ERROR))
            return
        }
        const payload = {name: value ,health_place: currentHealthPlaceUser?.health_place?.id }

        try {
            await postInventoryMaterialLocation(payload)
            fetchActiveLocationsOptions({name: value})
            setLocationInputValue('')
            dispatch(AddAlert('Estoque de Materiais', 'Localização salva com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            console.log('handleAddNewLocation ~ ', err)
            dispatch(AddAlert('Estoque de Materiais', 'Erro em salvar Localização. Tente novamente na aba de Configurações.', AlertType.ERROR))
        }
    }

        const handleAddNewManufacturer = async (value) => {
        if (!value) {
            dispatch(AddAlert('Estoque de Materiais', 'Preencha o nome do fabricante no campo de pesquisa antes de clicar em "Adicionar opção".', AlertType.ERROR))
            return
        }
        const payload = {name: value ,health_place: currentHealthPlaceUser?.health_place?.id }
        

        try {
            await postInventoryMaterialManufacturer(payload)
            fetchActiveManufacturersOptions({name: value})
            setManufacturerInputValue('')
            dispatch(AddAlert('Estoque de Materiais', 'Fabricante salvo com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            console.log('handleAddNewManufacturer ~ ', err)
            dispatch(AddAlert('Estoque de Materiais', 'Erro em salvar fabricante. Tente novamente na aba de Configurações.', AlertType.ERROR))
        }
    }

        const handleSearchManufacturer = (event) => {
        setManufacturerInputValue(event.target.value)
        if (searchTimeout.current) clearTimeout(searchTimeout.current)
    
        searchTimeout.current = setTimeout(() => {
            fetchActiveManufacturersOptions({ 'name__icontains': event.target.value })
        }, 400, event.target.value)
    }

    const openCalendarDateModal = () => {
        const currentDate = new Date();

	
		const currentDay = String(adicionaZero(currentDate.getDate()));
		const currentMonth = String(adicionaZero(currentDate.getMonth() + 1)); // Months are zero-indexed
		const currentYear = String(currentDate.getFullYear());
		
        setMaterialItemErr(prev => ({ ...prev, day: false, month: false, year: false }))
		setSecondaryModalInfo(prev => ({
			...prev,
			open: true,
			title: 'Validade do material',
			content: (
				<CalendarDateModal
					name={'filterKey'}
					currentDay={currentDay}
					currentMonth={currentMonth}
					currentYear={currentYear}
					renderNextSelectedDay={(event) => {
                        setMaterialItem(prev => ({
                            ...prev,
                            day: event?.daySearchCursor,
                            month: event?.monthSearchCursor,
                            year: event?.yearSearchCursor
                        }))
                        setSecondaryModalInfo(MODAL_INIT_PROPS)
                    }}
				/>
			)
		}));
        console.log("material item error",materialItemErr);
        
	};

    return (
        <section className="MaterialFlowModal">
            <Modal {...secondaryModalInfo} dismissFn={() => setSecondaryModalInfo(MODAL_INIT_PROPS)}/>
            
            <div className='MaterialFlow-EntryForm'>
                <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                    <span><b className='MaterialFlowModal-Asterisk'>*</b> Quantidade</span>
                    <Input
                        name='batch_quantity'
                        action={handleChange}
                        value={materialItem.batch_quantity}
                        errors={{ error: materialItemErr, message: materialItemErr.batch_quantity }}
                        type='number'
                        min='0'
                        max='10000000'
                        placeholder='Ex.: 10, 25, 50'
                    />
                </div>
                <div>
                    <span><b className='MaterialFlowModal-Asterisk'>*</b> Validade</span>
                    <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                        <Input
                            name='day'
                            placeholder='DD/MM/AAAA'
                            actionFocus={openCalendarDateModal}
                            value={MaskDataHora(`${materialItem?.year}-${materialItem?.month}-${materialItem?.day}`, 'DD/MMM/AAAA', '-')?.data}
                            errors={{ error: materialItemErr, message: materialItemErr.day }}

                        />
                    </div>
                </div>
                <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                    <span><b className='MaterialFlowModal-Asterisk'>*</b> Localização</span>
                    <NewSelect 
                        id='location'
                        onSelect={handleSelect}
                        selected={materialItem.location}
                        options={activeLocations}
                        optionStrKey='name'
                        error={materialItemErr.location}
                        addNewCategoryPermission={true}
                        handleAddNewCategory={() => handleAddNewLocation(locationInputValue)}
                        filterNode={<div className='InsertInventoryMaterialsModal-NewSelect'>
                            <Input
                                placeholder='Pesquisar por localização'
                                action={handleSearchLocation}
                                actionFocus={()=> fetchActiveLocationsOptions()}
                                value={locationInputValue}
                            />
                        </div>}
                    />
                </div>
                <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                    <span>NF associada</span>
                    <Input
                        name='invoice'
                        action={handleChange}
                        value={materialItem.invoice}
                        errors={{ error: materialItemErr, message: materialItemErr.invoice }}
                    />
                </div>
                <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                    <span><b className='MaterialFlowModal-Asterisk'>*</b> Responsável</span>
                    <NewSelect 
                        id='responsible'
                        options={collaboratorsOptions}
                        optionStrKey='str'
                        selected={materialItem.responsible}
                        onSelect={handleSelect}
                        error={materialItemErr.responsible}
                        filterNode={<div className='ManageTeamEdgesModal-NewSelect'>
                        <Input 
                            placeholder='Pesquisar por email'
                            action={(value) => handleSearchCollaboratorsOptions(value)}
                            actionFocus={()=> fetchCollaboratorsOptions()}
                        />
                        </div>}
                    />
                </div>
                <div className='MaterialFlowModal-Select MaterialFlowModal-Input-W'>
                    <span><b className='MaterialFlowModal-Asterisk'>*</b> Origem</span>
                    <NewSelect 
                        id='source'
                        defaultText='Selecione'
                        options={itemSourceEntryOptions}
                        onSelect={handleSelect}
                        selected={materialItem?.source}
                        error={materialItemErr?.source}
                    />
                </div>
                <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Fabricante</span>
                        <NewSelect 
                            id='manufacturer'
                            onSelect={handleSelect}
                            selected={materialItem.manufacturer}
                            options={activeManufacturers}
                            optionStrKey='name'
                            error={materialItemErr.manufacturer}
                            addNewCategoryPermission={true}
                            handleAddNewCategory={() => handleAddNewManufacturer(manufacturerInputValue)}
                            filterNode={<div className='InsertInventoryMaterialsModal-NewSelect'>
                                <Input
                                    placeholder='Pesquisar por fabricante'
                                    action={handleSearchManufacturer}
                                    actionFocus={()=> fetchActiveManufacturersOptions()}
                                    value={manufacturerInputValue}
                                />
                            </div>}
                        />
                    </div>
                    <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Apresentação</span>
                        <Input
                            name='presentation'
                            action={handleChange}
                            value={materialItem.presentation}
                            errors={{ error: materialItemErr, message: materialItemErr.presentation }}
                            maxLength='50'
                            placeholder='Ex.: Garrafa, pacote, etc.'
                        />
                    </div>
                <div className='MaterialFlowModal-Input MaterialFlowModal-Input-FullRow'>
                    <span>Observações</span>
                    <Input
                        name='note'
                        action={handleChange}
                        value={materialItem.note}
                        errors={{ error: materialItemErr, message: materialItemErr.invoice }}
                    />
                </div>
                <div className='MaterialFlow-EntryForm-BtnBox'>
                    <NewButton 
                        label='Limpar campos'
                        onClick={handleClearFields}
                        model={ButtonModel.SECONDARY}
                    />
                    <NewButton 
                        label='Cadastrar entrada'
                        onClick={handleSubmit}
                        model={ButtonModel.PRIMARY}
                    />                    
                </div>
            </div>
        </section>
    )

}