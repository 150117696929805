import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import './App.css'
import './app-color.css'
import WebsocketContext from '../websocket-context'
import keepOriginalUserApi from '../helpers/api'
import { loaded, loading } from './redux/AppActions'
import CouncilRegisterPage from '../pages/register/CoucilRegisterPage/CouncilRegisterPage'
import logo from './logo-so.png'
import logoSquare from '../media/LogoSquare.png'
import getInitials from '../utils/getInitials'
import Modal, { MODAL_INIT_PROPS } from '../newcomponents/modal/modal'
import { userRoles } from '../utils/userRoles'
import { AddAlert, AlertType, Menu } from '../component'
import MenuJSON from './app.json'
import VerifyEmailPage from '../pages/register/VerifyEmailRegister/VerifyEmailRegister'
import ConcludeRegisterPage from '../pages/register/ConcludeRegisterPage/ConcludeRegisterPage'
import RegisterProfileFieldAgainPage from '../pages/register/RegisterProfileFieldAgain/RegisterProfileFieldAgain'
import api from '../helpers/api'
import SelectHealthPlaceUser from './subcomponents/SelectHealthPlaceUserLeader/SelectHealthPlaceUser'
import CurrentUserDropdown from './subcomponents/CurrentUserDropdown/CurrentUserDropdown'
import ChatWindow from '../pages/home/subcomponents/Chat/ChatWindow/ChatWindow'
import ChatIcon from '../component/icon/chat'
import { ExclamationIco } from '../component'
import Chat from '../pages/home/subcomponents/Chat/Chat'

const AppContext = React.createContext()

export const useApp = () => {
  return useContext(AppContext)
}

async function getCouncils() {
  return keepOriginalUserApi.get(`/health_place_user/council/`)
}

const getMenuDash = ({ profile, enabled_thousand_days, enabled_preceptorship, health_place }) => {
  let tabs = MenuJSON.menuPerfil[profile]
  if (!tabs) return [];

  const filters = {
    Gestação: !enabled_thousand_days,
    Preceptoria: !enabled_preceptorship,
    Estoque: !(
      health_place?.inventory?.can_read_and_write?.includes(profile) ||
      health_place?.inventory?.can_read?.includes(profile)
    ),
  };

  const profilesNeedingFilters = ['DOCTOR', 'DENTIST', 'NUTRITIONIST', 'PSYCHOLOGIST', 'NURSE', 'ASSISTANT'];

  if (profilesNeedingFilters.includes(profile)) {
    tabs = tabs.filter(section => !filters[section.label]);
  }

  return tabs;
};

const wsScheme = process.env.NODE_ENV === "production" ? "wss" : "ws";
const wsHost = process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST ? process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST : window.location.hostname;

export default function AppLayout({ children, access, path }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { credenciais } = useSelector(state => state.appState)
  const [councils, setCouncils] = useState([])
  const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
  const [menu, setMenu] = useState(MenuJSON.menuPerfil['DEFAULT'])
  const user = JSON.parse(localStorage.user || '{}' )
  const [currentUser, setCurrentUser]  = useState(user || {})
  const [currentHealthPlaceUser, setCurrentHealthPlaceUser] = useState(null)
  const [isArrivalFilter, setIsArrivalFilter] = useState(false)

  const socketRef = useRef(null);
  const [onlineUsers, setOnlineUsers] = useState({})
  const reconnectInterval = useRef(null)
  const intentionalDisconnect = useRef(false)

  const health_place__base64_avatar = currentHealthPlaceUser?.health_place?.logo_url || ''
  const isHealthProfessional = currentHealthPlaceUser?.profile === 'DOCTOR' || currentHealthPlaceUser?.profile === 'DENTIST' || currentHealthPlaceUser?.profile === 'PSYCHOLOGIST' || currentHealthPlaceUser?.profile === 'NUTRITIONIST' || currentHealthPlaceUser?.profile === 'NURSE'
  const isAssistant = currentHealthPlaceUser?.profile === 'ASSISTANT'
  const isAdmin = currentHealthPlaceUser?.profile === 'ADMINISTRATOR'
  const isNurse = currentHealthPlaceUser?.profile === 'NURSE'
  const isHealthProfessionalOrIsNurse = isHealthProfessional || isNurse
  const isAdminOrAssistant = isAdmin || isAssistant
  const isAssistantOrNurse = isAssistant || isNurse
  const isAdminOrIsAssistantOrIsNurse = isAdmin || isAssistant || isNurse
  
  useEffect(() => {
    if (localStorage.token && path !== '/verificar-email' && path !== '/registrar/concluir') {
      loged()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (currentHealthPlaceUser) {
      setMenu(getMenuDash(currentHealthPlaceUser))
    }
  }, [currentHealthPlaceUser])

  useEffect(() => {
    if (isHealthProfessional) {
      fetchUserCouncils()
    }
  }, [isHealthProfessional])

  useEffect(() => {
    if (currentHealthPlaceUser?.health_place?.primary_color) {
      document.documentElement.style.setProperty('--primary-color', currentHealthPlaceUser?.health_place?.primary_color)
    }

    if (currentHealthPlaceUser?.health_place?.primary_color_light) {
      document.documentElement.style.setProperty('--primary-color-light', currentHealthPlaceUser?.health_place?.primary_color_light)
    }
    
    if (currentHealthPlaceUser?.health_place?.primary_color_lighter) {
      document.documentElement.style.setProperty('--primary-color-lighter', currentHealthPlaceUser?.health_place?.primary_color_lighter)
    }

    if (currentHealthPlaceUser?.health_place?.primary_color_light_2) {
      document.documentElement.style.setProperty('--primary-color-light-2', currentHealthPlaceUser?.health_place?.primary_color_light_2)
    }

    if (currentHealthPlaceUser?.health_place?.primary_color_dark) {
      document.documentElement.style.setProperty('--primary-color-dark', currentHealthPlaceUser?.health_place?.primary_color_dark)
    }

    if (currentHealthPlaceUser?.health_place?.primary_color_darker) {
      document.documentElement.style.setProperty('--primary-color-darker', currentHealthPlaceUser?.health_place?.primary_color_darker)
    }
  
    if (currentHealthPlaceUser?.health_place?.primary_font_color) {
      document.documentElement.style.setProperty('--primary-font-color', currentHealthPlaceUser?.health_place?.primary_font_color)
    }
  }, [
    currentHealthPlaceUser?.health_place?.primary_color, 
    currentHealthPlaceUser?.health_place?.primary_color_light,
    currentHealthPlaceUser?.health_place?.primary_color_lighter,
    currentHealthPlaceUser?.health_place?.primary_color_light_2,
    currentHealthPlaceUser?.health_place?.primary_color_dark,
    currentHealthPlaceUser?.health_place?.primary_color_darker,
    currentHealthPlaceUser?.health_place?.primary_font_color
  ])
  
  const loadHealthPlaceUser = (healthPlaceUserCursor) => {
    localStorage.setItem('selectedLeaderHealthPlaceUser', healthPlaceUserCursor?.id)
    setCurrentHealthPlaceUser(healthPlaceUserCursor)
  }

  const fetchUserCouncils = async () => {
    dispatch(loading())
    try {
      let response = await getCouncils()
      setCouncils(response.data.results)
    } catch (error) {
      console.error('fetchUserCouncils ', error)
      setCouncils([])
    }
    dispatch(loaded())
  }

  const actionMenu = (e) => {
    if (e.url === 'urlchamada') {
      const urltolken = `${credenciais.url_provider}?token=${credenciais.user_token}`
      window.open(urltolken, "_blank")
    } else {
      history.push(e.go)
    }
  }

  if (currentHealthPlaceUser && !access.includes(currentHealthPlaceUser?.profile)) {
    history.push('/home')
  }

  const logOut = () => {
    localStorage.clear()
    disconnectUserStatusWebSocket()
    history.push('/login')
  }

  const loged = async () => {
    api.get(`auth/user/`)
      .then(response => {
        const user = response.data
        if (response.data && !user?.health_place_users?.length) {
          return dispatch(AddAlert('Login', 'Você não possui permissão de acesso', AlertType.WARNING))
        }
        setCurrentUser(response.data)
        let nextHealthPlaceUser = user?.health_place_users?.find(({id}) => id === currentHealthPlaceUser?.id) || user?.health_place_users?.[0]
        loadHealthPlaceUser(nextHealthPlaceUser)
        localStorage.setItem('user', JSON.stringify(user))
        connectUserStatusWebSocket(user)
      })
      .catch(error => {
        console.error('loged ~ ', error)
        dispatch([
          AddAlert('Autenticação', 'Falha ao autenticar usuário, realize o login novamente', AlertType.WARNING),
        ])
        localStorage.clear()
        history.push('/login')
      })
  }

  const connectUserStatusWebSocket = () => {
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) return;

      if (localStorage?.token) {
        socketRef.current = new WebSocket(`${wsScheme}://${wsHost}/ws/user_status/connection/?authorization=Token ${localStorage.token}`)
          
        socketRef.current.onopen = () => {
            console.log('User Status WebSocket connected')
            const pingInterval = setInterval(() => {
              if (socketRef.current.readyState === WebSocket.OPEN) {
                  socketRef.current.send(JSON.stringify({ type: 'ping' }));
              }
          }, 10000)

          socketRef.current.onclose = () => clearInterval(pingInterval);
          }

        socketRef.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            
            if (data) {
                if (data?.type === 'pong') {
                  console.log('Received pong')
                } else {
                  const activeUsers = data.active_users.reduce((acc, userId) => {
                      acc[userId] = true;
                      return acc;
                  }, {});
          
                  setOnlineUsers((prev) => ({
                      ...prev,
                      ...activeUsers,
                      [data.user_id]: data.is_online,
                  }));
                }
            }
          }

        socketRef.current.onerror = (error) => {
          console.log('User Status Websocket error: ', error)
          socketRef.current.close()
        }

        socketRef.current.onclose = () => {
          console.log('User Status websocket disconnecting...')

          if (!intentionalDisconnect.current) {
            reconnectInterval.current = setTimeout(connectUserStatusWebSocket, 5000);
          }
        }

        return () => { 
          if (socketRef.current) {
            socketRef.current.close()
          }
          if (reconnectInterval.current) {
            clearTimeout(reconnectInterval.current)
          }
        }
      }
  }

  const disconnectUserStatusWebSocket = () => {
    intentionalDisconnect.current = true
    if (socketRef.current) {
      socketRef.current.close()
        socketRef.current = null
    }
  }

  const updateMenu = (newHealthPlaceUser) => {
    if (newHealthPlaceUser && currentHealthPlaceUser) {
      setMenu(getMenuDash({
        profile: currentHealthPlaceUser?.profile,
        enabled_thousand_days: newHealthPlaceUser.enabled_thousand_days,
        enabled_preceptorship: newHealthPlaceUser.enabled_preceptorship,
        health_place: currentHealthPlaceUser?.health_place
      }))
    }
  }

  //console.log(currentHealthPlaceUser)
  return (
    <AppContext.Provider value={{
      setModalInfo,
      councils,
      fetchUserCouncils,
      currentHealthPlaceUser,
      setCurrentHealthPlaceUser,
      currentUser,
      loadHealthPlaceUser,
      updateMenu,
      logOut,
      loged,
      onlineUsers,
      isHealthProfessional,
      isAssistant,
      isAdmin,
      isAssistantOrNurse,
      isAdminOrAssistant,
      isAdminOrIsAssistantOrIsNurse,
      isHealthProfessionalOrIsNurse,
      isArrivalFilter, setIsArrivalFilter
     }}>
      <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
      {
        !currentUser?.email_verified
        ? <VerifyEmailPage />
        : !currentUser.person?.cpf
        ? <ConcludeRegisterPage />
        : !currentHealthPlaceUser && currentUser?.health_place_users?.length > 1
        ? <SelectHealthPlaceUser 
          healthPlaceUsers={user?.health_place_users}
          loadHealthPlaceUser={loadHealthPlaceUser} 
        />
        : !councils?.length && isHealthProfessional
        ? <CouncilRegisterPage />
        //: currentHealthPlaceUser && !currentHealthPlaceUser?.profile_registered_again
        //? <RegisterProfileFieldAgainPage />
        : <>
            <WebsocketContext>
              <div id='box-app'>
                  <div className='box-container-body'>
                    <div className='box-container-body-header'>
                      <div className='box-container-body-header-firstbox'>
                        <div className='box-container-body-header-firstbox-LogoWrapper'>
                          {health_place__base64_avatar 
                          ? <img 
                              alt='logo parceiro' 
                              src={health_place__base64_avatar} 
                              title='Logo Parceiro' 
                              className="box-container-body-header-firstbox-LogoWrapper-Logo-Partner" 
                            /> 
                            : <div>
                              <img 
                                className='box-container-body-header-firstbox-LogoWrapper-Logo-Salutho' 
                                alt='logo' 
                                src={logo} 
                                title='Logo Salutho' 
                              />
                            </div>
                            }
                        </div>
                        <Menu data={menu} action={(e) => actionMenu(e)} />
                      </div>

                      <div className='box-container-body-header-actionbutton'>
                      {currentHealthPlaceUser && <Chat onlineUsers={onlineUsers} />}
                      <CurrentUserDropdown />

                        <div className='box-container-body-header-lastbox-LogoWrapper'>
                          <div className='box-container-body-header-lastbox-LogoWrapper-Logo'>
                            <img className='box-container-body-header-firstbox-LogoWrapper-Logo-Salutho' alt='logo' src={logoSquare} title='Logo Salutho' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='box-container-body-content'>
                      <div className='box-container-body-content-whiteRadius'>
                        {children}
                      </div>
                    </div>
                  </div>
              </div>
            </WebsocketContext>
        </>
      }
    </AppContext.Provider>
  )
}
