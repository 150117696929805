import './InsertInventoryMaterialsModal.css'
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import NewSelect from '../../../../../../../../newcomponents/form/select/select';
import { AddAlert, AlertType, Input } from '../../../../../../../../component';
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button';
import { NewCheckbox } from '../../../../../../../../newcomponents';
import { getYearsRangeTodayToPlus50 } from '../../../../../../../../utils/getYearsRangeTodayToPlus50';
import { InventoryMaterialStockRequest } from './helpers';
import { getInventoryMaterialCategories, postInventoryMaterialCategory } from '../../../ConfigInventoryMaterials/subcomponents/CategoriesOptions/http';
import { getInventoryMaterialLocations } from '../../../ConfigInventoryMaterials/subcomponents/LocationsOptions/http';
import { getInventoryMaterialManufacturers, postInventoryMaterialManufacturer } from '../../../ConfigInventoryMaterials/subcomponents/ManufacturersOptions/http';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { patchInventoryMaterial, postInventoryMaterial } from '../../../../http';
import { useApp } from '../../../../../../../../layout/App';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import { useInventoryMaterials } from '../../../../InventoryMaterials';
import IcoBoxReady from '../../../../../../../../component/icon/box_ready';
import DeleteInventoryMaterialModal from '../DeleteInventoryMaterialModal/DeleteInventoryMaterialModal';
import DeleteIco from '../../../../../../../../component/icon/delete';
import InventoryMaterialImageModal from './InventoryMaterialImageModal/InventoryMaterialImageModal';
import { IcoBin } from '../../../../../../../../component';

const defaultOptions = require('../../../../../../../../component/form/select/options.json')
const defaultImage = require('../../../../../../../../media/defaultImage.png')

const strpHoldingInventoryMaterial = (instance=null) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    let [expiration_year, expiration_month, expiration_day] = regex.test(instance?.expiration_date)
        ? instance.expiration_date.split('-').map(value => Number(value))
        : ['', '', '']
    

    return {
        id: instance?.id || null,
        name: instance?.name || '',
        code: instance?.code || '',
        category: instance?.category?.id || '',
        manufacturer: instance?.manufacturer?.id || '',
        presentation: instance?.presentation || '',
        minimum_stock: instance?.minimum_stock || 0,
        expiration_notice: instance?.expiration_notice || 0,
        base64_avatar: instance?.picture_uuid || '',
        picture_image_url: instance?.picture_image_url || ''
    }
}

export default function InsertInventoryMaterialsModal({ initHoldingInventoryMaterial=null, fetchMaterials= () => null, openEditInsertInventoryModal= () => null }) {
    const dispatch = useDispatch()
    const searchTimeout = useRef(null)
    const [activeCategories, setActiveCategories] = useState([])
    const [activeManufacturers, setActiveManufacturers] = useState([])
    const [holdingInventoryMaterial, setHoldingInventoryMaterial] = useState(strpHoldingInventoryMaterial(initHoldingInventoryMaterial))
    const [holdingInventoryMaterialErr, setHoldingInventoryMaterialErr] = useState(strpHoldingInventoryMaterial())
    const { currentHealthPlaceUser } = useApp()
    const { setModalInfo } = useInventoryMaterials()
    const [secondaryModalInfo, setSecondaryModalInfo] = useState(MODAL_INIT_PROPS)
    const fileInputRef = useRef(null);
    const [manufacturerInputValue, setManufacturerInputValue] = useState('')
    const [categoryInputValue, setCategoryInputValue] = useState('')

    useEffect(() => {
        fetchActiveCategoriesOptions()
        // fetchActiveManufacturersOptions()
    }, [])

    const handleEditButtonClick = () => {
        fileInputRef.current.click()
    }

    const openProfileImageModal = (event) => {
        event.preventDefault();
        let files;
        if (event.dataTransfer) {
            files = event.dataTransfer.files;
        } else if (event.target) {
            files = event.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setSecondaryModalInfo({
                open: true,
                title: 'Editar Foto',
                content: <InventoryMaterialImageModal
                            inventoryMaterial={{id: holdingInventoryMaterial.id, base64_avatar: reader.result }}
                            setModalInfo={setSecondaryModalInfo}
                            fetchExtraAction={(base64_avatar) => updateMaterialImage(base64_avatar)
                        }
                        />,
                dismissText: '',
            });
        };
        reader.readAsDataURL(files[0]);
    }

    const updateMaterialImage = (base64_avatar) => {
        setHoldingInventoryMaterial(prev => ({ ...prev, 'picture_image_url': base64_avatar }))
        fetchMaterials({ "offset": 0 })
    }

    const fetchActiveCategoriesOptions = async (params={}) => {
        try {
            let res = await getInventoryMaterialCategories({ active: true, ...params })
            setActiveCategories(res.data.results)
        } catch (err) {
            setActiveCategories([])
            console.error('fetchActiveCategoriesOptions ~ ', err)
        }
    }

    const fetchActiveManufacturersOptions = async (params={}) => {
        try {
            let res = await getInventoryMaterialManufacturers({ active: true, ...params })
            setActiveManufacturers(res.data.results)
        } catch (err) {
            setActiveManufacturers([])
            console.error('fetchActiveManufacturersOptions ~ ', err)
        }
    }
    
    const handleChange = (event) => {
        let value = event.target.value
        if (event.target.name === 'code') {
            value = value.toUpperCase()
        } else if ((event.target.name === 'batch_quantity' || event.target.name === 'minimum_stock') && value > 10000000) {
            value = 10000000
        } else if (event.target.name === 'expiration_notice' && value > 1095) {
            value = 1095
        } else if ((event.target.name === 'batch_quantity' || event.target.name === 'minimum_stock' || event.target.name === 'expiration_notice') && value < 0) {
            value = 0
        } else if (event.target.name === 'batch_quantity' || event.target.name === 'minimum_stock' || event.target.name === 'expiration_notice') {
            value = Number(value)
        } 
        setHoldingInventoryMaterial(prev => ({ ...prev, [event.target.name]: value }))
        setHoldingInventoryMaterialErr(prev => ({ ...prev, [event.target.name]: false }))
    }

    const handleSelect = (event) => {
        setHoldingInventoryMaterial(prev => ({ ...prev, [event.target.id]: event.target.selected }))

        let field = event.target.id
        if (field === 'expiration_day' || field === 'expiration_month' || field === 'expiration_year') {
            setHoldingInventoryMaterialErr(prev => ({ ...prev, 'expiration_day': false, 'expiration_month': false, 'expiration_year': false }))
        } else {
            setHoldingInventoryMaterialErr(prev => ({ ...prev, [event.target.id]: false }))
        }
    }

    const handleClearFields = () => {
        setHoldingInventoryMaterial(strpHoldingInventoryMaterial())
        setHoldingInventoryMaterialErr(strpHoldingInventoryMaterial())
    }

    const handleSubmit = async () => {
        const payload = new InventoryMaterialStockRequest({ ...holdingInventoryMaterial, health_place: currentHealthPlaceUser?.health_place?.id })

        if (!payload.isValid()) {
            let errors = payload.getErrors()
            if (Object.keys(errors).length) return setHoldingInventoryMaterialErr(errors)
        }

        dispatch(loading())
        try {
            if (payload?.id) {
                await patchInventoryMaterial(payload?.id, payload)
            } else {
                await postInventoryMaterial(payload)
            }
            fetchMaterials({ "offset": 0 })
            setHoldingInventoryMaterial(strpHoldingInventoryMaterial())
            dispatch(AddAlert('Estoque de Materiais', 'Material salvo com sucesso!', AlertType.SUCCESS))
            setModalInfo(MODAL_INIT_PROPS)
        } catch (err) {
            console.error('handleSubmit ~ ', err)
        }
        dispatch(loaded())
    }

    const handleSearchCategory = (event) => {
        setCategoryInputValue(event.target.value)
        if (searchTimeout.current) clearTimeout(searchTimeout.current)
    
        searchTimeout.current = setTimeout(() => {
            fetchActiveCategoriesOptions({ 'name__icontains': event.target.value })
        }, 400, event.target.value)
    }

    const handleAddNewCategory = async (value) => {
        if (!value) {
            dispatch(AddAlert('Estoque de Materiais', 'Preencha o campo para adicionar Categoria.', AlertType.ERROR))
        }
        const payload = {name: value ,health_place: currentHealthPlaceUser?.health_place?.id }

        try {
            await postInventoryMaterialCategory(payload);
            fetchActiveCategoriesOptions({name: value})
            setCategoryInputValue('')
            dispatch(AddAlert('Estoque de Materiais', 'Categoria salva com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            console.log('handleAddNewCategory ~ ', err)
            dispatch(AddAlert('Estoque de Materiais', 'Erro em salvar categoria. Tente novamente na aba de Configurações.', AlertType.ERROR))
        }
    }

    return <div className='InsertInventoryMaterialsModal'>
            <Modal {...secondaryModalInfo} dismissFn={() => setSecondaryModalInfo(MODAL_INIT_PROPS)}/>
            {
                /*
            <div className='InsertInventoryMaterialsModal-Subtitle'>
                <div><IcoBoxReady style={{ width: '32px', height: '32px' }} /></div>
                <b>Salvar Material</b>
            </div>
                */
            }
            <div className='InsertInventoryMaterialsModal-Form'>
                <div className='InsertInventoryMaterialsModal-Form-Body'>
                    <div className='InsertInventoryMaterialsModal-Form-Inputs'>
                        <div className='InsertInventoryMaterialsModal-Input InsertInventoryMaterialsModal-Input-2Col'>
                            <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Nome</span>
                            <Input
                                name='name'
                                action={handleChange}
                                value={holdingInventoryMaterial.name}
                                errors={{ error: holdingInventoryMaterialErr, message: holdingInventoryMaterialErr.name }}
                                maxLength='100'
                            />
                        </div>
                        <div className='InsertInventoryMaterialsModal-Input'>
                            <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Código</span>
                            <Input
                                name='code'
                                action={handleChange}
                                value={holdingInventoryMaterial.code}
                                errors={{ error: holdingInventoryMaterialErr, message: holdingInventoryMaterialErr.code }}
                                maxLength='50'
                            />
                        </div>
                        <div className='InsertInventoryMaterialsModal-Select'>
                            <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Categoria</span>
                            <NewSelect 
                                id='category'
                                onSelect={handleSelect}
                                selected={holdingInventoryMaterial.category}
                                options={activeCategories}
                                optionStrKey='name'
                                error={holdingInventoryMaterialErr.category}
                                addNewCategoryPermission={true}
                                handleAddNewCategory={() => handleAddNewCategory(categoryInputValue)}
                                filterNode={<div className='InsertInventoryMaterialsModal-NewSelect'>
                                    <Input
                                        placeholder='Pesquisar por categoria'
                                        action={handleSearchCategory}
                                        actionFocus={()=> fetchActiveCategoriesOptions()}
                                        value={categoryInputValue}
                                    />
                                </div>}
                            />
                        </div>
                        {/* <div className='InsertInventoryMaterialsModal-Form-Body-1W InsertInventoryMaterialsModal-Select'>
                            <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Fabricante</span>
                            <NewSelect 
                                id='manufacturer'
                                onSelect={handleSelect}
                                selected={holdingInventoryMaterial.manufacturer}
                                options={activeManufacturers}
                                optionStrKey='name'
                                error={holdingInventoryMaterialErr.manufacturer}
                                addNewCategoryPermission={true}
                                handleAddNewCategory={() => handleAddNewManufacturer(manufacturerInputValue)}
                                filterNode={<div className='InsertInventoryMaterialsModal-NewSelect'>
                                    <Input
                                        placeholder='Pesquisar por fabricante'
                                        action={handleSearchManufacturer}
                                        actionFocus={()=> fetchActiveManufacturersOptions()}
                                        value={manufacturerInputValue}
                                    />
                                </div>}
                            />
                        </div> */}
                        {/* <div className='InsertInventoryMaterialsModal-Form-Body-1W InsertInventoryMaterialsModal-Input'>
                            <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Apresentação</span>
                            <Input
                                name='presentation'
                                action={handleChange}
                                value={holdingInventoryMaterial.presentation}
                                errors={{ error: holdingInventoryMaterialErr, message: holdingInventoryMaterialErr.presentation }}
                                maxLength='50'
                                placeholder='Ex.: Garrafa, pacote, etc.'
                            />
                        </div> */}
                        <div className='InsertInventoryMaterialsModal-Form-Body-1W InsertInventoryMaterialsModal-Select'>
                            <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Estoque mínimo para aviso</span>
                            <Input
                                name='minimum_stock'
                                action={handleChange}
                                value={holdingInventoryMaterial.minimum_stock}
                                errors={{ error: holdingInventoryMaterialErr, message: holdingInventoryMaterialErr.minimum_stock }}
                                maxLength='4'
                                type='number'
                                min='0'
                                max='10000000'
                                placeholder='Ex.: 10, 25, 50'
                            />
                        </div>
                        <div className='InsertInventoryMaterialsModal-Form-Body-1W InsertInventoryMaterialsModal-Input'>
                            <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Aviso de vencimento (Dias)</span>
                            <Input
                                name='expiration_notice'
                                action={handleChange}
                                value={holdingInventoryMaterial.expiration_notice}
                                errors={{ error: holdingInventoryMaterialErr, message: holdingInventoryMaterialErr.expiration_notice }}
                                maxLength='4'
                                type='number'
                                min='0'
                                max='1095'
                                placeholder='Ex.: 10, 25, 50'
                            />
                        </div>
                    </div>
                    <div className='InsertInventoryMaterialsModal-Form-ImgBox'>
                    <div className='PersonalDataProfileImage-Header-Title'>Foto do Material</div>
                        <div>
                            <input 
                                type="file" 
                                className="PersonalDataProfileImage-HideChooseFile" 
                                name="file_photo"
                                ref={fileInputRef}
                                onChange={openProfileImageModal}
                                style={{ display: 'none' }}
                            />
                            <div className='PersonalDataProfileImage-Container-Avatar'>
                                <div className='img'>
                                    <img
                                        onClick={handleEditButtonClick}
                                        className='PersonalDataProfileImage-Container-Avatar-Img'
                                        alt='Foto de perfil'
                                        src={holdingInventoryMaterial?.picture_image_url || defaultImage}
                                    />
                                </div>
                            </div>
                            <div className='PersonalDataProfileImage-ActionRow'>
                                <div 
                                    className='PersonalDataProfileImage-ActionRow-Btn' 
                                    onClick={handleEditButtonClick}
                                >
                                    <b>Editar</b>
                                </div>
                            </div>
                            </div> 
                        </div>
                </div>
                <div className='InsertInventoryMaterialsModal-Form-ActionRow'>
                    <div className='InsertInventoryMaterialsModal-Form-ActionRow-BtnBox'>
                        {initHoldingInventoryMaterial && (<div
                            title='Excluir material'
                            className='InsertInventoryMaterialsModal-Form-ActionRow-DeleteButton'
                            onClick={() => setSecondaryModalInfo(prev => ({ ...prev, 
                                open: true,
                                title: 'Excluir Material',
                                content: <DeleteInventoryMaterialModal
                                    holdingInventoryMaterial={initHoldingInventoryMaterial}
                                    fetchMaterials={fetchMaterials}
                                    setModalInfo={setSecondaryModalInfo}
                                    fetchExtraAction={() => setModalInfo(MODAL_INIT_PROPS)}
                                />
                            }))}
                            >
                            <IcoBin style={{ width: '28px', height: '28px', fill: '#aaa'}} />
                        </div>)}
                        
                        <NewButton 
                            label='Limpar campos'
                            onClick={handleClearFields}
                            model={ButtonModel.SECONDARY}
                        />
                        <NewButton 
                            label='Salvar cadastro'
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
}