import './DocumentsEmission.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MevoPrescription from './subcomponents/Mevo/MevoPrescription'
import { useAttendance } from '../../../../../../../../consultation'
import { patchSignatureMevo, postSignatureMevo } from '../../../../../../../../http'
import NewButton, { ButtonModel } from '../../../../../../../../../../newcomponents/button/button'
import Loading from '../../../../../../../../../../newcomponents/loading/loading'
import { AddAlert, AlertType } from '../../../../../../../../../../component'
import api from '../../../../../../../../../../helpers/api'
import DocumentsHistory from './subcomponents/DocumentsHistory/DocumentsHistory'
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal'
import { useApp } from '../../../../../../../../../../layout/App'
import EditDoctorAddress from './subcomponents/EditDoctorAddress/EditDoctorAddress'
import { useHistory } from 'react-router-dom'


export default function DocumentsEmission() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { attendance, isEditable } = useAttendance()
    const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
    const { currentHealthPlaceUser } = useApp()
    const user_profile = currentHealthPlaceUser?.profile
    const [loading, setLoading] = useState(false)
    const [mevoModalURL, setMevoModalURL] = useState('')
    const [openSection, setOpenSection] = useState('documents')
    const hideMevo = user_profile !== 'DOCTOR' || !isEditable

    const cancelMevo = async () => {
        console.log("Evento lido: Terminar Mais Tarde")
        try {
            setMevoModalURL('cancel')
            await patchSignatureMevo(attendance.id)
        } catch (err) {
            dispatch(AddAlert('Receituário Mevo', 'Falha ao salvar informações para terminar mais tarde', AlertType.ERROR))
        }
    }

    const excludedMevo = () => {
        console.log("Evento lido: Cancelado")
        setMevoModalURL('excluded')
    }

    const saveMevo = async (documents) => {
        console.log("Evento lido: Salvar")
        setLoading(true)
        try {
            await postSignatureMevo(attendance.id, documents)
            document.dispatchEvent(new CustomEvent("DocumentsHistoryFetchActivities"))
            setMevoModalURL('')
            setLoading(false)
            dispatch(AddAlert('Receituário Mevo', 'Documento(s) criado(s) com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            setLoading(false)
            dispatch(AddAlert('Receituário Mevo', 'Falha ao fazer upload dos Documentos, tente novamente!', AlertType.ERROR))
        }
    }

    const getMevoModalURL = () => {
        api.get(`appointment/health_document/signature/mevo/${attendance.id}/`)
            .then(res => setMevoModalURL(res.data.ModalURL))
            .catch(err => err.response.data.error ? setMevoModalURL(err.response.data.error) : setMevoModalURL('error'))
    }

    useEffect(() => {
        const setupEvents = (event) => {
            const validEvents = ['cancel','excluded','prescricao']
            if (validEvents.includes(event.data.type)) {
                const actions = {
                    'cancel': cancelMevo,
                    'excluded': excludedMevo,
                    'prescricao': () => saveMevo(event.data.Documentos)
                }
                const action = actions[event.data.type]
                if (action) action()
            }
        }

        window.addEventListener("message", setupEvents)
        return _ => {
            window.removeEventListener("message", setupEvents)
        }
    })     

    if (attendance?.extra_kwargs?.external_doctor_name) {
        return <p>Emissão não disponível para esse atendimento.</p>;
    }

    if (!currentHealthPlaceUser?.user?.person?.is_address_filled) {
        return <div>
            <span className='DocumentsEmission-Message-Title'>Para acessar a área de documentos, algumas atualizações cadastrais são necessárias:</span>
            <div>
                <p>Seus dados:</p>
                <ul>
                    <li><b>Endereço profissional:</b> necessário para emissão de documentos.</li>
                </ul>
            </div>
            <div className='DocumentsEmission-Message-ButtonBox'>
                <NewButton
                    onClick={() => window.open('/configuration/person')}
                    label='Cadastrar meu endereço'
                />
                <NewButton 
                    onClick={() => window.location.reload()}
                    model={ButtonModel.SECONDARY}
                    label='Já atualizei meu endereço'
                />
            </div>
        </div>
    }

    if (loading) return <div className='DocumentsEmission-Loading'>
        <Loading />
    </div>

    const sections = {
        'documents': <div className='DocumentsEmission-Body'>
            <DocumentsHistory
                attendance={attendance} 
                setModalInfo={setModalInfo}
            />
        </div>,
        'mevo': <div className='DocumentsEmission-Body'>
            <MevoPrescription 
                mevoModalURL={mevoModalURL} 
                setMevoModalURL={setMevoModalURL} 
                getMevoModalURL={getMevoModalURL} 
                setModalInfo={setModalInfo}
            />
        </div>
    }
    
    
    return <>
        <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
        <div className='DocumentsEmission-TabManager'>
            <div className={`DocumentsEmission-TabManager-btn ${openSection === 'documents' ? 'active' : ''}`} onClick={() => setOpenSection('documents')}>
                Documentos
            </div>
            {
                !hideMevo
                ? <div className={`DocumentsEmission-TabManager-btn ${openSection === 'mevo' ? 'active' : ''}`} onClick={() => setOpenSection('mevo')}>
                    Mevo
                </div>
                : null
            }
        </div>
        {sections[openSection]}
    </>
}