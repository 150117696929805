import './ListEntryBatchMaterials.css'
import React, { useEffect, useState } from "react";
import { getMaterialBatch, patchMaterialBatch, postMaterialBatch } from "../../http";
import { AddAlert, AlertType, List, MaskDataHora, Paginate } from "../../../../../../component";
import CancelIcon from "../../../../../../component/icon/cancel";
import { useDispatch } from 'react-redux';
import FilterEntryBatchParams from './FilterEntryBatchParams/FilterEntryBatchParams';
import { useInventoryMaterials } from '../../InventoryMaterials';
import Loading from '../../../../../../newcomponents/loading/loading';
import DeleteEntryBatchMaterialModal from './DeleteEntryBatchMaterialModal/DeleteEntryBatchMaterialModal';

const INIT_FILTER_PARAMS = { material__name__icontains: '', material__code__icontains: '', material__active: true, responsible__id: null, offset: 0 }

export default function ListEntryBatchMaterials() {
    const limit = 20
    const dispatch = useDispatch()
    const { setModalInfo, canReadAndWrite } = useInventoryMaterials()
    const [entries, setEntries] = useState([])
    const [filterParams, setFilterParams] = useState(INIT_FILTER_PARAMS)
    const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: limit, totalElements: 0 })
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetchBatchEntries({ ...filterParams, "offset": 0 })
    }, [filterParams])
    
    const fetchBatchEntries = async (params={}) => {
        setIsLoading(true)
        
        let materialEntriesResponse = await getMaterialBatch({...params, limit})
        console.log('entries', materialEntriesResponse.data.results);
        
        
        setEntries(materialEntriesResponse.data.results)
        setPagination({
            totalElements: materialEntriesResponse.data.count,
            totalPages: Math.ceil(materialEntriesResponse.data.count / limit),
            pageNumber: params.offset / limit || 0,
            next: materialEntriesResponse.data.next,
            previous:  materialEntriesResponse.data.previous
        })
        setIsLoading(false)
    }

    const handlefetchBatchEntries = async () => {
        await fetchBatchEntries({ ...filterParams, "offset": 0 })

    }
    
    const handleCancel = async (assignment) => {
        try {

                await patchMaterialBatch(assignment?.id, { "active": false })

            await handlefetchBatchEntries()
            dispatch(AddAlert('Requisição de Material', 'Requisição cancelada com sucesso!', AlertType.SUCCESS))            
        } catch (err) {
            dispatch(AddAlert('Requisição de Material', 'Falha ao cancelar requisição.', AlertType.ERROR))            
            console.error('ListRequestMaterials ~ patchInventoryMaterialAssignment ~ ', err)
        }
    }
    
    const handleNextPage = offset => {
        fetchBatchEntries({ ...filterParams, "offset": offset * limit })
    }
    
    const openCancelRequestModal = (request) => {
        setModalInfo(prev => ({ ...prev, 
            open: true, 
            title: 'Cancelar entrada', 
            content: <DeleteEntryBatchMaterialModal request={request} handleCancel={handleCancel} setModalInfo={setModalInfo}/> 
        }))
    }

    return <div className="ListRequestMaterials">

        <FilterEntryBatchParams
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            fetchBatchEntries={handlefetchBatchEntries}
            INIT_FILTER_PARAMS={INIT_FILTER_PARAMS} 
        />
        {!isLoading ?
        <div>
            <div className='ListEntryBatchMaterials-List-Container'>
                <List 
                    head={
                        canReadAndWrite
                        ? [
                            { colunm: 'h_material', text: 'Material' },
                            { colunm: 'h_code', text: 'Código' },
                            { colunm: 'h_time', text: 'Última atualização' },
                            { colunm: 'h_responsible__id', text: 'Responsável' },
                            { colunm: 'h_requested_amount', text: 'Quantidade' },
                            { colunm: 'h_active', text: 'Status da Entrada' },
                            { colunm: 'h_action', text: <div className='ListEntryBatchMaterials-HeaderRow-Action'>Ações</div>, width: '72px' }
                        ]
                        : [
                            { colunm: 'h_material', text: 'Material' },
                            { colunm: 'h_code', text: 'Código' },
                            { colunm: 'h_time', text: 'Última atualização' },
                            { colunm: 'h_responsible__id', text: 'Requisitante' },
                            { colunm: 'h_requested_amount', text: 'Quantidade' },
                            { colunm: 'h_active', text: 'Status da Entrada' },
                        ]}
                    data={entries}
                    listCustom={custom => {
                        let cursor = custom
                        const time = cursor?.time ? MaskDataHora(cursor?.time, 'DD/MMM/AAAA', '-') : MaskDataHora(cursor?.registration_date, 'DD/MMM/AAAA', '-')
                        cursor['h_material'] = cursor?.material?.name || '-'
                        cursor['h_code'] = cursor?.material?.code || '-'
                        cursor['h_responsible__id'] = cursor?.responsible?.name || '-'
                        cursor['h_time'] = time?.data && time?.hora ? `${time.data} ${time.hora}` : '-'
                        cursor['h_requested_amount'] = cursor?.requested_amount || cursor?.batch_quantity || '-'
                        cursor['h_active'] = cursor?.active
                        ? <div className='ListEntryBatchMaterials-Status-Subtitle ListEntryBatchMaterials-Success'>Confirmada</div>
                            : <div className='ListEntryBatchMaterials-Status-Subtitle ListEntryBatchMaterials-Error'>Cancelada</div>
                        custom['h_action'] = (
                            <div className='ListEntryBatchMaterials-ActionRow'>
                                {
                                    cursor?.active
                                    ? <div
                                    
                                        className={cursor?.hasAssignment ? 'action-disabled' : ''}
                                        title={cursor?.hasAssignment ? 'Cancelamento desativado: este lote já foi requisitado.' : 'Cancelar entrada'}
                                        onClick={!cursor?.hasAssignment ? () => openCancelRequestModal(cursor) : undefined}                         
                                    >
                                        <CancelIcon 
                                            
                                        />
                                    </div>
                                    : null
                                }
                            </div>
                        )
                        return custom
                    }}
                />
                <div>
                    <Paginate data={pagination} action={handleNextPage} />
                </div>
            </div>
        </div> : 
        <div className='ListEntryBatchMaterials-Loading-Container'>
            <Loading/>
        </div>
        }
    </div>
}