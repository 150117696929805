import React from 'react'

export default function NoteCheckIco({ style = {}, width, height }) {  
  return (
    <svg 
      style={{ width: width || "24px", height: height || "24px", ...style }}  
      viewBox="0 0 24 24" 
      data-name="Line Color" 
      xmlns="http://www.w3.org/2000/svg" 
    >
      <rect 
        x="9" y="3" width="6" height="3" rx="1" 
        style={{ fill:'none',strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2, ...style }} 
      />
      <path 
        data-name="secondary" 
        d="m9 13 2 2 4-4"
        style={{ fill: "none",  strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2, ...style }} 
      />
      <path 
        d="M18 4a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1" 
        style={{ fill: "none",  strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2, ...style }}
      />
    </svg>
  )
}
