import './ConsultationManager.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { IcoEstetoscopio } from '../../../../component'
import Button from '../../../../newcomponents/button/button'
import Modal, { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal'
import NotesSection from './subcomponents/NotesSection/NotesSection'
import AttendanceHistorySection from '../AttendanceHistorySection/AttendanceHistorySection'
import { useAttendance } from '../../consultation'
import { NotesIcon } from '../../../../component/icon/notes'
import { PersonIcon } from '../../../../component/icon/person'
import CheckOutlinedIcon from '../../../../component/icon/check-outlined'
import HoldingPatientInfoView from '../../../datailPatient/HoldingPatientInfoView'
import HealthProfileSection from './subcomponents/HealthProfileSection/HealthProfileSection'
import SOAPSection from './subcomponents/SOAP/SOAP'
import FinishAppointmentModal from './subcomponents/FinishAppointmentModal/FinishAppointmentModal'
import DocumentsEmission from './subcomponents/SOAP/subcomponents/Plan/subcomponents/DocumentEmission/DocumentsEmission'
import IcoHealthProfile from '../../../../component/icon/health_profile'
import { DocumentsIcon } from '../../../../component/icon/documents'
import IcoBook from '../../../../component/icon/book'
import LogsAppointmentsBookSection from './subcomponents/LogsAppointmentsBookSection/LogsAppointmentsBookSection'

const ActionButtons = () => {
    const dispatch = useDispatch()
    const { isEditable } = useAttendance()
    const { saveHoldingPreceptorship,canWritePreceptorship } = useAttendance()
    const [modalProps, setModalProps] = useState(MODAL_INIT_PROPS)
    
    let renderSaveButton = isEditable

    const openFinishAppointmentModal = () => {
        setModalProps(prev => ({
            ...prev,
            "open": true,
            "title": "Finalizar consulta",
            "content": <FinishAppointmentModal setModalInfo={setModalProps} />
        }))
    }

    return <div className='ConsultationManager-button-actions btn-box'>
        <Modal {...modalProps} dismissFn={() => setModalProps(MODAL_INIT_PROPS)} />
        {renderSaveButton && (
            <Button id='ConsultationManager-button-actions-save'
                label='Finalizar Consulta'
                onClick={openFinishAppointmentModal}
                disabled={!isEditable}
            />
        )}
        {
            canWritePreceptorship
            ? <Button 
                label='Salvar avaliação'
                onClick={saveHoldingPreceptorship}
            />
            : null
        }
    </div>
}

function getLastUpdateTime(last_updated) {
    let hours =  last_updated.getHours().toString().padStart(2, '0')
    let minutes = last_updated.getMinutes().toString().padStart(2, '0')
    return hours + ':' + minutes
}

export default function ConsultationManager() {
    const dispatch = useDispatch()
    const {
        attendance,
        lastUpdate,
        mainOpenConsultationTab,
        setMainOpenConsultationTab,
        openTemplateTypesManager 
    } = useAttendance()

    const sections = {
        'soap': <SOAPSection />,
        'notes': <NotesSection />,
        'attendancesHistory': <AttendanceHistorySection />,
        'healthprofile': <HealthProfileSection />,
        'patientdata': <HoldingPatientInfoView initHoldingPatientID={attendance?.person?.id} />,
        'documents_emission': <DocumentsEmission />,
        'change_book': <LogsAppointmentsBookSection />,
    }

    return <div className='ConsultationManager'>
    <div className='ConsultationManager-Body'>
        {
            !attendance?.template_type?.id
            ? <div
                onClick={() => openTemplateTypesManager()}
                className='ConsultationManager-overlay'
            >
                <p>Selecionar Template</p>
            </div>
            : null
        }

        <div className='ConsultationManager-sideleft'>
            <div className={`ConsultationManager-sideleft-btn ${mainOpenConsultationTab === 'soap' ? 'active' : ''}`} onClick={() => setMainOpenConsultationTab('soap')}>
                <IcoEstetoscopio style={{ fontSize: '21px', marginRight: '12px'}} /> Soap
            </div>
            <div className={`ConsultationManager-sideleft-btn ${mainOpenConsultationTab === 'healthprofile' ? 'active' : ''}`} onClick={() => setMainOpenConsultationTab('healthprofile')}>
                <IcoHealthProfile style={{ fontSize: '24px', marginRight: '12px', width: '24px'}} /> Perfil de saúde
            </div>
            <div className={`ConsultationManager-sideleft-btn ${mainOpenConsultationTab === 'notes' ? 'active' : ''}`} onClick={() => setMainOpenConsultationTab('notes')}>
                <NotesIcon style={{ fontSize: '21px', marginRight: '12px'}} />  Anotações
            </div>
            <div className={`ConsultationManager-sideleft-btn ${mainOpenConsultationTab === 'patientdata' ? 'active' : ''}`} onClick={() => setMainOpenConsultationTab('patientdata')}>
                <PersonIcon style={{ fontSize: '24px', marginRight: '12px'}} /> Paciente
            </div>
            <div className={`ConsultationManager-sideleft-btn ${mainOpenConsultationTab === 'documents_emission' ? 'active' : ''}`} onClick={() => setMainOpenConsultationTab('documents_emission')}>
                <DocumentsIcon style={{ fontSize: '24px', marginRight: '12px', width: '24px' }} /> Documentos
            </div>
            <div className={`ConsultationManager-sideleft-btn ${mainOpenConsultationTab === 'change_book' ? 'active' : ''}`} onClick={() => setMainOpenConsultationTab('change_book')}>
                <IcoBook style={{ fontSize: '24px', marginRight: '12px', width: '24px' }} /> Livro de Alterações
            </div>

        </div>
        <div className='ConsultationManager-separator' />
        <div className='ConsultationManager-mainOpenConsultationTab'>
            { sections[mainOpenConsultationTab] }
        </div>
    </div>
    <div className='ConsultationManager-footer'>
        <div className='ConsultationManager-footer-lastsave'><CheckOutlinedIcon style={{ width: '12px', fill: '' }}/>Atendimento salvo pela última vez em {getLastUpdateTime(lastUpdate)}</div> {/* FIXME  */}
        <ActionButtons />
    </div>
    </div>
}