import React, { useState } from "react";
import './LogsAppointmentsBookSection.css'
import LogsAppointments from "./subcomponents/LogsAppointments/LogsAppointments";
import LogsRecords from "./subcomponents/LogsRecords/LogsRecords";


export default function LogsAppointmentsBookSection() {
    const [openSection, setOpenSection] = useState('records')

    const contents = {
        'appointments': <LogsAppointments openSection={openSection} setOpenSection={setOpenSection} />,
        'records': <LogsRecords openSection={openSection} setOpenSection={setOpenSection} />,
    }

    return <div>
        { contents[openSection] }
    </div>
}
