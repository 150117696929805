import './MaterialBatchChart.css'
import { BoxIcon } from '../../../../../../../../../component'
import IcoBoxBarcode from '../../../../../../../../../component/icon/box_barcode'
import WarningOutlinedIcon from '../../../../../../../../../component/icon/warning-outlined'
import { useEffect, useState } from 'react'
import { useApp } from '../../../../../../../../../layout/App'
import MaterialAssignment from './MaterialAssignment/MaterialAssignment'


const itemSourceEntryOptions = [
    {'id': 'purchase', 'name':'Compra'},
    {'id': 'free_sample', 'name':'Amostra Grátis'},
    {'id': 'bonus', 'name':'Bonificação'},
    {'id': 'material_utiliation', 'name':'Aproveitamento de material'},
    {'id': 'external_loan', 'name':'Emprestimo externo'},
    {'id': 'transfer', 'name':'Transferência'},
    {'id': 'return', 'name':'Devolução'},
    {'id': 'donation', 'name':'Doação'} 
]

export default function MaterialBatchChart({materialBatchInfo, onSubmit}) {
    const currentHealthPlaceUser = useApp().currentHealthPlaceUser
    console.log('BATCH INFO',materialBatchInfo);
    

    const batchInfo = materialBatchInfo
    const batchSource = itemSourceEntryOptions.find(option => option.id === batchInfo.source)?.name || 'Desconhecido'
    const isResponsible = currentHealthPlaceUser.id == batchInfo?.responsible?.id
    const [warning, setWarning] = useState('hidden')
    const [registrationDate, setRegistrationDate] = useState(0)
    const [isBatchAllocationFormOpen, setIsBatchAllocationFormOpen] = useState(false)
    
    useEffect(() => {
        setFormatedRegistrationDate()
        updateWarningStatus()
        setIsBatchAllocationFormOpen(false)
    },[materialBatchInfo])

    const formatToMMDDYYYY = (dateStr) => {
        if (!dateStr) return '';
    
        const [year, month, day] = dateStr.split('-');
        
        return `${day}/${month}/${year}`;
    }

    const formatCustomDateTime = (inputStr) =>  {
        if (!inputStr) return '';
        
        const [datePart, timePart] = inputStr.split('T');
        
        const formattedDate = formatToMMDDYYYY(datePart);
        
        const [hour, minute] = timePart.split(':'); 

        return `${formattedDate} - ${hour}:${minute}`;
    }

    const setFormatedRegistrationDate = () => {
        const formatedDateTime = formatCustomDateTime(batchInfo.registration_date)
        setRegistrationDate(formatedDateTime)
    }
    const getExpirationStatus = () => {
        const expiringNoticeDays = batchInfo.material.expiration_notice || 0;
        const today = new Date();
        // Set expiration to the end of the day to avoid timezone issues.
        const expiration = new Date(batchInfo.expiration_date + "T23:59:59");
    
        if (isNaN(expiration.getTime())) return ''; 
    
        if (expiration < today) {
            return 'expired';
        } 
        
        const noticeDate = new Date();
        noticeDate.setDate(today.getDate() + expiringNoticeDays);
    
        if (noticeDate >= expiration) {
            return 'warning';
        } 
    
        return ''; 
    }

    const getQuantityStatus = () => {
        const minimumStock = batchInfo.material.minimum_stock;
        const batchQuantity = batchInfo.remaining;
    
        if (batchQuantity <= minimumStock) {
            return 'warning';
        }
    
        return '';
    }

    const handleTogleBatchAllocationForm = () => {
        setIsBatchAllocationFormOpen(!isBatchAllocationFormOpen)
    }

    // Atualiza o estado de 'warning' corretamente dentro do useEffect
    const updateWarningStatus = () => {
        const expirationWarning = getExpirationStatus();
        const quantityWarning = getQuantityStatus();
        
        if (expirationWarning === 'expired')  {
            setWarning('expired');
        } else if (expirationWarning === 'warning' || quantityWarning === 'warning'){
            setWarning('warning');
        } else {
            setWarning('hidden');
        }
    }

    return (
        <div className="MaterialBatchChart">
            {
                !isBatchAllocationFormOpen ?
                <>

                        <div className='MaterialBatchChart-Header'>
                        <div className='MaterialBatchChart-Header-Registration'>
                            <BoxIcon style={{width: "1.8rem", stroke: "#ccc"}}/>
                            <p>{registrationDate}</p>
                        </div>
                        <div className='MaterialBatchChart-Header-Actions'>
                        <div className={`MaterialBatchChart-Header-Warning ${warning}`}>
                                <WarningOutlinedIcon style={{ width: '1.8rem', height: '1.8rem'}} />
                            </div>
                            <div className='MaterialBatchChart-Header-Btn'
                                title='Requisitar material'
                                onClick={handleTogleBatchAllocationForm}
                            >
                                <IcoBoxBarcode/>
                            </div>
                        </div>
                    </div>
                    <div className='MaterialBatchChart-Info'>
                        <div className='MaterialBatchChart-Info-Container'>
                            <p className={isResponsible ? 'responsible' : ''}><span>Responsável:</span> {batchInfo?.responsible?.name || '-'}</p>
                            <p><span>Localização:</span> {batchInfo?.location?.name || '-'}</p>
                            <p><span>Apresentação:</span> {batchInfo?.presentation || '-'}</p>
                        </div>
                        <div className='MaterialBatchChart-Info-Container'>
                            <p className={getQuantityStatus()}><span>Quantidade:</span> {batchInfo?.remaining} unidades</p>
                            <p className={getExpirationStatus()} ><span>Validade:</span> {formatToMMDDYYYY(batchInfo?.expiration_date)}</p>
                            <p><span>Fabricante:</span> {batchInfo?.manufacturer?.name}</p>
                            <p><span>Origem:</span> {batchSource}</p>
                            <p className='MaterialBatchChart-Info-Note-Title'><span>Observações:</span></p>
                            <div className='MaterialBatchChart-Info-Note'>
                                <p>{batchInfo.note || 'Sem observações'}</p>
                            </div>
                        </div>
                    </div>
                </>
            

            :
            <MaterialAssignment batchInfo={batchInfo} closeForm={handleTogleBatchAllocationForm} onSubmit={onSubmit}/>
            }
            
        </div>
    )
}