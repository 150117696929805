import './DeleteRequestMaterialModal.css'
import React from "react";
import { useDispatch } from 'react-redux';
import NewButton, { ButtonModel } from '../../../../../../../newcomponents/button/button';
import { MODAL_INIT_PROPS } from '../../../../../../../newcomponents/modal/modal';

export default function DeleteRequestMaterialModal({ request=null, handleCancel=() => null, setModalInfo }) {

    const handleConfirmAction = () => {
        handleCancel(request)
        setModalInfo(MODAL_INIT_PROPS)

    }

    const handleCancelAction = () => {
        setModalInfo(MODAL_INIT_PROPS)
    }

    return <div className='DeleteRequestMaterialModal'>
        <p>Tem certeza que deseja cancelar a requisição?</p>
        <p>A ação não poderá ser desfeita.</p>
        <div className='DeleteRequestMaterialModal-BtnBox'>
            <NewButton 
                label='Voltar'
                model={ButtonModel.SECONDARY}
                onClick={handleCancelAction}
            />
            <NewButton 
                label='Cancelar'
                model={ButtonModel.DANGER}
                onClick={handleConfirmAction}
            />
        </div>
    </div>
}