import { useEffect, useState } from 'react'
import './NextAppointmentInfo.css'
import { useApp } from '../../../../../../layout/App'
import { PatientIcon } from '../../../../../../component/icon/patient'
import { IcoEstetoscopio } from '../../../../../../component'
import { patchAttendance } from '../../../../../AttendanceManager/http'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { IcoArrowForward, IcoArrowBack } from '../../../../../../component'
import choices from './choices.json'
import { getFormatedTimeFromDate } from '../../../../../AttendanceManager/helpers'


export default function NextPatientInfo({ activeScheduledAppointments, mapFromChoices }) {
    const [patientAge, setPatientAge] = useState(0)
    const [appointmentInfo, setAppointmentInfo] = useState({})
    const [appointmentsCursor, setAppointmentsCursor] = useState(0)
    const {isArrivalFilter} = useApp()
    
    const patient = appointmentInfo?.person
    const attendance = appointmentInfo
    const history = useHistory()

    useEffect(() => {
        if (activeScheduledAppointments.length > 0) {
            setAppointmentInfo(activeScheduledAppointments[appointmentsCursor])
        } else {
            setAppointmentInfo({})
        }
    }, [activeScheduledAppointments, appointmentsCursor])

    useEffect(() => {
        if (activeScheduledAppointments.length > 0) {
            setAppointmentsCursor(0)
        }
    }, [activeScheduledAppointments])

    const calculatePatientAge = () => {
        if (patient) {

            const birthday = patient.birthday
            const birthDate = new Date(birthday);
            const today = new Date();

            let age = today.getFullYear() - birthDate.getFullYear();

            const hasBirthdayPassedThisYear =
                today.getMonth() > birthDate.getMonth() ||
                (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

            if (!hasBirthdayPassedThisYear) {
                age -= 1;
            }
            setPatientAge(age);
        }
    }

    const patchAttendanceStatus = async (attendanceId) => {
        const payload = { "decision": "OPEN" }
        await patchAttendance(attendanceId, payload)
    }

    const openConsultation = async () => {
        await patchAttendanceStatus(attendance?.id)
        history.push(`/consultation/${attendance?.id}`)
    }

    const goToPreviousAppointment = () => {
        setAppointmentsCursor((prev) => Math.max(prev - 1, 0));
    };

    const goToNextAppointment = () => {
        setAppointmentsCursor((prev) =>
            Math.min(prev + 1, activeScheduledAppointments.length - 1)
        );
    };

    useEffect(() => {
        calculatePatientAge()
    }, [appointmentInfo])

    return (
        <div className='NextAppointment-Info-Chart'>
            <div className='NextAppointment-Info-Chart-Header'>
                <p className='NextAppointment-Info-Chart-Title'>Próximo atendimento:</p>
                <div className='NextAppointment-Actions'>
                    <div className='NextAppointmet-Cursor-Container'>
                        <div title='Atendimento Anterior' onClick={goToPreviousAppointment} style={{ cursor: 'pointer' }}>
                            <IcoArrowBack></IcoArrowBack>
                        </div>
                        <div title='Próximo Atendimento' onClick={goToNextAppointment} style={{ cursor: 'pointer' }} >
                            <IcoArrowForward ></IcoArrowForward>
                        </div>
                    </div>
                    <div className='NextAppointment-OpenAttendance' title='Abrir atendimento' onClick={openConsultation}>
                        <IcoEstetoscopio></IcoEstetoscopio>
                    </div>

                </div>
            </div>
            {appointmentInfo?.person ? (
                <div>
                    {(() => {
                        const decisionData = mapFromChoices(choices.DECISION_CHOICES, appointmentInfo.decision);
                        const decisionTime = getFormatedTimeFromDate(appointmentInfo?.decision_datetime)
                        const classificationData = mapFromChoices(choices.CLASSIFICATION_CHOICES, appointmentInfo.classification);
                        const resultData = mapFromChoices(choices.RESULT_CHOICES, appointmentInfo.result);
                        const priorityData = mapFromChoices(choices.PRIORITY_CHOICES, appointmentInfo.priority);

                        return (
                            <div className='NextAppointment-Sections-Container'>
                                <div className='NextAppointment-Status' style={{backgroundColor: decisionData?.secondColor, color: decisionData?.color}}>{decisionData?.name} - {decisionTime}</div>
                                <div className="NextAppointment-Section">
                                    <div className='NextAppointment-Section-Attendance-Container'>

                                        <p className="NextAppointment-Section-Title">Informações do atendimento:</p>
                                        <div className="NextAppointment-Section-Attendance">
                                            <p>
                                                <span>Classificação:</span> {classificationData?.name}
                                            </p>
                                            <div className="NextAppointment-Section-Attendance-Time">
                                                <p>
                                                    <span>Horário agendado:</span>{' '}
                                                    {attendance?.start &&
                                                        new Date(attendance.start).toLocaleTimeString([], {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                        })}
                                                </p>
                                                <p> - </p>
                                                <p>
                                                    {attendance?.end &&
                                                        new Date(attendance.end).toLocaleTimeString([], {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                        })}
                                                </p>
                                            </div>
                                            <p>
                                                <span>Modalidade:</span> {resultData?.name}
                                            </p>
                                            <p>
                                                <span>Prioridade:</span> {priorityData?.name}
                                            </p>
                                            <p>
                                                <span>Protocolo:</span> {attendance?.protocol}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="NextAppointment-Section">
                                    <div className="NextAppointment-Section-Patient">
                                        <PatientIcon style={{ width: '4rem', fill: 'var(--primary-color)' }} />
                                        <p className="NextAppointment-Section-Patient-Name">{patient?.name}</p>
                                        <div className="NextAppointment-Section-Patient-SubInfo">
                                            <p>{patientAge} Anos</p>
                                            <p>{patient?.sex === 'F' ? 'Feminino' : 'Masculino'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })()}
                </div>
            ) : (
                <div className='NoNextAppointments-Container'>
                    <PatientIcon style={{ width: '2.5rem', fill: '#aaa' }} ></PatientIcon>
                    <p className='NoNextAppointments-Message'>{isArrivalFilter? 'Nenhum paciente aguardando atendimento' : 'Nenhum atendimento agendado para hoje'}</p>
                </div>
            )}

        </div>
    )
}