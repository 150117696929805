import React, { useEffect, useState } from 'react'
import { List } from '../../newcomponents'
import Button, { ButtonModel } from '../../newcomponents/button/button'
import { IcoAdd, IcoBin, IcoPencil } from '../../component'
import Modal from '../../newcomponents/modal/modal'
import ProcedureForm from './procedure-form'
import { apiV2 } from '../../helpers'
import ToolTip from '../../component/tooltip/tooltip'
import useFinanceService from '../../services/finance'

export default function ProceduresList() {

    const [procedures, setProcedures] = useState([])
    const [modalState, setModalState]  = useState({open: false, procedure: null})

    const financeService = useFinanceService()

    useEffect(() => {
        fetchProcedureList()
    }, [])


    const fetchProcedureList = async() => {
        const procedures = await financeService.fetchProcedureList()
        setProcedures(procedures)
    }

    const handleProcedureDelete = async(procedure) => {
        const id = procedure.id
        delete procedure.id
        await apiV2.delete(`finance/procedures/${id}`) //TODO: handle errors
        fetchProcedureList()
    }
    
    const handleProceduresUpdate = () => {
        setModalState({open: false, procedure: null})
        fetchProcedureList()
    }

    const procedureAction = (procedure) => <div className='procedure-actions'>
        <div className='procedure-action' onClick={() => handleEditProcedureClick(procedure)}>
            <IcoPencil></IcoPencil>
        </div>
        <div className='procedure-action' onClick={() => handleProcedureDelete(procedure)}>
            <IcoBin></IcoBin>
        </div>
    </div>

    const getModalTitle = () => `${modalState.procedure ? 'Editar' : 'Adicionar'} procedimento`

    const handleAddProcedureClick = () => setModalState({open: true, procedure: null})
    const handleProcedureModalClose = () => setModalState({open: false, procedure: null})
    const handleEditProcedureClick = (procedure) => setModalState({open: true, procedure: procedure})

    return <div className='procedures-list'>

        <div className='subtitle'>Procedimentos</div>

        <List
            columns={[
                { header: "Código", size: 100 },
                { header: "Descrição" },
                { header: "Ações", size: 80 },
            ]}
            rows={procedures.map((procedure) => [
                procedure.code,
                procedure.description,
                procedureAction(procedure)
            ])}
        ></List>

        <div className='finance-add-button'>

            <ToolTip message={"Adicionar"}>
                <Button 
                    Icon={<IcoAdd></IcoAdd>}
                    model={ButtonModel.ROUNDICON}
                    onClick={handleAddProcedureClick}
                ></Button>
            </ToolTip>
        </div>

        <Modal
            open={modalState.open}
            title={getModalTitle()}
            content={<ProcedureForm 
                procedure={modalState.procedure}
                onClose={handleProcedureModalClose}
                onUpdate={handleProceduresUpdate}
            ></ProcedureForm>}
            dismissFn={handleProcedureModalClose}
        ></Modal>
    </div>
}