import React, { useEffect, useState } from "react";
import { Input } from "../../component";

import "./patient-selector.css"
import { getHealthPlacePatientsByNmeOrCpf } from "../../pages/pacientes/http";

export default function PatientSelector({open, onSelect, onClose}) {

    const [patientList, setPatientList] = useState([])
    const [filter, setFilter] = useState("")

    useEffect(() => {
        if (filter !== "") {
            fetchUsers()
        }
    }, [filter])

    document.onkeydown = evt => {
        if (evt.key === "Escape") {
            clearState()
            onClose()
        }
    }

    const fetchUsers = async() => {
        let response = await getHealthPlacePatientsByNmeOrCpf(filter)
        setPatientList(response.data.results)
    }

    const clearState = () => {
        setPatientList([])
        setFilter("")
    }

    const handleSelect = patient => {
        clearState()
        onSelect(patient)
    }

    const docIsCPF = patient => patient.document_type === 'CPF'
    const docLabel = patient => docIsCPF(patient) ? 'CPF' : 'RG'
    const docValue = patient => docIsCPF(patient) ? patient.cpf : patient.rg

    return <div className={`patient-selector ${open && 'open'}`}>

        <Input
            label='Digite Nome, CPF ou RG'
            value={filter}
            action={e => setFilter(e.target.value)}
        ></Input>

        {
            patientList.length > 0 && <div className="list"> 
                {
                    patientList.map(patient => {
                        return <div className="patient" onClick={() => handleSelect(patient)} key={patient.id}>
                            <div className="patient-name">
                                <b>Nome:</b> {patient.name}
                            </div>
                            <div className="patient-document">
                                <b>{docLabel(patient)}: </b> {docValue(patient)}
                            </div>
                        </div>
                    })
                }
            </div>
        }

    </div>
}