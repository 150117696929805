import React, { useState } from 'react'
import ConfigurationSection from './configuration'
import RevenuesSection from './revenues'
import ExpensesSection from './expenses'
import ComponentMenu from '../../newcomponents/component-menu/component-menu'

import './finance.css'

export default function FinancePage() {

    const revenues = <RevenuesSection></RevenuesSection>

    const [content, setContent] = useState(revenues)

    return <div className='finance-page'>
        <ComponentMenu
            items={[
                {
                    label: 'Receitas',
                    component: revenues
                },
                {
                    label: 'Despesas',
                    component: <ExpensesSection/>
                },
                {
                    label: 'Configurações',
                    component: <ConfigurationSection/>
                }
            ]}
            setComponent={setContent}
        ></ComponentMenu> 

        <div className='finance-content'>
            {content}
        </div>

    </div> 
}