import './TimeSchedule.css'
import React, { useEffect, useRef, useState } from 'react'
import { useBookingAppointmentCursor } from '../../BookingAppointmentCursor'
import RenderCurrentTimeLine from './subcomponents/RenderCurrentTimeLine/RenderCurrentTimeLine'
import RenderAppointments from './subcomponents/RenderAppointments/RenderAppointments'
import RenderAppointmentsCheckbox from './subcomponents/RenderAppointmentsCheckbox/RenderAppointmentsCheckbox'
import { useDispatch } from 'react-redux'
import GlobalCheckbox from '../../../../../../../../newcomponents/GlobalCheckbox/GlobalCheckbox'
import { IcoArrowBack, IcoArrowForward, Modal } from '../../../../../../../../component'
import ZoomInIco from '../../../../../../../../component/icon/zoom-in'
import ZoomOutIco from '../../../../../../../../component/icon/zoom-out'
import CreateSchedulesModal from '../CreateSchedulesModal/CreateSchedulesModal'

const HOUR_LABELS = [
  '01:00', '02:00', '03:00', '04:00', '05:00', '06:00',
  '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
  '13:00', '14:00', '15:00', '16:00', '17:00', '18:00',
  '19:00', '20:00', '21:00', '22:00', '23:00', '23:59'
]

const getDayOfWeek = (day) => {
  const daysOfWeek = {
    'dom.': 'DOM',
    'seg.': 'SEG',
    'ter.': 'TER',
    'qua.': 'QUA',
    'qui.': 'QUI',
    'sex.': 'SEX',
    'sáb.': 'SAB'
  };
  const dayAbbreviation = day.toLocaleDateString('pt-BR', { weekday: 'short' }).toLowerCase();
  return daysOfWeek[dayAbbreviation] || dayAbbreviation;
}


export default function TimeSchedule({ displayMode = 'daily', setCalendarCursor, setModalInfo }) {
  const dispatch = useDispatch()
  const timeScheduleGridRef = useRef(null)
  const {
    calendarCursor,
    selectedTimesCursor, setSelectedTimesCursor, filledTimesOfTheDay //checkAll 
  } = useBookingAppointmentCursor()

  // checkAll
  const isAllSelected = filledTimesOfTheDay.horarios.every((horario) =>
    selectedTimesCursor.some((selected) => selected.pk === horario.pk)
  );

  const isDisabled = filledTimesOfTheDay.horarios.length === 0;

  //Zoom Controll
  const [zoomLevel, setZoomLevel] = useState(80);

  const handleZoomIn = () => setZoomLevel((prev) => Math.min(prev + 20, 120));
  const handleZoomOut = () => setZoomLevel((prev) => Math.max(prev - 20, 50));

  useEffect(() => {
    if (timeScheduleGridRef.current) {
      const now = new Date();
      const startOfDay = new Date(now.setHours(0, 0, 0, 0));
      const diffInMilliseconds = new Date() - startOfDay;
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

      const hourBlockHeight = 72 * zoomLevel; // Altura dinâmica
      const scrollPosition = diffInHours * hourBlockHeight;

      timeScheduleGridRef.current.scrollTop = scrollPosition - (window.innerHeight / 2);
    }
  }, [zoomLevel]);

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedTimesCursor((prev) => {
        const newTimes = filledTimesOfTheDay.horarios.filter(
          (horario) => !prev.some((selected) => selected.pk === horario.pk)
        );
        return [...prev, ...newTimes];
      });
    } else {
      setSelectedTimesCursor((prev) => {
        return prev.filter(
          (selected) => !filledTimesOfTheDay.horarios.some((horario) => horario.pk === selected.pk)
        );
      });
    }
  };
  //checkAll - end


  useEffect(() => {
    const calculateScrollPosition = () => {
      const now = new Date();
      const startOfDay = new Date(now.setHours(0, 0, 0, 0));
      const diffInMilliseconds = new Date() - startOfDay;
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

      const hourBlockHeight = 72;
      return diffInHours * hourBlockHeight;
    };

    if (timeScheduleGridRef.current) {
      const scrollPosition = calculateScrollPosition();

      timeScheduleGridRef.current.scrollTop = scrollPosition - (window.innerHeight / 2);
    }
  }, []);

  const openCreateSchedulesModal = (num) => {     
          setModalInfo({
              open: true,
              title: 'Criar Horário(s)',
              content: <CreateSchedulesModal selectedDate={calendarCursor.selectedDay} selectedTime={num} />
          })
      };

  const isActiveDay = (day) => {
    const selected = calendarCursor.selectedDay;
    return day.getDate() === selected.getDate() &&
      day.getMonth() === selected.getMonth() &&
      day.getFullYear() === selected.getFullYear();
  }

  const getStartOfWeek = (date) => {
    const diff = date.getDate() - date.getDay();
    return new Date(date.setDate(diff));
  }

  const generateWeekDays = () => {
    let startDate = getStartOfWeek(new Date(calendarCursor.selectedDay));
    let weekDays = [];
    for (let i = 0; i < 7; i++) {
      let day = new Date(startDate);
      day.setDate(day.getDate() + i);
      weekDays.push(day);
    }
    return weekDays;
  }

  const weekDays = generateWeekDays();

  // controlls selectedDay changes on the timeSchedule chart
  const selectDayOfTheWeek = (newSelectedDay) => {
    setCalendarCursor((prevCursor) => {
      const newMonth = newSelectedDay.getMonth();
      const newYear = newSelectedDay.getFullYear();

      return {
        ...prevCursor,
        selectedDay: newSelectedDay,
        monthSearchCursor: newMonth,
        yearSearchCursor: newYear,
      };
    });
  };

  const selectPreviousWeek = () => {
    const currentSelectedDay = calendarCursor.selectedDay;

    const previousWeekDate = new Date(currentSelectedDay);
    previousWeekDate.setDate(previousWeekDate.getDate() - 7);

    selectDayOfTheWeek(previousWeekDate);
  };

  const selectNextWeek = () => {
    const currentSelectedDay = calendarCursor.selectedDay;

    const nextWeekDate = new Date(currentSelectedDay);
    nextWeekDate.setDate(nextWeekDate.getDate() + 7);

    selectDayOfTheWeek(nextWeekDate);
  };

  return (
    <div>
      <Modal />
      <div className="TimeSchedule-ZoomControls">
        <button style={{background: "transparent", border: "none"}} onClick={handleZoomOut} title='Zoom -'>
          <ZoomOutIco></ZoomOutIco>
        </button>
        <button style={{background: "transparent", border: "none"}} onClick={handleZoomIn} title='Zoom +'>
          <ZoomInIco></ZoomInIco>
        </button>
      </div>
      <div className='TimeSchedule-Body'>
        <div className='TimeSchedule-Body-Header'>
          <div className='TimeSchedule-Body-Header-Cursor'>
            <div className="TimeSchedule-Body-Header-Cursor-AllCheckBox">
              <GlobalCheckbox
                isAllSelected={isAllSelected}
                isDisabled={isDisabled}
                onToggle={handleSelectAll}
              />
            </div>
          </div>
          <div className='TimeSchedule-Body-Header-CalendarRow'>
            <div
              className='TimeSchedule-Body-Header-Arrow'
              onClick={() => {
                selectPreviousWeek()
              }}
            >
              <IcoArrowBack />
            </div>

            {weekDays.map((day, index) => (
              <div
                key={index}
                className={`TimeSchedule-Body-Header-CalendarRow-DayOfTheWeek ${isActiveDay(day) ? 'active' : ''}`}
                onClick={() => {
                  selectDayOfTheWeek(day);
                }}
              >
                <div className='TimeSchedule-Body-Header-CalendarRow-DayOfTheWeek-Inner'>
                  <div>{getDayOfWeek(day)}</div>
                  <div>{day.getDate()}</div>
                </div>
              </div>
            ))}

            <div
              className='TimeSchedule-Body-Header-Arrow'
              onClick={() => {
                selectNextWeek();
              }}
            >
              <IcoArrowForward />
            </div>
          </div>
        </div>
        <div className="TimeSchedule-Body-Content" ref={timeScheduleGridRef}>
          <div className='TimeSchedule-Body-CheckBoxGrid' style={{ position: 'relative', height: '100%', gridTemplateRows: `repeat(24, ${zoomLevel}px)` }}>
            {
              HOUR_LABELS.map((num, idx) => <div
                style={{ cursor: 'pointer' }}
                onClick={() => null}
                key={num}
                id={`timeschedule-body-hoursgrid-${num}`}
              >
              </div>)
            }
            {
              displayMode === 'daily' && (
                <RenderAppointmentsCheckbox />
              )
            }
          </div>
          <div className='TimeSchedule-Body-HourLabels' style={{ gridTemplateRows: `repeat(24, ${zoomLevel}px)` }}>
            {
              HOUR_LABELS.map(num => num != '23:59' ? <div>{num}</div> : <div></div>)
            }
          </div>
          <div className='TimeSchedule-Body-Spliter' style={{ gridTemplateRows: `repeat(24, ${zoomLevel}px)` }}>
            {
              HOUR_LABELS.map(() => <div></div>)
            }
          </div>
          <div className='TimeSchedule-Body-HoursGrid' style={{ position: 'relative', height: '100%', gridTemplateRows: `repeat(24, ${zoomLevel}px)` }}>
            {
              HOUR_LABELS.map(num => <div
                style={{ cursor: 'pointer' }}
                onClick={() => openCreateSchedulesModal(num)}
                key={num}
                id={`timeschedule-body-hoursgrid-${num}`}
              >

              </div>)
            }
            <RenderAppointments displayMode={displayMode} />
            <RenderCurrentTimeLine />
          </div>
        </div>
      </div>
    </div>
  )
}