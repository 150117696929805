import React from 'react';
import './TimeScheduleActions.css'
import IcoSchedule from '../../../../../../../../component/icon/schedule';
import IcoCalendarLoop from '../../../../../../../../component/icon/calendar_loop';
import PrinterIcon from '../../../../../../../../component/icon/newPrinter';
import CreateSchedulesModal from '../CreateSchedulesModal/CreateSchedulesModal';
import RecurringPatientModal from '../TimeSchedule/subcomponents/RecurringPatientModal/RecurringPatientModal';
import { useDispatch } from 'react-redux';
import { useBookingAppointmentCursor } from '../../BookingAppointmentCursor'
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { AddAlert, AlertType } from '../../../../../../../../component';
import { parsePrintReportParams } from '../../../../../../helpers';
import { stringifyCollaboratorOption } from '../../../../../../../../utils/stringifyCollaboratorOption';
import AllowPopUpRedictModal from '../../../../../AttendanceHistory/subcomponents/AllowPopUpRedictModal/AllowPopUpRedictModal';
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import { postQuickEmission } from '../../../../../../http';


const wsScheme = process.env.NODE_ENV === 'production' ? 'wss' : 'ws';
const wsHost = process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST
    ? process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST
    : window.location.hostname;

export default function TimeScheduleActions({ selectedDay }) {
    const dispatch = useDispatch();

    const {
        selectedHealthProfessional,
        calendarCursor,
        setModalInfo,
    } = useBookingAppointmentCursor()

    const openCreateSchedulesModal = () => {
        setModalInfo({
            open: true,
            title: 'Criar Horário(s)',
            content: <CreateSchedulesModal selectedDate={calendarCursor.selectedDay} />
        })
    };

    const openRecurringPatientModal = () => {
        setModalInfo({
            open: true,
            title: 'Paciente Recorrente',
            content: <RecurringPatientModal />,
        });


    };

    const handleEmission = async () => {
        setModalInfo(MODAL_INIT_PROPS)
        let params = parsePrintReportParams({
          "doctor__id__in": [stringifyCollaboratorOption(selectedHealthProfessional)],
          "classification__in": [{ id: "ROUTINE", name: "Primeira Consulta" }, { id: "RETURN", name: "Consulta de Retorno" }],
          "start__gte": calendarCursor.selectedDay,
          "start__lte": calendarCursor.selectedDay
        })
    
        dispatch(loading());
        try {
          let response = await postQuickEmission({ params })
          const taskId = response.data.task_id
          const client = new WebSocket(`${wsScheme}://${wsHost}/ws/reports/dowload_pdf/${taskId}/?authorization=Token ${localStorage.token}`)

          client.onopen = () => {
            console.log('PDF Status WebSocket Client Connected');
          }
    
          client.onmessage = (message) => {
            dispatch(loaded())
            const dataFromServer = JSON.parse(message.data);
            const newWindow = window.open(dataFromServer.download_url)
            if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
              console.log('Pop-up bloqueado.')
              setModalInfo(prev => ({
                ...prev,
                open: true,
                title: 'Pop-up e redirecionamento bloqueado',
                content: <AllowPopUpRedictModal onClick={handleEmission} download_url={dataFromServer.download_url} />
              }))
            } else {
              dispatch(AddAlert('Agenda', 'Agenda impressa com sucesso', AlertType.SUCCESS))
            }
            client.close()
          }
          dispatch(AddAlert('Agenda', 'Gerando PDF, aguarde um instante.', AlertType.INFO))
        } catch (err) {
          dispatch(AddAlert('Agenda', 'Não foi possível gerar a agenda', AlertType.ERROR))
        }
        dispatch(loaded())
    }

    return (
        <div className="TimeSchedule-Actions">
            <div
                title="Criar horário(s)"
                className="TimeSchedule-CreateHour-Btn"
                onClick={openCreateSchedulesModal}
            >
                <IcoSchedule />
            </div>
            <div
                title="Paciente recorrente"
                className="TimeSchedule-CreateHour-Btn"
                onClick={openRecurringPatientModal}
            >
                <IcoCalendarLoop />
            </div>
            <div
                title="Imprimir agenda"
                className="TimeSchedule-CreateHour-Btn"
                onClick={handleEmission}
            >
                <PrinterIcon />
            </div>
        </div>
    );
}
