import { apiV2 } from "../helpers"

export default function useFinanceService() {

    //TODO: implements error handling

    const fetchProcedureList = async() => {
        const result = await apiV2.get('/finance/procedures')
        return result.data
    }

    const fetchReceipts = async() => {
        const result = await apiV2.get('/finance/receipts')
        return result.data
    }

    const postReceipt = async(data) => {
        await apiV2.post('/finance/receipts', data)
    }

    const putReceipt = async(data) => {
        let id = data.id
        delete data.id
        await apiV2.put(`/finance/receipts/${id}`, data)
    }

    const deleteReceipt = async(data) => {
        let id = data.id
        delete data.id
        await apiV2.delete(`/finance/receipts/${id}`, data)
    }

    return {
        fetchProcedureList,
        fetchReceipts,
        postReceipt,
        putReceipt,
        deleteReceipt
    }
}