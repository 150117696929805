import './InventoryMaterials.css'
import React, { useContext, useState } from "react";
import { useParams } from 'react-router-dom';
import ListInventoryMaterials from './subcomponents/ListInventoryMaterials/ListInventoryMaterials';
import ConfigInventoryMaterials from './subcomponents/ConfigInventoryMaterials/ConfigInventoryMaterials';
import Modal, { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal';
import ListRequestMaterials from './subcomponents/ListRequestMaterials/ListRequestMaterials';
import ListEntryBatchMaterials from './subcomponents/ListEntryBatchMaterials/ListEntryBatchMaterials';
import { useApp } from '../../../../layout/App';

const InventoryMaterialsContext = React.createContext();

export function useInventoryMaterials() {
    return useContext(InventoryMaterialsContext);
}

export default function InventoryMaterials() {
    const { subtab } = useParams()
    const [currentSubTab, setCurrentSubTab] = useState(subtab || 'ListInventoryMaterials')
    const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
    const { currentHealthPlaceUser } = useApp()

    const canReadAndWrite = !!currentHealthPlaceUser?.health_place?.inventory?.can_read_and_write?.includes(currentHealthPlaceUser?.profile)

    const pages = {
        'ListInventoryMaterials': <ListInventoryMaterials />,
        'ListRequestMaterials': <ListRequestMaterials />,
        'ListEntryBatchMaterials' : <ListEntryBatchMaterials/>,
        'ConfigInventoryMaterials': <ConfigInventoryMaterials />
    }

    return <InventoryMaterialsContext.Provider
        value={{
            setModalInfo,
            canReadAndWrite,
        }}
    >
        <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
        <div className='InventoryMaterials'>
            <div className='InventoryMaterials-Header'>
                <div 
                    className={`InventoryMaterials-Header-Option ${currentSubTab === 'ListInventoryMaterials' ? 'active' : ''}`}
                    onClick={() => setCurrentSubTab('ListInventoryMaterials')}
                >
                    Listar
                </div>
                <div
                    className={`InventoryMaterials-Header-Option ${currentSubTab === 'ListEntryBatchMaterials' ? 'active' : ''}`}
                    onClick={() => setCurrentSubTab('ListEntryBatchMaterials')}
                >
                    Entradas
                </div>
                <div 
                    className={`InventoryMaterials-Header-Option ${currentSubTab === 'ListRequestMaterials' ? 'active' : ''}`}
                    onClick={() => setCurrentSubTab('ListRequestMaterials')}
                >
                    Requisições
                </div>
                <div 
                    className={`InventoryMaterials-Header-Option ${currentSubTab === 'ConfigInventoryMaterials' ? 'active' : ''}`}
                    onClick={() => setCurrentSubTab('ConfigInventoryMaterials')}
                >
                    Configuração
                </div>
            </div>
            <div className='InventoryMaterials-Content'>
                { pages[currentSubTab] || null }
            </div>
        </div>
    </InventoryMaterialsContext.Provider>
}