import './ListRequestMaterials.css'
import React, { useEffect, useState } from "react";
import { getInventoryMaterialsAssignment, getMaterialBatch, patchInventoryMaterialAssignment, patchMaterialBatch, postMaterialBatch } from "../../http";
import { AddAlert, AlertType, List, MaskDataHora, Paginate } from "../../../../../../component";
import CancelIcon from "../../../../../../component/icon/cancel";
import { useDispatch } from 'react-redux';
import FilterRequestParams from './FilterRequestParams/FilterRequestParams';
import { useInventoryMaterials } from '../../InventoryMaterials';
import Loading from '../../../../../../newcomponents/loading/loading';
import DeleteRequestMaterialModal from './DeleteRequestMaterialModal/DeleteRequestMaterialModal';
import RequestDetailsModal from './RequestDetailsModal/RequestDetailsModal';
import IcoBoxSearch from '../../../../../../component/icon/box-search';

const INIT_FILTER_PARAMS = { material__name__icontains: '', material__code__icontains: '', material__active: true, patient: null, health_place_user: null, offset: 0 }

export default function ListRequestMaterials() {
    const limit = 20
    const dispatch = useDispatch()
    const { setModalInfo, canReadAndWrite } = useInventoryMaterials()
    const [assignments, setAssignments] = useState([])
    const [filterParams, setFilterParams] = useState(INIT_FILTER_PARAMS)
    const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: limit, totalElements: 0 })
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetchAssignments({ ...filterParams, "offset": 0 })
    }, [filterParams])
    
    const fetchAssignments = async (params={}) => {
        setIsLoading(true)
        let materialAssignmentsResponse = await getInventoryMaterialsAssignment({ ...params, limit })
        
        setAssignments(materialAssignmentsResponse.data.results)
        setPagination({
            totalElements: materialAssignmentsResponse.data.count,
            totalPages: Math.ceil((materialAssignmentsResponse.data.count) / limit),
            pageNumber: params.offset / limit || 0,
            next: materialAssignmentsResponse.data.next,
            previous: materialAssignmentsResponse.data.previous
        })
        setIsLoading(false)
    }

    const handleFetchAssignments = async () => {
        await fetchAssignments({ ...filterParams, "offset": 0 })

    }
    
    const handleCancel = async (assignment) => {
        try {

                await patchInventoryMaterialAssignment(assignment?.id, { "active": false })

            await handleFetchAssignments()
            dispatch(AddAlert('Requisição de Material', 'Requisição cancelada com sucesso!', AlertType.SUCCESS))            
        } catch (err) {
            dispatch(AddAlert('Requisição de Material', 'Falha ao cancelar requisição.', AlertType.ERROR))            
            console.error('ListRequestMaterials ~ patchInventoryMaterialAssignment ~ ', err)
        }
    }
    
    const handleNextPage = offset => {
        fetchAssignments({ ...filterParams, "offset": offset * limit })
	}
    
    const openCancelRequestModal = (request) => {
        setModalInfo(prev => ({ ...prev, 
            open: true, 
            title: 'Cancelar requisição', 
            content: <DeleteRequestMaterialModal request={request} handleCancel={handleCancel} setModalInfo={setModalInfo}/> 
        }))
    }

    const openRequestDetailsModal = (cursor) => {
        setModalInfo(prev => ({ ...prev, 
            open: true, 
            title: 'Detalhes da requisição', 
            content: <RequestDetailsModal request={cursor} setModalInfo={setModalInfo}/> 
        }))
    }

    return <div className="ListRequestMaterials">

        <FilterRequestParams
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            fetchAssignments={handleFetchAssignments}
            INIT_FILTER_PARAMS={INIT_FILTER_PARAMS} 
        />
        {!isLoading ?
        <div>
            <div className='ListRequestMaterials-List-Container'>
                <List 
                    head={
                        canReadAndWrite
                        ? [
                            { colunm: 'h_material', text: 'Material' },
                            { colunm: 'h_code', text: 'Código' },
                            { colunm: 'h_time', text: 'Última atualização' },
                            { colunm: 'h_patient', text: 'Paciente' },
                            { colunm: 'h_health_place_user', text: 'Requisitante' },
                            { colunm: 'h_requested_amount', text: 'Quantidade' },
                            { colunm: 'h_active', text: 'Status da Requisição' },
                            { colunm: 'h_action', text: <div className='ListRequestMaterials-HeaderRow-Action'>Ações</div>, width: '72px' }
                        ]
                        : [
                            { colunm: 'h_material', text: 'Material' },
                            { colunm: 'h_code', text: 'Código' },
                            { colunm: 'h_time', text: 'Última atualização' },
                            { colunm: 'h_patient', text: 'Paciente' },
                            { colunm: 'h_health_place_user', text: 'Requisitante' },
                            { colunm: 'h_requested_amount', text: 'Quantidade' },
                            { colunm: 'h_active', text: 'Status da Requisição' },
                        ]}
                    data={assignments}
                    listCustom={custom => {
                        let cursor = custom
                        const time = cursor?.time ? MaskDataHora(cursor?.time, 'DD/MMM/AAAA', '-') : MaskDataHora(cursor?.registration_date, 'DD/MMM/AAAA', '-')
                        cursor['h_material'] = cursor?.material?.name || '-'
                        cursor['h_code'] = cursor?.material?.code || '-'
                        cursor['h_patient'] = cursor?.patient?.name || '-'
                        cursor['h_health_place_user'] = cursor?.health_place_user?.user?.person?.name || cursor?.responsible?.name || '-'
                        cursor['h_time'] = time?.data && time?.hora ? `${time.data} ${time.hora}` : '-'
                        cursor['h_requested_amount'] = cursor?.requested_amount || cursor?.batch_quantity || '-'
                        cursor['h_active'] = cursor?.active
                        ? <div className='ListRequestMaterials-Status-Subtitle ListRequestMaterials-Success'>Confirmada</div>
                            : <div className='ListRequestMaterials-Status-Subtitle ListRequestMaterials-Error'>Cancelada</div>
                        custom['h_action'] = (
                            <div className='ListRequestMaterials-ActionRow'>
                                {
                                    cursor?.active
                                    ? <div
                                        title='Cancelar requisição'
                                        onClick={() => openCancelRequestModal(cursor)}                            
                                    >
                                        <CancelIcon />
                                    </div>
                                    : null
                                }
                                <div title='Ver detalhes' onClick={() => openRequestDetailsModal(cursor)}>
                                    <IcoBoxSearch style={{width: '1.6rem', height: '1.6rem', fill: 'none'}} />
                                </div>
                            </div>
                        )
                        return custom
                    }}
                />
                <div>
                    <Paginate data={pagination} action={handleNextPage} />
                </div>
            </div>
        </div> : 
        <div className='ListRequestMaterials-Loading-Container'>
            <Loading/>
        </div>
        }
    </div>
}