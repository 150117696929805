import React, { useState } from "react";
import SelectPatient from "../../../component/SelectPatient/SelectPatient";
import InfoLine from "../../../component/info-line/info-line";
import { IcoSearch, Input } from "../../../component";
import PatientSelector from "../../../newcomponents/patient-selector/patient-selector";

export default function ReceiptPatient({patient, onSelect}) {

    const [selectPatient] = useState(!patient.id)
    const [selectOpen, setSelectOpen] = useState(false)

    const handleSelect = patient => {
        setSelectOpen(false)
        onSelect(patient)
    }

    if (!selectPatient) {
        return <InfoLine
            label='Nome do paciente'
            value={patient.name}
        ></InfoLine>
    }

    return <div className="receipt-select receipt-select-anchor">
        <div>
            <Input 
                label='Nome do Paciente'
                value={patient.name}
            ></Input>
            <PatientSelector
                open={selectOpen}
                onSelect={handleSelect}
                onClose={() => setSelectOpen(false)}
            ></PatientSelector>
        </div>
        <div className="search" onClick={() => setSelectOpen(true)}>
            <IcoSearch></IcoSearch>
        </div>
    </div>
}