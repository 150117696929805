import React from 'react'

export default function IcoBook({ style }) {
    return <svg style={style} viewBox="0 0 512 512" >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M1165 5105 c-202 -44 -359 -166 -450 -347 -70 -141 -66 9 -63 -2228
            l3 -2015 22 -65 c67 -196 222 -352 417 -418 l81 -27 1519 -3 c1683 -3 1578 -7
            1668 63 31 23 56 55 77 96 l31 62 0 2327 c0 2608 7 2377 -76 2469 -26 29 -65
            58 -96 72 l-53 24 -1510 2 c-1224 1 -1521 -1 -1570 -12z m1487 -882 c3 -620 4
            -660 22 -693 61 -116 186 -155 287 -92 57 36 84 71 198 255 51 81 94 147 96
            147 2 0 47 -69 99 -153 114 -182 152 -228 209 -256 52 -25 126 -27 173 -5 46
            22 100 85 113 131 7 25 11 257 11 676 l0 637 169 0 c141 0 172 -3 185 -16 14
            -14 16 -190 16 -1790 l0 -1774 -1495 0 c-1303 0 -1505 -2 -1570 -15 -80 -17
            -166 -52 -223 -90 -20 -14 -40 -25 -44 -25 -11 0 -10 3292 1 3367 26 174 166
            318 335 343 28 4 358 8 733 9 l682 1 3 -657z m958 92 c0 -311 -2 -565 -4 -565
            -2 0 -63 94 -135 209 -118 188 -135 211 -170 224 -47 19 -100 8 -132 -26 -11
            -12 -73 -105 -136 -207 -64 -102 -120 -189 -125 -195 -4 -5 -8 246 -8 558 l0
            567 355 0 355 0 0 -565z m610 -3295 c6 -12 9 -68 8 -137 l-3 -118 -1425 -5
            c-1132 -4 -1430 -8 -1447 -18 -49 -27 -67 -106 -38 -162 33 -64 -66 -60 1495
            -60 l1420 0 0 -120 c0 -107 -2 -122 -20 -140 -20 -20 -33 -20 -1462 -20 -805
            0 -1471 4 -1505 9 -361 53 -473 518 -175 723 104 72 -20 67 1644 67 1486 1
            1497 1 1508 -19z"/>
        </g>
    </svg>
}
