import './MevoPrescription.css'
import React, { useEffect } from 'react'
import Loading from '../../../../../../../../../../../../newcomponents/loading/loading'
import NewButton, { ButtonModel } from '../../../../../../../../../../../../newcomponents/button/button'
import { history } from '../../../../../../../../../../../../helpers'
import EditAddress from '../../../../../../../../../../../datailPatient/subcomponents/EditAddress/EditAddress'
import { useAttendance } from '../../../../../../../../../../consultation'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../../newcomponents/modal/modal'
import { isInvalidAddress, isInvalidContact } from '../../../../../../../../../../../../utils/validators'

export default function MevoPrescription({ mevoModalURL, setMevoModalURL, getMevoModalURL, setModalInfo=() => null }) {
    const { attendance, setMainOpenConsultationTab } = useAttendance()
    const isInvalidPatientAddress = isInvalidAddress(attendance?.person?.address);
    const isInvalidPatientContact = isInvalidContact(attendance?.person?.contact_phone);
    const isPatientWithoutCPF = attendance?.person?.document_type !== 'CPF';

    useEffect(() => {
        setMevoModalURL('')
        getMevoModalURL()
        // eslint-disable-next-line
    }, [])

    const handleEditExtraAction = () => {
        setMevoModalURL('reload')
    }

    if (isInvalidPatientAddress || isInvalidPatientContact || isPatientWithoutCPF) {
        return <div className='DocumentsEmission-MessageBox'>
            <span className='DocumentsEmission-Message-Title'>Para acessar a área da mevo, algumas atualizações cadastrais são necessárias:</span>
            <div>
                <p>Dados do paciente</p>
                <ul>
                    {isInvalidPatientAddress && (
                        <li><b>Endereço:</b> necessário para emissão de documentos.</li>
                    )}

                    {isInvalidPatientContact && (
                        <li><b>Contato:</b> necessário para emissão de documentos.</li>
                    )}

                    {isPatientWithoutCPF && (
                        <li><b>CPF:</b> obrigatório para gerar documentos assinados digitalmente.</li>
                    )}
                </ul>
            </div>
            <div className='DocumentsEmission-Message-ButtonBox'>
                <NewButton
                    label='Ver dados do paciente'
                    onClick={() => setMainOpenConsultationTab('patientdata')}
                    model={ButtonModel.SECONDARY}
                />
            </div>
        </div>
    }

    // eslint-disable-next-line
    const urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig  
    const isValidURL = urlRegex.test(mevoModalURL)

    if (isValidURL) return <div className='patientNewDocumentBox'>
        <iframe className='patientNewDocumentFrame' title='Mevo Modal' src={mevoModalURL}></iframe>
    </div>



    
    switch (mevoModalURL) {
        case '':
            return (
                <div className='patientNewDocumentBox'>
                    <Loading />
                </div>
            )
        case 'reload':
            return <div>
                <NewButton
                    label='Recarregar'
                    onClick={() => getMevoModalURL()}
                />
            </div>
        case 'health place contact not found':
            return <p>O estabelecimento não possui contato cadastrado, solicite o cadastro para o administrador!</p>
        case 'health place address not found':
            return <p>O estabelecimento não possui endereço cadastrado, solicite o cadastro para o administrador!</p>
        case 'doctor address not found':
            return <div>
                <p>Cadastre seu endereço para criar um documento!</p>
                <NewButton 
                    onClick={() => history.push('/configuration/address')}
                    label='Cadastrar'
                />
            </div>
        case 'Council is invalid':
            return <div>
                <p>Houve um erro ao consultar seu conselho profissional, cadastre o mesmo novamente.</p>
                <NewButton 
                    label='Cadastrar'
                    onClick={() => history.push('/configuration/professionalcouncil')}
                />
            </div>
        case 'doctor specialties not found':
            return <div>
                <p>Houve um erro ao consultar sua especialidade, cadastre seu conselho profissional novamente.</p>
                <NewButton 
                    label='Cadastrar'
                    onClick={() => history.push('/configuration/professionalcouncil')}
                />
            </div>
        case 'patient address not found':
            return <>
                <p>Precisamos saber o <b>endereço do paciente</b> para acessar documentos</p>
                <NewButton 
                    label='Cadastrar'
                    onClick={() => setModalInfo(prevData => ({ 
                        ...prevData, 
                        open: true, 
                        title: 'Endereço do Paciente',
                        content: <EditAddress setModalInfo={setModalInfo} initHoldingPatient={attendance?.person} fetchExtraAction={handleEditExtraAction} />,
                        confirmText: ''
                    }))}
                />
            </>
        case 'error calling mevo finish later request':
            return <p>Houve um erro ao solicitar o modal de Terminar mais tarde.</p>
        case 'error calling mevo request':
            return <p>Houve um erro ao solicitar o Receituário Mevo.</p>
        case 'cancel':
            return <p>Terminar mais tarde selecionado! Seu progresso estará salvo quando voltar!</p>
        case 'excluded':
            return <p>Documento(s) excluído(s) com sucesso!</p>
        default:
            return <p>Houve um erro ao acessar o Receituário Mevo.</p>
    }
}