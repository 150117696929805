import './RenderAppointments.css'
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useBookingAppointmentCursor } from "../../../../BookingAppointmentCursor";
import AvailableScheduleMenu from './subcomponents/AvailableScheduleMenu/AvailableScheduleMenu';
import FilledScheduleMenu from './subcomponents/FilledScheduleMenu/FilledScheduleMenu';
import UnavailableScheduleMenu from './subcomponents/UnavailableScheduleMenu/UnavailableScheduleMenu';
import { useApp } from '../../../../../../../../../../layout/App';
import UnavailableHealthPlaceMenu from './subcomponents/UnavailableHealthPlaceMenu/UnavailableHealthPlaceMenu';

const { DECISION_CHOICES } = require('../../../../../../../../../../component/form/select/options.json')

const getStyleOption = (appointment, currentHealthPlaceUser) => {
    const stylesOptions = {
        "disponivel": {
          "color": "#4a934a", 
          "secondColor": "#d4edda",
        },
        "ocupado": {
          "color": "var(--primary-color)",
          "secondColor": "var(--primary-color-light-2)",
        },
        'indisponivel': {
          "color": "#aaa",
          "secondColor": "#fbfbfb",
        }
    }

    let stylesOption = {}

    if (appointment?.health_place?.pk && appointment?.health_place?.pk != currentHealthPlaceUser?.health_place?.id) {
      stylesOption = stylesOptions['indisponivel']
    } else if (appointment?.attendance?.decision) {
      stylesOption = DECISION_CHOICES.find(({id}) => appointment?.attendance?.decision === id)
    } else if (appointment?.status) {
      stylesOption = stylesOptions[appointment?.status]
    }

    return {
      color: stylesOption?.color,
      fill: stylesOption?.color,
      borderLeft: `6px solid ${stylesOption?.color}`,
      backgroundColor: `${stylesOption?.secondColor}`,
    }
}

export default function RenderAppointments({displayMode='daily'}) {
    const dispatch = useDispatch()
    const { filledTimesOfTheDay, filledTimesOfTheWeek } = useBookingAppointmentCursor()
    const { currentHealthPlaceUser } = useApp()

    const calculateGridRow = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      return hours + minutes / 60; 
    };

    return <>
      {
        displayMode === 'daily'
        ? ( filledTimesOfTheDay?.horarios?.map((appointment) => {
            const startTime = appointment.fields.start.split('T')[1];
            const endTime = appointment.fields.end.split('T')[1];
            const startRow = calculateGridRow(startTime);
            const endRow = calculateGridRow(endTime);
            const durationInHours = endRow - startRow;
            
            const height = `${(durationInHours / 24) * 100}%`;
            const top = `${(startRow / 24) * 100}%`;
  
            const styleOption = getStyleOption(appointment, currentHealthPlaceUser)
  
            return (
              <div
                key={appointment.pk}
                className='RenderAppointments'
                style={{
                  top: top,
                  height: height,
                  ...styleOption
                }}
              >
                {
                  appointment?.health_place?.pk && appointment?.health_place?.pk != currentHealthPlaceUser?.health_place?.id
                  ? <UnavailableHealthPlaceMenu appointment={appointment} displayMode={displayMode} />
                  : appointment.status === 'indisponivel'
                  ? <UnavailableScheduleMenu appointment={appointment} displayMode={displayMode} />
                  : appointment.status === 'ocupado'
                  ? <FilledScheduleMenu appointment={appointment} displayMode={displayMode} color={styleOption.color}/>
                  : appointment.status === 'disponivel'
                  ? <AvailableScheduleMenu appointment={appointment} displayMode={displayMode} />
                  : null
                }
              </div>
            )
          })
        )
        : (
          Object.entries(filledTimesOfTheWeek)?.map(([day, appointments]) => {
          return appointments.map((appointment) =>  {
            const startTime = appointment.fields.start.split('T')[1];
            const endTime = appointment.fields.end.split('T')[1];
            const startDate = new Date(appointment.fields.start)
            const startRow = calculateGridRow(startTime);
            const endRow = calculateGridRow(endTime);
            const durationInHours = endRow - startRow;
            
            const height = `${(durationInHours / 24) * 100}%`;
            const width = `${Math.floor((1 / 7) * 100)}%`
            const top = `${(startRow / 24) * 100}%`;
            const left = `${startDate.getDay() / 7 * 100}%`
  
            const styleOption = getStyleOption(appointment, currentHealthPlaceUser)
  
            return (
              <div
                key={appointment.pk}
                className='RenderAppointments'
                style={{
                  top: top,
                  left: left,
                  height: height,
                  width: width,
                  ...styleOption
                }}
              >
                {
                  appointment?.health_place?.pk && appointment?.health_place?.pk != currentHealthPlaceUser?.health_place?.id
                  ? <UnavailableHealthPlaceMenu appointment={appointment} displayMode={displayMode}/>
                  : appointment.status === 'indisponivel'
                  ? <UnavailableScheduleMenu appointment={appointment} displayMode={displayMode}/>
                  : appointment.status === 'ocupado'
                  ? <FilledScheduleMenu appointment={appointment} displayMode={displayMode}/>
                  : appointment.status === 'disponivel'
                  ? <AvailableScheduleMenu appointment={appointment} displayMode={displayMode}/>
                  : null
                }
              </div>
            )
          })
          })
        )
      }
      
    </>
  }