import './RequestDetailsModal.css'
import React from "react";
import { useDispatch } from 'react-redux';
import NewButton, { ButtonModel } from '../../../../../../../newcomponents/button/button';
import { MODAL_INIT_PROPS } from '../../../../../../../newcomponents/modal/modal';

const itemReasonExitOptions = [
    { id: 'allocation_to_patient', name: 'Alocação para paciente' },
    { id: 'sale', name: 'Venda' },
    { id: 'return', name: 'Devolução' },
    { id: 'transfer', name: 'Transferência' },
    { id: 'expiry', name: 'Vencimento' },
    { id: 'drug_stability', name: 'Estabilidade da droga' },
    { id: 'waste_breakage', name: 'Quebra ou desperdício' },
    { id: 'External_loan', name: 'Empréstimo externo' },
];

export default function RequestDetailsModal({  request, setModalInfo }) {
    const reasonName = itemReasonExitOptions.find(option => option.id === request.reason)?.name || 'Motivo desconhecido';


    return (
        <div className='RequestDetailsModal' >
            <div className='RequestDetailsModal-Status-Container'>
                <span>Status da Requisição:</span>
                {   request.active
                    ? <div className='RequestDetailsModal-Status-Subtitle RequestDetailsModal-Success'>Confirmada</div>
                    : <div className='RequestDetailsModal-Status-Subtitle RequestDetailsModal-Error'>Cancelada</div>
                }
            </div>
            <div className='RequestDetailsModal-Content'>
                <div><span>Código:</span> {request.h_code}</div>
                <div><span>Material:</span> {request.h_material}</div>
                <div><span>Quantidade:</span> {request.h_requested_amount} unidades</div>
                <div><span>Requisitante:</span> {request.h_health_place_user}</div>
                <div><span>Motivo:</span> {reasonName}</div>
                {
                    request.reason == 'allocation_to_patient' &&
                    <div><span>Paciente:</span> {request.patient ? request.patient.name : 'Paciente não informado'}</div>
                }
                <div><span>NF Associada:</span> {request.invoice ? request.invoice : 'Nota fiscal não informada'}</div>
                <div><span>Última atualização:</span> {request.h_time}</div>
                <div>
                    <span className='RequestDetailsModal-Justification-Title'>Observações:</span>
                    <div className='RequestDetailsModal-Justification'>
                        {request.justification ? request.justification : 'Sem observações'}
                    </div>
                 </div>


            </div>
        </div>
    )
}