import React, { useState } from "react";
import InfoLine from "../../../component/info-line/info-line";
import { Select } from "../../../component";
import { AttendanceTypeOptions, CategoryOptions } from "./constants";

export default function ReceitpType({type, onSelect}) {

    const [selectType] = useState(!type)

    if (selectType) {
        return <Select
            label='Type'
            options={AttendanceTypeOptions}
            selected={type}
            action={e => onSelect(e.target.value.id)}
        ></Select>
    }

    return <InfoLine
        label='Tipo'
        value={ type }
    ></InfoLine>
}