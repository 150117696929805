import './FilledScheduleMenu.css'
import React, { useState } from "react";
import { useHistory } from 'react-router-dom'
import { IcoBin, IcoEstetoscopio, IcoCash, MaskDataHora } from '../../../../../../../../../../../../component';
import IcoQuickly from '../../../../../../../../../../../../component/icon/quickly';
import { useBookingAppointmentCursor } from '../../../../../../BookingAppointmentCursor';
import AssignScheduleToPatient from '../../../AssignScheduleToPatient/AssignScheduleToPatient';
import DeleteScheduleSlot from '../../../DeleteScheduleSlot/DeleteScheduleSlot';
import IcoBlockCalendar from '../../../../../../../../../../../../component/icon/calendar_block';
import { useApp } from '../../../../../../../../../../../../layout/App';
import { IcoEditCalendar } from '../../../../../../../../../../../../component/icon/editcalendar';
import MarkTimeAsUnavailable from '../../../MarkTimeAsUnavailable/MarkTimeAsUnavailable';
import MarkTimeAsAvailable from '../../../MarkTimeAsAvailable/MarkTimeAsAvailable';
import IcoCalendarUnblock from '../../../../../../../../../../../../component/icon/calendar_unblock';
import { patchAttendance } from '../../../../../../../../../../http';
import NoteCheckIco from '../../../../../../../../../../../../component/icon/note_check';
import { useDispatch } from 'react-redux';
import { AddAlert,AlertType } from '../../../../../../../../../../../../component';
import { getFormatedTimeFromDate } from '../../../../../../../../../../helpers';
import { DECISIONS_WITH_TIME } from '../../../../../../../../../../consts';
import ReceiptModal from '../../../../../../../../../../../finance/receipts/receipt-modal';
import { isProduction } from '../../../../../../../../../../../../helpers/feature-flags';
import { ExclamationOutlinedIco } from '../../../../../../../../../../../../component';
import AttendanceObservations from '../AttendanceObservations/AttendanceObservations';
import { IcoSearch } from '../../../../../../../../../../../../component';
import { SpeechBubbleIco } from '../../../../../../../../../../../../component';

const defaultOptions = require('../../../../../../../../../../../../component/form/select/options.json')


export default function FilledScheduleMenu({ appointment={}, displayMode='daily', color={} }) {
    const history = useHistory()
    const dispatch = useDispatch()
    const { isHealthProfessional,isAdminOrAssistant } = useApp()
    const { setModalInfo, setSelectedTimesCursor, selectedTimesCursor, updateSchedule } = useBookingAppointmentCursor()
    const [isOpen, setIsOpen] = useState(false);
    const [addReceiptOpen, setAddReceiptOpen] = useState(false)
    const startHourTime = MaskDataHora(appointment?.fields?.start || appointment?.start, 'DD/MMM/AAAA', '-');
    const endHourTime = MaskDataHora(appointment?.fields?.end || appointment?.end, 'DD/MMM/AAAA', '-');
    const patient = appointment?.user || appointment?.user?.[0] || {};
    const decision = appointment?.attendance?.decision
    
    const isChecked = !!selectedTimesCursor.find(({pk}) => pk === appointment.pk)
    const appointmentDuration = new Date(appointment.fields.end) - new Date(appointment.fields.start)
    const isSaturday = new Date(appointment.fields.start).getDay() === 6;

    const openAssignScheduleToPatient = () => {
      const start = appointment?.fields?.start?.slice(11,16) || appointment?.start?.slice(11,16) || '-'
      const end = appointment?.fields?.end?.slice(11,16) || appointment?.end?.slice(11,16) || '-'

      setModalInfo(prev => ({ 
          ...prev, 
          open: true,
          title: `Editar horário | ${startHourTime?.data} | ${start} - ${end}`,
          content: <AssignScheduleToPatient initSchedulingInfo={appointment} setModalInfo={setModalInfo} />
      }))
    }

    const openRemoveSchedule = () => {
      setModalInfo(prev => ({
          ...prev,
          open: true,
          title: 'Remover Horário(s)',
          content: <DeleteScheduleSlot appointments={[appointment]} />
      }))
    }

    const handleBlockSchedule = async () => {
      setModalInfo(prev => ({
        ...prev,
        open: true,
        title: 'Bloquear Horário(s)',
        content: <MarkTimeAsUnavailable appointments={[appointment]} />
      }))
    }
    
    const handleMoveToAvailable = async () => {
      setModalInfo(prev => ({
        ...prev,
        open: true,
        title: 'Disponibilizar Horário(s)',
        content: <MarkTimeAsAvailable appointments={[appointment]} />
      }))
    }

    const patchAttendanceStatus = async (attendanceId, payload) => {
      await patchAttendance(attendanceId, payload)
    }
    
    const openConsultation = async () => {
      if (isHealthProfessional) {
        const payload = {"decision": "OPEN"}
        await patchAttendanceStatus(appointment?.attendance?.id, payload)
        history.push(`/consultation/${appointment?.attendance?.id}`)
      }
    }

    const moveToWaitingAttendance = async () => {
      if (isAdminOrAssistant) {
        const payload = {"decision": "WAIT"}
        if (appointment?.attendance?.decision != 'WAIT') {
          await patchAttendanceStatus(appointment?.attendance?.id, payload)
          await updateSchedule()
          dispatch(AddAlert('Agenda', 'Paciente aguardando atendimento.', AlertType.SUCCESS))
        } else {
          dispatch(AddAlert('Agenda', 'Este paciente já está aguardando atendimento.', AlertType.INFO))

        }
      }
    }
      
    const handleAddReceipt = () => {
      setAddReceiptOpen(true)
    }

    const openAttendanceObservation = () => {

      const start = appointment?.fields?.start?.slice(11,16) || appointment?.start?.slice(11,16) || '-'
      const end = appointment?.fields?.end?.slice(11,16) || appointment?.end?.slice(11,16) || '-'

      setModalInfo(prev => ({ 
          ...prev, 
          open: true,
          title: `Agendamento | ${startHourTime?.data} | ${start} - ${end}`,
          content: <AttendanceObservations observations={attendanceObservation}/>
      }))
    }

    const attendanceResult = appointment?.attendance?.result
      ? ` | ${defaultOptions.RESULT_CHOICES.find(({ id }) => id === appointment.attendance.result)?.name || ''}`
      : null;
    const attendanceClassification = appointment?.attendance?.classification
      ? ` | ${defaultOptions.CLASSIFICATION_CHOICES.find(({ id }) => id === appointment.attendance.classification)?.name || ''}`
      : null;
    const attendanceDecision = appointment?.attendance?.decision
      ? `${defaultOptions.DECISION_CHOICES.find(({ id }) => id === appointment.attendance.decision)?.name || ''}`
      : null;
    const attendancePriority = appointment?.attendance?.priority
      ? ` | ${defaultOptions.PRIORITY_CHOICES.find(({ id }) => id === appointment.attendance.priority)?.name || ''}`
      : null
    const attendanceHealthInsurance = appointment?.attendance?.health_insurance?.name
      ? ` | ${appointment?.attendance?.health_insurance?.name}`
      : null
    const attendanceSpecialty = appointment?.attendance?.specialty?.name
      ? ` | ${appointment?.attendance?.specialty?.name}`
      : null

    const attendanceObservation = appointment?.attendance?.observation
      ? appointment?.attendance?.observation
      : null

    const observationIndicator = attendanceObservation ? <>| <div className='Observation-Indicator' title='Ver Observações' onClick={openAttendanceObservation}>
      <SpeechBubbleIco style={{fill: color, height: '1.4rem', width: '1.4rem'}}></SpeechBubbleIco>
    </div>
    </>
    : null

    let initDropdownOptions = [
        { 
          title: 'Abrir Atendimento', 
          Icon: <IcoEstetoscopio style={{ width: '14px', height: '14px' }} />,
          action: openConsultation,
        },
        { 
          title: 'Alterar para aguardando atendimento', 
          Icon: <NoteCheckIco width={'16px'} height={'16px'} />,
          action: moveToWaitingAttendance,
        },
        { 
          title: 'Editar Horário', 
          Icon: <IcoEditCalendar style={{ width: '16px', height: '16px' }} />,
          action: openAssignScheduleToPatient,
        },
        { 
          title: 'Disponibilizar Horário', 
          Icon: <IcoCalendarUnblock style={{ width: '14px', height: '14px' }} />,
          action: handleMoveToAvailable,
        },
        { 
          title: 'Excluir Horário',
          Icon: <IcoBin style={{ width: '14px', height: '14px' }} />,
          action: openRemoveSchedule
        },
        { 
          title: 'Bloquear Horário', 
          Icon: <IcoBlockCalendar style={{ width: '14px', height: '14px' }} />,
          action: handleBlockSchedule
        },
        // {
        //   title: 'Ver Observações',
        //   Icon: <ExclamationOutlinedIco style={{ width: '18px', height: '18px' }} />,
        //   action: openAttendanceObservation
        // }
    ]

    if (!isProduction()) { //TODO: remove feature flag to release
      initDropdownOptions.push(
        {
          title: 'Lançar pagamento',
          Icon: <IcoCash style={{ width: '15px', height: '15px' }}></IcoCash>,
          action: handleAddReceipt
        }
      )
    }

    let dropdownOptions = initDropdownOptions
    const unavailableToMoveToWaitingAttendance = ['OPEN', 'WAIT', 'CLOSE', 'CANCEL', 'CHECK_OUT', 'ABSENT', 'SCHEDULE_CANCELED', 'SCHEDULE_RESCHEDULED']
    const unavailableToOpenAttendance = [ 'CLOSE', 'CANCEL', 'CHECK_OUT', 'ABSENT', 'SCHEDULE_CANCELED', 'SCHEDULE_RESCHEDULED']
    
    if (!isHealthProfessional || unavailableToOpenAttendance.includes(decision)) {
      dropdownOptions = dropdownOptions.filter(({title}) => title != 'Abrir Atendimento')
    }
    if (!isAdminOrAssistant  || unavailableToMoveToWaitingAttendance.includes(decision)) {
      dropdownOptions = dropdownOptions.filter(({title}) => title != 'Alterar para aguardando atendimento')
    }
    if (!attendanceObservation) {
      dropdownOptions = dropdownOptions.filter(({title}) => title != 'Ver Observações')
    }

    let title = attendanceDecision
    if (DECISIONS_WITH_TIME.includes(appointment?.attendance?.decision)) {
          const attendanceDecisionTime = getFormatedTimeFromDate(appointment?.attendance?.decision_datetime) || ''
      title = `${attendanceDecision} às ${attendanceDecisionTime}`
    }

    return <div 
      className='FilledScheduleMenu'
      style={displayMode === 'weekly' ? (appointmentDuration < 1500000 ? {fontSize: '10px'} : {fontSize: '12px'}) : {fontSize: '14px'}}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
        <ReceiptModal
          open={addReceiptOpen}
          patient={patient}
          attendance={appointment.attendance}
          onClose={() => setAddReceiptOpen(false)}
        ></ReceiptModal>

        <div className='FilledScheduleMenu-Content'>
          <div className='FilledScheduleMenu-TitleDiv'>
            <span>
              <strong>{title}</strong>&nbsp;
              {displayMode==='daily' && appointmentDuration <= 1500000 && <span style={{ fontSize: '12px' }}>{`| ${startHourTime?.hora?.slice(0,5) || '-'} | ${endHourTime?.hora?.slice(0,5) || '-'}`}</span>}
              {displayMode==='weekly' ? '' : (<> | {patient.social_name || patient.name || 'Nome não cadastrado'}{attendanceClassification}{attendancePriority}{attendanceSpecialty}</>)}
            </span>
            {observationIndicator}
            {displayMode==='weekly' && (
              <input
              type='checkbox'
              className='FilledScheduleMenu-checkbox'
              checked={isChecked}
              onChange={
                () => {
                  isChecked 
                  ? setSelectedTimesCursor(prev => prev.filter(({pk}) => appointment.pk != pk))
                  : setSelectedTimesCursor(prev => ([ ...prev, appointment ]))}
                }
            />
            )}
          </div>
          {(displayMode==='weekly' && appointmentDuration > 2100000) || (displayMode==='daily' && appointmentDuration > 1500000) && (
            <div className='FilledScheduleMenu-HourTime'>
              <IcoQuickly style={displayMode === 'daily' ? { fontSize: '16px' } : { fontSize: '14px' }} /> 
              <span style={displayMode === 'daily' ? { fontSize: '12px' } : { fontSize: '10px' }}>{startHourTime?.hora?.slice(0,5) || '-'} | {endHourTime?.hora?.slice(0,5) || '-'}</span>
            </div>
          )}
        </div>
        <div className={`FilledScheduleMenu-Dropdown ${(displayMode === 'weekly' & isSaturday) ? 'FilledScheduleMenu-Dropdown-left' : ''} ${displayMode === 'weekly' ? 'FilledScheduleMenu-Dropdown-content-weekly' : ''}`}>
          {isOpen && (
            <div className="FilledScheduleMenu-Dropdown-content">
              <div className='FilledScheduleMenu-Dropdown-ActionBar'>
                {dropdownOptions.map((option, index) => (
                  <div
                    key={index}
                    title={option.title}
                    onClick={option.action} 
                    className="FilledScheduleMenu-Dropdown-item"
                  >
                    {option.Icon} {option.label}
                  </div>
                ))}
              </div>
              {displayMode === 'weekly' && (
                <div className='FilledScheduleMenu-DropDown-info'>
                  <p>{<div className='FilledScheduleMenu-HourTime'>
                        <IcoQuickly style={{fontSize: '16px'}} /> 
                        <span style={{fontSize: '12px'}}>{startHourTime?.hora?.slice(0,5) || '-'} | {endHourTime?.hora?.slice(0,5) || '-'}</span>
                      </div>}
                  </p>
                  <p>{patient.social_name || patient.name || 'Nome não cadastrado'}</p>
                  <p>{attendancePriority?.replace(/\|/g, '').trim() === 'Sem prioridade' ?  (`${attendancePriority?.replace(/\|/g, '')}`) : (`Prioridade ${attendancePriority?.replace(/\|/g, '')}`)}</p>
                  <p>{(attendanceSpecialty ? `${attendanceSpecialty?.replace(/\|/g, '')}` : 'Sem especialidade')}</p>
                </div>
              )}
            </div>
          )}
        </div>
    </div>
}