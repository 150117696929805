import './EditSchedule.css'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { AddAlert, AlertType, Input } from '../../../../../../../component'
import NewButton from '../../../../../../../newcomponents/button/button'
import NewSelect from '../../../../../../../newcomponents/form/select/select'
import { uniqueIDs } from '../../../../../../../utils/uniqueIDs'
import { getParams } from '../../../../../../../utils'
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../newcomponents/modal/modal'
import SelectPatient from '../../../../../../../component/SelectPatient/SelectPatient'
import { getSpecialties, postActionSchedule } from '../../../../../../agenda/SelectScheduleHealthPlaceUserManager/http'
import { useApp } from '../../../../../../../layout/App'
import { getAppointmentServiceLocation } from '../../../../../../configuration/subcomponents/HealthUnitConfig/HealthPlaceInstitutionalMenu/http'
import api from '../../../../../../../helpers/api'
import { patchAttendance } from '../../../../../../AttendanceManager/http'
import ConfirmAssign from '../../../../../../AttendanceManager/subcomponents/BookingAppointment/subcomponents/BookingAppointmentCursor/subcomponents/TimeSchedule/subcomponents/AssignScheduleToPatient/ConfirmAssign/ConfirmAssign'
import { handleSearchHealthInsurance, fetchInstitutionInsurances } from '../../../../../../../shared/health_insurances'

const { 
  DECISION_CHOICES, 
  RESULT_CHOICES, 
  CLASSIFICATION_CHOICES, 
  PRIORITY_CHOICES 
} = require('../../../../../../../component/form/select/options.json')

const strpSelectedScheduling = (selectedScheduling=null, servicelocation=null) => {
  return {
    patient: selectedScheduling?.attendance?.person || {},
    start: selectedScheduling?.fields?.start || selectedScheduling?.start || '',
    end: selectedScheduling?.fields?.end || selectedScheduling?.end || '',
    data: selectedScheduling?.data || '',
    attendance: {
      id: selectedScheduling?.attendance?.id || '',
      result: selectedScheduling?.attendance?.result || 'NORMAL',
      classification: selectedScheduling?.attendance?.classification || 'ROUTINE',
      priority: selectedScheduling?.attendance?.priority || 'NO_PRIORITY',
      decision: selectedScheduling?.attendance?.decision || 'SCHEDULE',
      specialty: selectedScheduling?.attendance?.specialty || '',
      health_insurance: selectedScheduling?.attendance?.health_insurance || '',
      service_location: servicelocation || selectedScheduling?.attendance?.service_location || '',
    }

  }
}

export default function EditSchedule({ initSchedulingInfo=null, setModalInfo=() => null, doctor=null, setFilledTimesOfTheDay=() => null }) {
    const dispatch = useDispatch()
    const searchTimeout = useRef(null)
    const [selectedScheduling, setSelectedScheduling] = useState(strpSelectedScheduling(initSchedulingInfo, doctor?.current_service_location))
    const [schedulingErr, setSchedulingErr] = useState({ "patient": false })
    const [healthInsurancesResult, setHealthInsurancesResult] = useState([])
    const [healthInsuranceAllOptions, setHealthInsuranceAllOptions] = useState([])
    const [specialtiesOptions, setSpecialtiesOptions] = useState([])
    const [serviceLocationOptions, setServiceLocationOptions] = useState([])
    
    const { currentHealthPlaceUser } = useApp()
    const [secondaryModalInfo, setSecondaryModalInfo] = useState(MODAL_INIT_PROPS)

    useEffect(() => {
        fetchServiceLocationsOptions()
        fetchSpecialtiesOptions({ offset: 0 })
      fetchInstitutionInsurances(currentHealthPlaceUser, setHealthInsuranceAllOptions, setHealthInsurancesResult)
    }, [])

    const handleSelectPatient = (selectedPatient) => {
        setSchedulingErr(prev => ({ ...prev, "patient": false }))
        setSelectedScheduling(prev => ({ ...prev, "patient": selectedPatient }))
    }

    const continueSubmission = async (patient) => {
      let response = await api.post(`patient/activate_patient/${patient.id}/`)

      if (response.status !== 200) {
        dispatch(AddAlert('Ativar Paciente', 'Houve um erro ao ativar o paciente', AlertType.ERROR))
        return
      } else {
        handleSubmit(true)
      }
    }

    const cancelSubmission = () => {
      setSecondaryModalInfo(MODAL_INIT_PROPS)
      setSelectedScheduling((prev => ({ ...prev, "patient": {} })))
    }

    const handleSubmit = async (ignoreInactivate=false) => {
      if (!selectedScheduling?.patient?.id) {
        setSchedulingErr(prev => ({ ...prev, "patient": true }))
        return
      }

      if (!ignoreInactivate) {
        if (!selectedScheduling?.patient?.is_active) {
          setSecondaryModalInfo({ 
            open: true,
            title: 'Paciente Inativado',
            content: <ConfirmAssign 
                patient={selectedScheduling?.patient} 
                cancelSubmission={cancelSubmission} 
                continueSubmission={continueSubmission}/>,
          })

          return
        }
      }
        if (selectedScheduling?.attendance?.id) {
        const payload = {
            "result": selectedScheduling?.attendance?.result,
            "classification": selectedScheduling?.attendance?.classification,
            "decision": selectedScheduling?.attendance?.decision,
            "priority": selectedScheduling?.attendance?.priority,
            "person": selectedScheduling.patient?.id,
            "specialty": selectedScheduling?.attendance?.specialty?.id || '',
            "health_insurance": selectedScheduling?.attendance?.health_insurance?.id || '',
            "service_location": selectedScheduling?.attendance?.service_location?.id || '',
        }

        
        await patchAttendance(selectedScheduling.attendance.id, payload)
            .then(() => {
                dispatch(AddAlert('Agendar consulta', 'Consulta editada com sucesso!', AlertType.SUCCESS, 5000))
                setModalInfo(MODAL_INIT_PROPS)
            })
            .catch(error => {
                console.error(error)
                dispatch(AddAlert('Agendar consulta', error.response.data.detail, AlertType.ERROR, 5000))
            })
        } else {

        const payload = {
            "result": selectedScheduling?.attendance?.result,
            "classification": selectedScheduling?.attendance?.classification,
            "decision": selectedScheduling?.attendance?.decision,
            "priority": selectedScheduling?.attendance?.priority,
            "doctor": doctor?.user?.person?.id,
            "health_place": currentHealthPlaceUser?.health_place?.id,
            "person": selectedScheduling.patient?.id,
            "start": selectedScheduling.start,
            "end": selectedScheduling.end,
            "specialty": selectedScheduling?.attendance?.specialty?.id,
            "health_insurance": selectedScheduling?.attendance?.health_insurance?.id,
            "service_location": selectedScheduling?.attendance?.service_location?.id,
        }

        await postActionSchedule(payload)
            .then(() => {
                dispatch(AddAlert('Agendar consulta', 'Consulta agenda com sucesso!', AlertType.SUCCESS, 5000))
                setFilledTimesOfTheDay(prev => ({
                  ...prev,
                  horarios: prev.horarios.filter(horario => horario.pk !== initSchedulingInfo.pk)
              }));
                setModalInfo(MODAL_INIT_PROPS)
            })
            .catch(error => {
                console.error(error)
                dispatch(AddAlert('Agendar consulta', error.response.data.detail, AlertType.ERROR, 5000))
            })
        }
      }

      const fetchServiceLocationsOptions = async (params={}) => {
        try {
            let res = await getAppointmentServiceLocation({...params, limit: 500 })
            setServiceLocationOptions(res.data.results)
        } catch (err) {
            setServiceLocationOptions([])
            console.error('fetchServiceLocationsOptions ~ ', err)
        }
      }

      const fetchSpecialtiesOptions = async (params={}) => {
          params = { ...params, limit: 50 }

          try {
              let res = await getSpecialties(params)

              setSpecialtiesOptions(res.data.results)
          } catch(err) {
              console.error('fetchSpecialtiesOptions ~ ', err)
          }
      }
    
      const handleSearchSpecialties = (event) => {
          if (searchTimeout.current) clearTimeout(searchTimeout.current)

          searchTimeout.current = setTimeout(() => {
              fetchSpecialtiesOptions({ name__icontains: event.target.value })
          }, 400, event.target.value)
      }

      const handleSearchServiceLocations = (event) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)

        searchTimeout.current = setTimeout(() => {
            fetchServiceLocationsOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
      }

    return (
    <div className='AssignScheduleToPatient'>
      <Modal {...secondaryModalInfo} dismissFn={() => setSecondaryModalInfo(MODAL_INIT_PROPS)}/>

      <div className='AssignScheduleToPatient-Grid'>
        <SelectPatient 
          selectedPatient={selectedScheduling.patient} 
          handleSelectPatient={handleSelectPatient}
          inputErr={schedulingErr?.patient}
        />
        <div className='AssignScheduleToPatient-Separator' />
        <div>
          <div className='AssignScheduleToPatient-2FRRow'>
            <div>
              <NewSelect 
                label='Categoria *'
                onSelect={event => setSelectedScheduling(prev => ({ 
                    ...prev, 
                    "attendance": { ...prev.attendance, "classification": event.target.selected }
                  }))
                }
                canBeEmpty={false}
                options={CLASSIFICATION_CHOICES}
                selected={selectedScheduling?.attendance?.classification || ''}
              />
            </div>
            <div>
              <NewSelect 
                label='Status *'
                onSelect={event => setSelectedScheduling(prev => ({ 
                    ...prev, 
                    "attendance": { ...prev.attendance, "decision": event.target.selected } 
                  }))
                }
                canBeEmpty={false}
                options={DECISION_CHOICES}
                selected={selectedScheduling?.attendance?.decision || ''}
              />
            </div>
          </div>
          <div className='AssignScheduleToPatient-2FRRow'>
            <div>
              <NewSelect 
                label='Tipo *'
                onSelect={event => setSelectedScheduling(prev => ({ 
                    ...prev, 
                    "attendance": { ...prev.attendance, "result": event.target.selected } 
                  }))
                }
                canBeEmpty={false}
                options={RESULT_CHOICES}
                selected={selectedScheduling?.attendance?.result || ''}
              />
            </div>
            <div>
              <NewSelect 
                label='Prioridade'
                onSelect={event => setSelectedScheduling(prev => ({ 
                    ...prev, 
                    "attendance": { ...prev.attendance, "priority": event.target.selected } 
                  }))
                }
                canBeEmpty={false}
                options={PRIORITY_CHOICES}
                selected={selectedScheduling?.attendance?.priority || ''}
              />
            </div>
          </div>
          <div>
            <NewSelect 
              label='Convênio'
              onSelect={event => {
                setSelectedScheduling(prev => ({ 
                  ...prev, 
                  "attendance": { 
                    ...prev.attendance, 
                    "health_insurance": prev?.attendance?.health_insurance?.id != event.target.selected 
                      ? healthInsurancesResult?.find(({ id }) => id === event.target.selected)
                      : ''
                  } 
                }))
                  fetchInstitutionInsurances(currentHealthPlaceUser, setHealthInsuranceAllOptions, setHealthInsurancesResult)
                }
              }
              canBeEmpty={false}
              options={uniqueIDs(selectedScheduling?.attendance?.health_insurance, healthInsurancesResult)}
              selected={selectedScheduling?.attendance?.health_insurance?.id || ''}
              filterNode={<div className='AssignScheduleToPatient-NewSelect'>
                <Input 
                    placeholder='Pesquisar por nome'
                    action={(event) => handleSearchHealthInsurance(event, healthInsuranceAllOptions, setHealthInsurancesResult)}
                    actionFocus={()=> fetchInstitutionInsurances(currentHealthPlaceUser, setHealthInsuranceAllOptions, setHealthInsurancesResult)}
                />
              </div>
              }
            />
          </div>
          <div>
            <NewSelect 
              label='Especialidade'
              onSelect={event => {
                  setSelectedScheduling(prev => ({ 
                    ...prev, 
                    "attendance": { 
                      ...prev.attendance, 
                      "specialty": prev?.attendance?.specialty?.id != event.target.selected 
                        ? specialtiesOptions?.find(({ id }) => id === event.target.selected)
                        : ''
                    } 
                  }))
                  fetchSpecialtiesOptions({ offset: 0 })
                } 
              }              
              canBeEmpty={false}
              options={uniqueIDs(selectedScheduling?.attendance?.specialty, specialtiesOptions)}
              optionRefKey='id'
              optionStrKey='strf'
              selected={selectedScheduling?.attendance?.specialty?.id || ''}
              filterNode={<div className='AssignScheduleToPatient-NewSelect'>
                <Input 
                    placeholder='Pesquisar por nome'
                    action={handleSearchSpecialties}
                    actionFocus={()=> fetchSpecialtiesOptions()}
                />
              </div>
              }
            />
          </div>
          <div>
            <NewSelect 
                label='Local de Atendimento'
                onSelect={event => {
                  setSelectedScheduling(prev => ({ 
                    ...prev, 
                    "attendance": { 
                      ...prev.attendance, 
                      "service_location": prev?.attendance?.service_location?.id != event.target.selected 
                        ? serviceLocationOptions?.find(({ id }) => id === event.target.selected)
                        : ''
                    } 
                  }))
                  fetchServiceLocationsOptions({ offset: 0 })
                }}     
                canBeEmpty={false}
                options={uniqueIDs(selectedScheduling?.attendance?.service_location, serviceLocationOptions)}
                optionRefKey='id'
                optionStrKey='name'
                selected={selectedScheduling?.attendance?.service_location?.id || ''}
                filterNode={<div className='NewAppointmentModal-NewSelect'>
                    <Input 
                        placeholder='Pesquisar por nome'
                        action={handleSearchServiceLocations}
                        actionFocus={()=> fetchServiceLocationsOptions()}
                    />
                </div>
                }
            />
            </div>
        </div>
      </div>
      <div className='AssignScheduleToPatient-BtnBox'>
        <NewButton
          label='Salvar'
          onClick={() => handleSubmit()}
        />
      </div>

    </div>
  )
}