import React from "react";
import ReceiptForm from "./receipt-form";
import Receipt from "./receipt";
import Modal from "../../../newcomponents/modal/modal";

export default function ReceiptModal({open, receipt, patient, attendance, onClose, onSaved}) {

    return <Modal
            open={open}
            title={'Lançar pagamento'}
            content={
                <ReceiptForm
                    receipt={receipt ? receipt : new Receipt(patient, attendance)}
                    onSaved={onSaved}
                    onClose={onClose}
                ></ReceiptForm>
            }
            dismissFn={onClose}
        ></Modal>
}