import React, { useEffect, useState } from "react";
import InfoLine from "../../../component/info-line/info-line";

import './receipt-form.css'
import '../finance.css' //FIXME: isolate finance add button to reuse

import { List } from "../../../newcomponents";
import { IcoAdd, Input, Select } from "../../../component";
import { PaymentOptions } from "./constants";
import Toggle from "../../../newcomponents/toggle/toggle";
import ToolTip from "../../../component/tooltip/tooltip";
import Button, { ButtonModel } from "../../../newcomponents/button/button";
import ReceiptItemModal from "./receipt-item-modal";
import { getBRLCurrency, removeCurrencyFormat } from "../../../helpers/numbers";
import { getDocumentFormat } from "../../../helpers/documents";
import useFinanceService from "../../../services/finance";
import ReceiptPatient from "./receipt-patient";
import ReceitpCategory from "./receipt-category";
import ReceitpType from "./receipt-type";
import ReceiptInsurance from "./receipt-insurance";

const MAX_DISCOUNT = 100
const MAX_DOCUMENT_CHARACTERS = 18
const MAX_PAYER_NAME_SIZE = 60
const CPF_SIZE = 11
const CNPJ_SIZE = 14

export default function ReceiptForm({receipt, onSaved, onClose}) {

    const [localReceipt, setReceipt] = useState({...receipt})
    const [openReceiptAdd, setOpenReceiptAdd] = useState(false)
    const [totalValue, setTotalValue] = useState(0)
    const [procedureToEdit, setProcedureToEdit] = useState(null)
    const [procedures, setProcedures] = useState([])

    const finance = useFinanceService()

    useEffect(() => {
        fetchProcedures()
    }, [])

    useEffect(() => {
        let total = 0
        localReceipt.procedures.forEach(procedure => {
            total += procedure.value
        });

        setTotalValue(total * (1-(localReceipt.discount/100)))

    }, [localReceipt, localReceipt.discount])

    useEffect(() => {
        if (needsToUpdateInstallments())
            handleInstallments()
    }, [totalValue, localReceipt.payOverTime, localReceipt.installmentsNumber, localReceipt.upfrontValue])

    useEffect(() => {

        if (procedureToEdit) {
            setOpenReceiptAdd(true)
        }

    }, [procedureToEdit])

    const needsToUpdateInstallments = () => {

        return receipt && (
            receipt.payOverTime !== localReceipt.payOverTime || 
            receipt.installmentsNumber !== localReceipt.installmentsNumber ||
            receipt.upfrontValue !== localReceipt.upfrontValue || 
            receipt.discount !== localReceipt.discount
        )
    }

    const fetchProcedures = async() => {
        let procedures = await finance.fetchProcedureList()
        setProcedures(procedures)
    }

    const handleProcedureAdd = (procedure, index) => {

        setOpenReceiptAdd(false)

        if (index !== undefined) {
            delete procedure.index
            localReceipt.procedures[index] = procedure
        } else {
            localReceipt.procedures.push(procedure)
        }

        setReceipt({...localReceipt})
    }

    const handleUpfrontPayment = e => {
        const value = removeCurrencyFormat(e.target.value)

        if (isNaN(value)) return

        setReceipt({...localReceipt, upfrontValue: Number(value)})
    }

    const handleDiscount = e => {
        let value = e.target.value

        if (isNaN(value) || value < 0 || value > MAX_DISCOUNT) {
            return
        }

        setReceipt({...localReceipt, discount: Number(value)})
    }

    const handleDocument = e => {
        const value = e.target.value

        if (value.length > MAX_DOCUMENT_CHARACTERS) {
            return
        }

        setReceipt({
            ...localReceipt,
            payer: {
                ...localReceipt.payer,
                document: value
            }
        })
    }

    const handlePayerName = e => {
        const value = e.target.value

        if (value.length > MAX_PAYER_NAME_SIZE) {
            return
        }

        setReceipt({
            ...localReceipt,
            payer: {
                ...localReceipt.payer,
                name: value
            }
        })
    }

    const getActions = (procedure, index) => {
        return <div onClick={() => {
            procedure.index = index
            setProcedureToEdit(procedure)
        }}>editar</div>
    }

    const handleReceiptItemClose = () => {
        setProcedureToEdit(null)
        setOpenReceiptAdd(false)
    }

    const handleDate = (e, field) => {
        const value = new Date(e.target.value)

        if (isNaN(value.getDay())) {
            return
        }

        localReceipt[field] = value
        setReceipt({...localReceipt})
    }

    const handleInstallmentDate = (e, index) => {
        const value = new Date(e.target.value)

        if (isNaN(value.getDay())) {
            return
        }

        localReceipt.installments[index].paidDate = value
        setReceipt({...localReceipt})
    }
    const isValid = () => {

        if (localReceipt.paidByAnother) {
            if (localReceipt.payer.name === '' || localReceipt.payer.document === '') { 
                return false
            }

            const docLength = localReceipt.payer.document.length

            if ( docLength !== CPF_SIZE && docLength !== CNPJ_SIZE) {
                return false
            }
        }

        return true
    }

    const handleInstallments = () => {

        let installments = [{
            value: localReceipt.payOverTime ? localReceipt.upfrontValue : totalValue,
            paid: false,
            paidDate: new Date()
        }]

        if (localReceipt.payOverTime) {
            for (let i = 0; i < localReceipt.installmentsNumber ; i++) {
                let today = new Date()
                installments.push({
                    value: Math.floor((totalValue - localReceipt.upfrontValue) / localReceipt.installmentsNumber),
                    paid: false,
                    paidDate: new Date(today.setMonth(today.getMonth() + i + 1))
                })
            }
        }

        setReceipt({ ...localReceipt, installments: installments })
    }

    const handleSave = async() => {

        let operation = receipt.id ? finance.putReceipt : finance.postReceipt
        await operation(localReceipt) //TODO: handle errors

        onSaved && onSaved()
        onClose && onClose()
    }

    return <div className="receipt-form-container">

            <form onSubmit={e => e.preventDefault()} className="receipt-form">

            <ReceiptItemModal
                procedureList={procedures}
                open={openReceiptAdd}
                onClose={handleReceiptItemClose}
                onAdd={handleProcedureAdd}
                receiptItem={procedureToEdit}
            ></ReceiptItemModal>

            < div className="receipt-grid">

                <ReceiptPatient
                    patient={localReceipt.patient}
                    onSelect={(patient) => setReceipt({...localReceipt, patient: patient})}
                ></ReceiptPatient>

                <ReceitpCategory
                    category={ localReceipt.category }
                    onSelect={ value => setReceipt({...localReceipt, category: value})}
                ></ReceitpCategory>

                <ReceitpType
                    type={ localReceipt.type }
                    onSelect={ value => setReceipt({...localReceipt, type: value}) }
                ></ReceitpType>

                <ReceiptInsurance
                    insurance={localReceipt.insurance}
                    onSelect={ (insurance) => setReceipt({...localReceipt, insurance: insurance}) }
                >
                </ReceiptInsurance>
            </div>

            <label className="subtitle">Lista de procedimentos</label>
            <List
                columns={[
                    {header: 'Descrição'},
                    {header: 'Quantidade', size: 200},
                    {header: 'Valor unitário', size: 270},
                    {header: 'Valor total', size: 230},
                    {header: 'Ações', size: 80}
                ]}
                rows={localReceipt.procedures.map((procedure, i) => {
                    return [
                        procedure.description,
                        procedure.quantity,
                        getBRLCurrency(procedure.unitCost),
                        getBRLCurrency(procedure.value),
                        getActions(procedure, i)
                    ]
                })}
            >
            </List>

            <div className="finance-add-button">
                <ToolTip message={"Adicionar"}>
                    <Button
                        Icon={<IcoAdd></IcoAdd>}
                        model={ButtonModel.ROUNDICON}
                        onClick={() => setOpenReceiptAdd(true)}
                    ></Button>
                </ToolTip>
            </div>

            <div className="receipt-grid payer">

                <Input
                    label='Desconto (%)'
                    value={localReceipt.discount}
                    type='number'
                    action={handleDiscount}
                ></Input>

                <InfoLine
                    label='Total'
                    value={getBRLCurrency(totalValue)} 
                ></InfoLine>
            </div>

            <Toggle label='Pago por outra pessoa' 
                value={localReceipt.paidByAnother}
                onChange={() => setReceipt({...localReceipt, paidByAnother: !localReceipt.paidByAnother})}
            ></Toggle>

            <div className="receipt-grid payer">
                {localReceipt.paidByAnother && <Input
                    label='Nome completo do pagador'
                    value={localReceipt.payer.name}
                    action={handlePayerName}
                ></Input>}

                {localReceipt.paidByAnother && <Input
                    label='CPF/CNPJ do pagador'
                    value={getDocumentFormat(localReceipt.payer.document)}
                    action={handleDocument}
                ></Input>}

                <Select
                    label='Forma de pagamento'
                    selected={localReceipt.paymentMethod}
                    options={PaymentOptions}
                    action={e => setReceipt({...localReceipt, paymentMethod: e.target.value})}
                >   
                </Select>

                <Input 
                    label='Data de vencimento'
                    type='date'
                    value={new Date(localReceipt.expireDate).toISOString().slice(0,10)}
                    action={e => handleDate(e, 'expireDate')}
                ></Input>

            </div>

            <Toggle
                label='Parcelar'
                value={localReceipt.payOverTime}
                onChange={e => setReceipt({...localReceipt, payOverTime: !localReceipt.payOverTime})}
            ></Toggle>

            <div className="receipt-grid">

                {localReceipt.payOverTime && <Input
                    label='Entrada (R$)'
                    value={getBRLCurrency(localReceipt.upfrontValue)}
                    action={handleUpfrontPayment}
                ></Input>}

                {localReceipt.payOverTime && <Input 
                    label='Quantidade de parcelas'
                    type='number'
                    value={localReceipt.installmentsNumber}
                    action={e => setReceipt( {...localReceipt, installmentsNumber: Number(e.target.value)} )}
                ></Input>}
            </div>

            <div className="subtitle">Pagamentos</div>

            <List
                columns={[
                    {header: 'Parcela'},
                    {header: 'Valor'},
                    {header: 'Recebido'},
                    {header: 'Data de recebimento'}
                ]}
                rows={localReceipt.installments.map( (installment, i) => [
                    i ? i : localReceipt.payOverTime ? 'Entrada' : 'Total',
                    getBRLCurrency(installment.value),
                    <div  className='installment-toggle'>
                        <Toggle
                            value={installment.paid}
                            onChange={() => {
                                localReceipt.installments[i].paid = !installment.paid
                                setReceipt({...localReceipt})
                            }}
                        ></Toggle>
                    </div>,
                    <div className="installment-date">
                        <Input 
                            type='date'
                            value={new Date(installment.paidDate).toISOString().slice(0,10)}
                            action={e => handleInstallmentDate(e, i)}
                        ></Input>
                    </div>
                ])}
            ></List>

            <div className="button-group">
                <Button 
                    label='Cancelar'
                    model={ButtonModel.PRIMARY_OUTLINED}
                    onClick={onClose}
                ></Button>
                <Button label='Salvar'
                    disabled={!isValid()}
                    onClick={handleSave}
                ></Button>
            </div>

        </form>
    </div>
}