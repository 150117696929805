export const PaymentOptions = [
    {
        id: 'CASH',
        name: 'Dinheiro'
    },
    {
        id: 'CREDIT_CARD',
        name: 'Cartão de crédito'
    },
    {
        id: 'DEBIT_CARD',
        name: 'Cartão de débito'
    },
    {
        id: 'PIX',
        name: 'PIX'
    },
    {
        id: 'PAYMENT_SLIP',
        name: 'Boleto'
    },
    {
        id: 'BANK_TRANSFER',
        name: 'Transferência bancária'
    }
]

export const CategoryLabel = {
    ROUTINE: 'Primeira consulta',
    RETURN: 'Consulta de Retorno', 
    EMERGENCY: 'Emergência'
}

export const CategoryOptions = [
    {
        id: 'ROUTINE',
        name: CategoryLabel.ROUTINE
    },
    {
        id: 'RETURN',
        name: CategoryLabel.RETURN
    },
    {
        id: 'EMERGENCY',
        name: CategoryLabel.EMERGENCY
    }
]

export const AttendanceType = {
    NORMAL: 'Normal',
    TELEMEDICINE: 'Telemedicine'
}

export const AttendanceTypeOptions = [
    {
        id: 'NORMAL',
        name: AttendanceType.NORMAL
    },
    {
        id: 'TELEMEDICINE',
        name: AttendanceType.TELEMEDICINE
    }
]