import './MaterialStockFilters.css'
import { useState,useRef, useEffect } from "react"
import { useApp } from '../../../../../../../../../layout/App';
import NewSelect from "../../../../../../../../../newcomponents/form/select/select"
import { Input } from "../../../../../../../../../component"
import { getInventoryMaterialManufacturers } from "../../../../ConfigInventoryMaterials/subcomponents/ManufacturersOptions/http"
import { getInventoryMaterialLocations } from "../../../../ConfigInventoryMaterials/subcomponents/LocationsOptions/http"

const orderingOptions = [
    { id: 'expiration_date', name: 'Validade Crescente' },
    { id:  'remaining', name: 'Quantidade Crescente' },
    { id: 'registration_date', name: 'Data de Entrada (A-Z)' },
    { id: '-registration_date', name: 'Data de Entrada (Z-A)' }
]

export default function MaterialStockFilters({fetchBatchFilters,setFetchBatchFilters}) {
    const searchTimeout = useRef(null)
    const currentHealthPlaceUser = useApp().currentHealthPlaceUser

    const [activeManufacturers, setActiveManufacturers] = useState([])
    const [activeLocations, setActiveLocations] = useState([])
    const responsability = [{name:'Minha responsabilidade', id: currentHealthPlaceUser.id}]
    const [manufacturerInputValue, setManufacturerInputValue] = useState('')
    const [locationInputValue, setLocationInputValue] = useState("");


    useEffect(() => {
        fetchActiveManufacturersOptions();
        fetchActiveLocationsOptions()
    },[])

    const fetchActiveManufacturersOptions = async (params={}) => {
            try {
                let res = await getInventoryMaterialManufacturers({ active: true, ...params })
                setActiveManufacturers(res.data.results)
            } catch (err) {
                setActiveManufacturers([])
                console.error('fetchActiveManufacturersOptions ~ ', err)
            }
        }

        
    const handleSearchManufacturer = (event) => {
        setManufacturerInputValue(event.target.value)
        if (searchTimeout.current) clearTimeout(searchTimeout.current)
            
            searchTimeout.current = setTimeout(() => {
                fetchActiveManufacturersOptions({ 'name__icontains': event.target.value })
            }, 400, event.target.value)
        }
            
            
    const handleSelectManufacturer = (event) => {
        const selectedManufacturer = event.target.selected
        console.log(selectedManufacturer);
        
        setFetchBatchFilters((prevFilters) => ({
            ...prevFilters,
            manufacturer__id: selectedManufacturer ? selectedManufacturer: "",
        }));
    };
    
    const fetchActiveLocationsOptions = async () => {
            try {
                let res = await getInventoryMaterialLocations({ active: true })
                setActiveLocations(res.data.results)
                console.log('localtions', res.data.results);
                
            } catch (err) {
                setActiveLocations([])
                console.error('fetchActiveLocationsOptions ~ ', err)
            }
    }
    const handleSearchLocation = (event) => {
        setLocationInputValue(event.target.value);
        if (searchTimeout.current) clearTimeout(searchTimeout.current);

        searchTimeout.current = setTimeout(() => {
            fetchActiveLocationsOptions({ "name__icontains": event.target.value });
        }, 400);
    };

    const handleSelectLocation = (event) => {
        const selectedLocation = event.target.selected;
        console.log(selectedLocation);

        setFetchBatchFilters((prevFilters) => ({
            ...prevFilters,
            location__id: selectedLocation ? selectedLocation : "",
        }));
    };

    // const handleSelectResponsible = (event) => {
    //     const selectedResponsible = event.target.selected;
    //     console.log(selectedResponsible);

    //     setFetchBatchFilters((prevFilters) => ({
    //         ...prevFilters,
    //         responsible__id: selectedResponsible ? selectedResponsible : '',
    //     }));
    // }

    const handleSelectOrdering = (event) => {
        const selectedOrdering = event.target.selected;
        console.log(selectedOrdering);
    
        setFetchBatchFilters((prevFilters) => ({
            ...prevFilters,
            ordering: selectedOrdering ? selectedOrdering : "",
        }));
    };
    

    return (
        <div className='MaterialStockFilters-Container'>
            <div className='MaterialStockFilters-Section'>
                <p className='MaterialStockFilters-Label'>Filtrar por fabricante:</p>
                <NewSelect
                    id='manufacturer'
                    onSelect={handleSelectManufacturer}
                    selected={fetchBatchFilters.manufacturer__id}
                    options={activeManufacturers}
                    optionStrKey='name'
                    filterNode={<div className='MaterialStockFilters-NewSelect'>
                        <Input
                            placeholder='Pesquisar por fabricante'
                            action={handleSearchManufacturer}
                            actionFocus={fetchActiveManufacturersOptions}
                            value={manufacturerInputValue}
                        />
                    </div>}
                />      
            </div>
            <div className='MaterialStockFilters-Section'>
                <p className='MaterialStockFilters-Label'>Filtrar por localização:</p>
                <NewSelect
                    id="location"
                    onSelect={handleSelectLocation}
                    selected={fetchBatchFilters.location__id}
                    options={activeLocations}
                    optionStrKey="name"
                    filterNode={
                        <div className="MaterialStockFilters-NewSelect">
                            <Input
                                placeholder="Pesquisar por localização"
                                action={handleSearchLocation}
                                actionFocus={fetchActiveLocationsOptions}
                                value={locationInputValue}
                            />
                        </div>
                    }
                />

            </div>
            <div className='MaterialStockFilters-Section'>
                <p className='MaterialStockFilters-Label'>Ordenar por:</p>
                <NewSelect
                    id="responsible"
                    onSelect={handleSelectOrdering}
                    selected={fetchBatchFilters.ordering}
                    options={orderingOptions}
                    optionStrKey="name"
                />

            </div>
        </div>
    )
}