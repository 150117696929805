import './GlassesPrescription.css'
import React, { useEffect, useState } from "react";
import { AddAlert, AlertType, Input, RadioButton } from '../../../../../../../../../../../../../../../../../component';
import NewButton from '../../../../../../../../../../../../../../../../../newcomponents/button/button';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../../../../../../../../../../newcomponents/modal/modal';
import { Glasses, validateGlasses } from './helpers';
import { useAttendance } from '../../../../../../../../../../../../../../../consultation';
import { handleAxis, handleCDegree, handleSignal, strpOphthalmology } from '../../../../../../../../../../OphthalmologyTemplate/subcomponents/helpers';
import { getOpthalmology } from '../../../../../../../../../../../../../../../http';
import { useDispatch } from 'react-redux';
import { IcoReviewDocument } from '../../../../../../../../../../../../../../../../../component/icon/reviewDocument';
import DetailOphthalmologyInfo from './subcomponents/DetailOphthalmologyInfo/DetailOphthalmologyInfo';
import { useApp } from '../../../../../../../../../../../../../../../../../layout/App';

const glassesTypes = [
    { id: 'FAR', name: 'Longe' },
    { id: 'CLOSE', name: 'Perto' },
    { id: 'MULTIFOCAL', name: 'Multifocal'},
    { id: 'BIFOCAL', name: 'Bifocal' }
]

const strpHoldingGlassesDocument = (document) => {
    return {
        "id": document?.id || "",
        "category": document?.category || "",
        "title": document?.title || "",
        "glasses": new Glasses(document?.glasses)
    }
}

export default function GlassesPrescription({ saveDocument, initHoldingGlassesDocument=null }) {
    const dispatch = useDispatch()
    const { currentHealthPlaceUser } = useApp()
    const { attendance, setModalInfo } = useAttendance()
    const [modalInfoV2, setModalInfoV2] = useState(MODAL_INIT_PROPS)
    const [holdingOphthalmology, setHoldingOphthalmology] = useState(strpOphthalmology())
    const [holdingGlassesDocument, setHoldingGlassesDocument] = useState(strpHoldingGlassesDocument(initHoldingGlassesDocument))
    const [glassesErrors, setGlassesErrors] = useState({})

    useEffect(() => {
        fetchOphthalmology()
    }, [])

    const fetchOphthalmology = async (params={}) => {
        params = { ...params, "appointment__id": attendance?.id }

        try {
          let res = await getOpthalmology(params)
          setHoldingOphthalmology(strpOphthalmology(res?.data?.results?.[0]))
        } catch (err) {
          dispatch(AddAlert('Oftalmologia', 'Falha ao obter oftalmologia', AlertType.ERROR))
          console.error('Oftalmologia ~ fetchOphthalmology ~ ', err)
        }
    }

    const handleChangeGlasses = (event) => {
        setHoldingGlassesDocument(prev => ({ ...prev, glasses: { ...prev.glasses, [event.target.name]: event.target.value } }))
        setGlassesErrors({})
    }

    const saveGlassesPrescription = async () => {
        const errors = validateGlasses(holdingGlassesDocument.glasses)
        setGlassesErrors(errors)
        if (Object.keys(errors).length > 0) return
        let payload = { glasses: holdingGlassesDocument.glasses }
        
        await saveDocument(payload)
        setModalInfo(MODAL_INIT_PROPS)
    }
    
    return <div className='GlassesPrescription'>
        <Modal {...modalInfoV2} dismissFn={() => setModalInfoV2(MODAL_INIT_PROPS)} />
        <div>
            <RadioButton
                label='Tipo de óculos' 
                name='tipo'
                action={event => setHoldingGlassesDocument(prev => ({ ...prev, glasses: { ...prev.glasses, type: event.target.value } }))}
                checked={{ 'id': holdingGlassesDocument.glasses.type.id }}
                options={glassesTypes}
            />
            <div className='GlassesPrescription-ActionRow'>
                <div
                    onClick={() => setModalInfoV2(prev => ({ 
                        ...prev, 
                        open: true, 
                        title: "Consulta de Oftalmologia", 
                        content: <DetailOphthalmologyInfo 
                            holdingOphthalmology={holdingOphthalmology} 
                        /> 
                    }))}
                    title="Revisar Refração"
                >
                    <IcoReviewDocument />
                </div>
            </div>
            <div className='GlassesPrescription-Row'>
                <div className='glasses-container-2'>
                    <div className='glasses-space'><b>HD</b></div>
                    <div className='glasses-degreeE'><b>Grau E</b></div>
                    <div className='glasses-degreeC'><b>Grau C</b></div>
                    <div className='glasses-axis'><b>Eixo</b></div>
                    <div className='glasses-DNP'><b>DNP</b></div>
                    <div className='glasses-rEyeFlag'><p>OD</p></div>
                    <div className='glasses-rEDegreeInput'>
                        <Input
                            name='rightEyeEDegree'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={handleSignal(holdingGlassesDocument.glasses.rightEyeEDegree)}
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                    <div className='glasses-rCDegreeInput'>
                        <Input
                            name='rightEyeCDegree'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={handleCDegree(holdingGlassesDocument.glasses.rightEyeCDegree)}
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                    <div className='glasses-rAxisInput'>
                        <Input
                            name='rightEyeAxis'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={handleAxis(holdingGlassesDocument.glasses.rightEyeAxis)}
                            type='Number'
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                    <div className='glasses-rDNPInput'>
                        <Input
                            name='rightEyeDNP'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={holdingGlassesDocument.glasses.rightEyeDNP}
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>

                    <div className='glasses-lEyeFlag'><p>OE</p></div>
                    <div className='glasses-lDegreeEInput'>
                        <Input
                            name='leftEyeEDegree'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={handleSignal(holdingGlassesDocument.glasses.leftEyeEDegree)}
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                    <div className='glasses-lDegreeCInput'>
                        <Input
                            name='leftEyeCDegree'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={handleCDegree(holdingGlassesDocument.glasses.leftEyeCDegree)}
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                    <div className='glasses-lAxisInput'>
                        <Input
                            name='leftEyeAxis'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={handleAxis(holdingGlassesDocument.glasses.leftEyeAxis)}
                            type='Number'
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                    <div className='glasses-lDNPInput'>
                        <Input
                            name='leftEyeDNP'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={holdingGlassesDocument.glasses.leftEyeDNP}
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                </div>
                <div>
                    <b>Adição</b>
                    <div className='glasses-addition'>
                        <Input
                            name='addition'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={handleSignal(holdingGlassesDocument.glasses.addition)}
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                </div>
            </div>
        </div>
        <p className='GlassesPrescription-Paragraph'>{`${currentHealthPlaceUser?.user?.person?.sex === 'M'? 'Dr.' : 'Dra.'} ${currentHealthPlaceUser?.user?.person?.name}`}</p>
        <div className='GlassesPrescription-BtnBox'>
            <NewButton label='Salvar' onClick={saveGlassesPrescription} />
        </div>
    </div>
}