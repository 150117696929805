import React, { useState } from "react";
import InfoLine from "../../../component/info-line/info-line";
import { Select } from "../../../component";
import { CategoryOptions } from "./constants";

export default function ReceitpCategory({category, onSelect}) {

    const [selectCategory] = useState(!category)

    if (selectCategory) {
        return <Select
            label='Categoria'
            options={CategoryOptions}
            selected={category}
            action={e => onSelect(e.target.value.id)}
        ></Select>
    }

    return <InfoLine
        label='Categoria'
        value={ category }
    ></InfoLine>
}