import './WaitingRoom.css'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { IcoEstetoscopio, Paginate } from '../../../../component/index.js'
import Loading from '../../../../newcomponents/loading/loading.js'
import Modal, { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal.js'
import { formatDateWithTimezoneFromDateObj } from '../../../../utils/convertDate.js'
import WaitingRoomAttendancesFilters from './subcomponents/WaitingRoomAttendancesFilters/WaitingRoomAttendancesFilters.js'
import { getAttendancesHistory } from '../../http.js'
import { useApp } from '../../../../layout/App.js'
import WaitingRoomUnit from './subcomponents/WaitingRoomUnit/WaitingRoomUnit.js'
import { parseAppointmentsURLQuerysetParams } from '../../helpers.js'


const WaitingRoomContext = React.createContext()

export function useWaitingRoom() {
	return useContext(WaitingRoomContext)
}


export default function WaitingRoom() {

	const [loading, setLoading] = useState(true)
	const [attendances, setAttendances] = useState([])
	const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })
	const [modalProps, setModalProps] = useState(MODAL_INIT_PROPS)
	const { currentHealthPlaceUser, isHealthProfessionalOrIsNurse, isArrivalFilter  } = useApp()

	const INIT_WAITING_ROOM_APPOINTMENT_FILTERS = {
		"name_or_cpf": "",
		"doctor__id__in": isHealthProfessionalOrIsNurse ? [
			{ 	id: currentHealthPlaceUser?.user?.person?.id,
				str: currentHealthPlaceUser?.user?.person?.str }
		] : [],
		"specialty__id__in": [],
		"health_insurance__id__in": [],
		"classification__in": [],
		"decision__in": isHealthProfessionalOrIsNurse? [
			{ id: "OPEN", name: "Aberto atendimento"},
			{ id: "WAIT", name: "Aguardando atendimento"},
		] : [
			{ id: "OPEN", name: "Aberto atendimento"},
			{ id: "WAIT", name: "Aguardando atendimento"},
			{ id: "SCHEDULE", name: "Agendado atendimento"},
			{ id: "GO_PLACE", name: "Check-in solicitado"},
			{ id: "CHECK_IN", name: "Check-in confirmado"},
			{ id: "SCHEDULE_CONFIRMED", name: "Confirmado agendamento"},
		],
		"priority__in": [],
		"result__in": [],
		"person__birthday__gte": "",
		"person__birthday__lte": "",
		"person__address__city__in": [],
		"service_location__id__in": [],
		"related_diseases_code": [],
		"ordering": "priority_order,start",
	}
	const [filters, setFilters] = useState(INIT_WAITING_ROOM_APPOINTMENT_FILTERS)

	const fetchAttendancesHistory = async params => {
		const builtParams = parseAppointmentsURLQuerysetParams(filters)
		params = { 
			offset: 0,
			...builtParams, 
			...params, 
			limit: 18,
			has_priority: true,
			health_place__id: currentHealthPlaceUser?.health_place?.id,
			start__gte: formatDateWithTimezoneFromDateObj(new Date(), '00', '00'),
			start__lte: formatDateWithTimezoneFromDateObj(new Date(), '23', '59'),
		}

		setLoading(true)
		try {
			let response = await getAttendancesHistory(params)
			setAttendances(response.data.results)
			setPagination({
				totalElements: response.data.count,
				totalPages: Math.ceil(response.data.count / 18),
				pageNumber: params.offset / 18,
			})
			setLoading(false)
		} catch (err) {
			setLoading(false)
			console.error('fetchAttendancesHistory ~ ', err)
		}
	}

	const handleNextPage = e => {
		fetchAttendancesHistory({ offset: e * 18, ...parseAppointmentsURLQuerysetParams(filters) })
	}

	return (
		<WaitingRoomContext.Provider
			value={{
				attendances,
				pagination,
			}}>
			<Modal {...modalProps} dismissFn={() => setModalProps(MODAL_INIT_PROPS)} />
			<div className='WaitingRoom-Content'>
				<WaitingRoomAttendancesFilters
					filters={filters}
					setFilters={setFilters}
					fetchAttendancesHistory={fetchAttendancesHistory}
					setModalProps={setModalProps}
					INIT_WAITING_ROOM_APPOINTMENT_FILTERS={INIT_WAITING_ROOM_APPOINTMENT_FILTERS}
				/>
				<div className='WaitingRoom-Separator'/>
				<div className='WaitingRoom-Body'>
					{
						loading 
						? <div className='WaitingRoom-Loading'><Loading/></div>
						: <div>
							{
								attendances?.length > 0
								? <div className='WaitingRoom-Attendance-Content'>
									<div className='WaitingRoom-AttendanceUnitWrapper'>
										{attendances.map(attendance =><WaitingRoomUnit
											filters={filters}
											attendance={attendance}
											setModalProps={setModalProps}
											fetchAttendancesHistory={fetchAttendancesHistory}
										/>)}
									</div>
										<Paginate data={pagination} action={handleNextPage} />
								</div>
								: <div className='WaitingRoom-NotFound'>
									<div>
										<p><b>Nenhum(a) paciente aguardando atendimento</b></p>
									</div>
								</div>
							}
						</div> 
					}
				</div>
			</div>
		</WaitingRoomContext.Provider>
	)
}
