import './LogsRecords.css'
import React, { useEffect, useState } from "react";
import { adicionaZero, Input, List, MaskDataHora } from "../../../../../../../../component";
import { formatDateWithTimezoneFromDateObj, formatISO8601Str } from '../../../../../../../../utils/convertDate';
import NewSelect from '../../../../../../../../newcomponents/form/select/select';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import CalendarDateModal from '../../../../../../../../component/calendar/CalendarDateModal';
import api from '../../../../../../../../helpers/api';
import { useAttendance } from '../../../../../../consultation';
import { getAppointmentFieldsCombinedHistory } from '../../../../../../http';
import { ACTIVE_FIELD_OPTIONS } from '../../../TemplateTypeSection/subcomponents/NewEditHoldingTemplate/NewEditHoldingTemplate';

const headDefault = [
    { colunm: 'h_changed_field', text: 'Campo Alterado', width: '300px' },
    { colunm: 'h_content', text: 'Conteúdo' },
    { colunm: 'h_date', text: 'Data e Hora', width: '180px' },
    { colunm: 'h_history_user', text: 'Dono da ação' },
]

const INTERVAL_DIFFERENCES_CHOICES = [
    { "id": "PAST_30_DAYS", "name": "Últimos 30 dias" },
    { "id": "PAST_60_DAYS", "name": "Últimos 60 dias" },
    { "id": "PAST_90_DAYS", "name": "Últimos 90 dias" },
    { "id": "PAST_120_DAYS", "name": "Últimos 120 dias" },
    { "id": "PAST_180_DAYS", "name": "Últimos 180 dias" },
    { "id": "PAST_365_DAYS", "name": "Últimos 365 dias" },
    { "id": "CUSTOM", "name": "Intervalo customizado" },
]

function getStartDate(interval) {
    const today = new Date();
    let daysToSubtract = 0;
  
    switch (interval) {
      case 'PAST_30_DAYS':
        daysToSubtract = 30;
        break;
      case 'PAST_60_DAYS':
        daysToSubtract = 60;
        break;
      case 'PAST_90_DAYS':
        daysToSubtract = 90;
        break;
      case 'PAST_120_DAYS':
        daysToSubtract = 120;
        break;
      case 'PAST_180_DAYS':
        daysToSubtract = 180;
        break;
      case 'PAST_365_DAYS':
        daysToSubtract = 365;
        break;
      case 'CUSTOM':
        return today;
      default:
        return today;
    }
  
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - daysToSubtract);
    return startDate;
}

export default function LogsRecords({ openSection, setOpenSection=() => null }) {
    const { attendance } = useAttendance()
    const [appointmentFieldCombinedHistory, setAppointmentFieldCombinedHistory] = useState([])
    const [filterParams, setFilterParams] = useState({ 'date_interval': 'PAST_90_DAYS', 'start_period_date': getStartDate('PAST_90_DAYS'), 'end_period_date': new Date() })
    const [secondaryModal, setSecondaryModal] = useState(MODAL_INIT_PROPS)

    useEffect(() => {
      if (!attendance?.id) return; 
      fetchAppointmentFieldsCombinedHistory();
    }, [attendance?.id, filterParams]);

    const fetchAppointmentFieldsCombinedHistory = async () => {
      try {
        // Convert start/end into a suitable string (e.g. YYYY-MM-DD)
        // or a full datetime, depending on how your backend wants it
        const minDateStr = formatDateWithTimezoneFromDateObj(filterParams.start_period_date, '00', '00');
        const maxDateStr = formatDateWithTimezoneFromDateObj(filterParams.end_period_date, '23', '59');
  
        const response = await getAppointmentFieldsCombinedHistory({
          appointment_id: attendance?.id,
          min_date: minDateStr,
          max_date: maxDateStr,
        });
        setAppointmentFieldCombinedHistory(response.data);
      } catch (err) {
        console.error('fetchAppointmentFieldsCombinedHistory ~ ', err);
        setAppointmentFieldCombinedHistory([]);
      }
    };
  
    const handleSelectDateObj = (name, { yearSearchCursor, monthSearchCursor, daySearchCursor }) => {
      let nextDate = new Date(yearSearchCursor, parseInt(monthSearchCursor, 10) - 1, daySearchCursor)
      setFilterParams(prev => ({ ...prev, [name]: nextDate }))
      setSecondaryModal(MODAL_INIT_PROPS);
    }

    const openCalendarDateModal = (filterKey, modalTitle) => {
        const date_cursor = filterParams?.[filterKey];
        const currentDate = date_cursor ? new Date(date_cursor) : new Date();
    
        const currentDay = String(adicionaZero(currentDate.getDate()));
        const currentMonth = String(adicionaZero(currentDate.getMonth() + 1)); // Months are zero-indexed
        const currentYear = String(currentDate.getFullYear());
    
        setSecondaryModal(prev => ({
          ...prev,
          open: true,
          title: modalTitle,
          content: (
            <CalendarDateModal
              name={filterKey}
              currentDay={currentDay}
              currentMonth={currentMonth}
              currentYear={currentYear}
              renderNextSelectedDay={(dateEvent) => {
                handleSelectDateObj(filterKey, dateEvent);
                setSecondaryModal(MODAL_INIT_PROPS);
              }}
            />
          )
        }));
      };
    
      const openCalendarStartPeriodDateModal = () => {
        openCalendarDateModal('start_period_date', 'De (Data do Log)');
      };
    
      const openCalendarEndPeriodDateModal = () => {
        openCalendarDateModal('end_period_date', 'Até (Data do Log)');
      };
    
      // Função para lidar com a mudança do date_interval
      const handleDateIntervalChange = (event) => {
        const selectedInterval = event.target.selected;
        const today = new Date();
        const newStartDate = getStartDate(selectedInterval);
        const newEndDate = today; // Define a data final como hoje
    
        setFilterParams(prev => ({
          ...prev,
          date_interval: selectedInterval,
          start_period_date: newStartDate,
          end_period_date: newEndDate
        }));
    
        // Se precisar, pode disparar uma chamada para atualizar os dados aqui.
      };
    
    return (
        <div>
          <Modal {...secondaryModal} dismissFn={() => setSecondaryModal(MODAL_INIT_PROPS)} />
          <div className='LogsAppointmentsBookSection-Header'>
            <div className='LogsAppointmentsBookSection-Header-Tabs'>
              {/* Exemplo de aba para Prontuário */}
              <div
                className={`LogsAppointmentsBookSection-Header-Tabs-Btn ${openSection === 'records' ? 'active' : ''}`} 
                onClick={() => setOpenSection('records')}
              >
                Prontuário
              </div>
            </div>
            <div className='LogsAppointmentsBookSection-Header-Filters'>
              <NewSelect 
                label=''
                id='date_interval'
                onSelect={handleDateIntervalChange}
                selected={filterParams.date_interval}
                options={INTERVAL_DIFFERENCES_CHOICES}
                canBeEmpty={false}
              />
              <Input
                label=''
                name='start_period_date'
                placeholder='DD/MM/YYYY'
                actionFocus={openCalendarStartPeriodDateModal}
                value={formatISO8601Str(filterParams.start_period_date)}
              />
              <span> Para </span>
              <Input
                label=''
                name='end_period_date'
                placeholder='DD/MM/YYYY'
                actionFocus={openCalendarEndPeriodDateModal}
                value={formatISO8601Str(filterParams.end_period_date)}
              />
            </div>
        </div>
        <div className='LogsAppointmentsBookSection-Body'>
            <List 
              head={headDefault}
              data={appointmentFieldCombinedHistory}
              noData='Sem registros'
              listCustom={(instance, i) => {
                instance['h_changed_field'] = ACTIVE_FIELD_OPTIONS?.find(cursor => cursor?.id === instance?.field_type)?.name  || '-';
                instance['h_content'] = (() => {
                  if (instance?.previous_text && instance?.text) {
                    return instance.previous_text !== instance.text ? (
                      <div>
                        <span>Conteúdo novo:</span>
                        <p dangerouslySetInnerHTML={{ __html: instance.text }}></p>
                        <br />
                        <span>Conteúdo anterior:</span>
                        <p dangerouslySetInnerHTML={{ __html: instance.previous_text }}></p>
                      </div>
                    ) : (
                      <div>
                        <span>Conteúdo salvo:</span>
                        <p dangerouslySetInnerHTML={{ __html: instance.text }}></p>
                      </div>
                    );
                  }
                
                  if (instance?.text) {
                    return (
                      <div>
                        <span>Conteúdo salvo:</span>
                        <p dangerouslySetInnerHTML={{ __html: instance.text }}></p>
                      </div>
                    );
                  }
                
                  return '-';
                })();
                
                let tempHistoryDate = MaskDataHora(instance?.history_date)
                instance['h_date'] = tempHistoryDate?.data && tempHistoryDate?.hora
                  ? <span>{tempHistoryDate?.data} <br /> {tempHistoryDate?.hora}</span> 
                  : '-'
                instance['h_history_user'] = instance?.changed_by_email && instance?.changed_by_name
                  ? <span>{instance?.changed_by_name} <br /> {instance?.changed_by_email}</span>
                  : 'Sistema Salutho'
                return instance;
              }}
            />
        </div>
    </div>
    );
}