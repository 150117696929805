import isValidDateExcludingPastDates from "../../../../../../../../../../validators/isValidDateExcludingFutureDates";

export class MaterialAssignmentForm {
    #errors

    constructor(data) {
        Object.assign(this, data);

        this.material = data?.material || '';
        this.material_batch = data?.material_batch || ''
        this.reason = data?.reason || null;
        // this.type = data?.type || '';
        // this.day = data?.day || '';
        // this.month = data?.month || '';
        // this.year = data?.year || '';
        this.invoice = data?.invoice || '';
        // this.location = data?.location || null;
        this.requested_amount = typeof data?.requested_amount === 'number' ? data.requested_amount : null;
        this.patient = data?.patient || null;
        this.justification = data?.justification || '';
        this.health_place = data?.health_place || null;
        this.health_place_user = data?.health_place_user || null;

        // this.time = this.year && this.month && this.day
        //     ? `${this.year}-${String(this.month).padStart(2, '0')}-${String(this.day).padStart(2, '0')}`
        //     : "";

        this.#errors = {};
    }

    isValid() {
        const REQUIRED_FIELDS = ['reason', 'requested_amount'];
        
        Object.entries(this).forEach(([key, value]) => {
            if (REQUIRED_FIELDS.includes(key) && !value) {
                this.#errors[key] = true;
            }
        });

        if (this.requested_amount !== null && this.requested_amount <= 0) {
            this.#errors['requested_amount'] = 'Quantidade deve ser maior que zero.';
        }

        // if (this.reason === 'allocation_to_patient' && !this.patient) {
        //     this.#errors['patient'] = true;

        // }

        // if (!isValidDateExcludingPastDates(this.time)) {
        //     this.#errors['year'] = true;
        //     this.#errors['month'] = true;
        //     this.#errors['day'] = 'Data inválida';
        // }

        return Object.keys(this.#errors).length === 0;
    }

    getErrors() {
        return this.#errors;
    }
}