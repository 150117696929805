import './MaterialStockInfoModal.css'
import { useContext, useEffect, useRef, useState } from "react"
import { Input } from '../../../../../../../../component';
import MaterialBatchChart from './MaterialBatchChart/MaterialBatchChart';
import { IcoArrowBack, IcoArrowForward, Checkbox } from '../../../../../../../../component';
import { useApp } from '../../../../../../../../layout/App';
import MaterialFlowModal from '../MaterialFlowModal/MaterialFlowModal';
import BoxEnter from '../../../../../../../../component/icon/box_enter';
import { IcoClose } from '../../../../../../../../component';
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import { getMaterialBatch } from '../../../../http';
import NewSelect from '../../../../../../../../newcomponents/form/select/select';
import MaterialStockFilters from './MaterialStockFilters/MaterialStockFilters';
import Loading from '../../../../../../../../newcomponents/loading/loading'



export default function MaterialStockInfoModal({initMaterialData, expirationStatus, stockStatus, materialBatches, fetchMaterials, setModalInfo}) {
    const [index, setIndex] = useState(0);
    const [filteredMaterialBatches, setFilteredMaterialBatcher] = useState(materialBatches)
    const [showNewBatchForm, setShowNewBatchForm] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const itemsPerPage = 2;
    const carrouselRef = useRef(null);
    const totalPages = Math.ceil(filteredMaterialBatches.length / itemsPerPage);
    const currentHealthPlaceUser = useApp().currentHealthPlaceUser
    const [fetchBatchFilters, setFetchBatchFilters] = useState({
        material: initMaterialData.id,
        active: true,
        location__id: '',
        responsible__id: '',
        manufacturer__id: '',
        ordering: '',
    })

    const nextPage = () => {
        if (index < totalPages - 1) {
            setIndex(prevIndex => prevIndex + 1);
        }
    };

    const prevPage = () => {
        if (index > 0) {
            setIndex(prevIndex => prevIndex - 1);
        }
    };

    const handleToggleNewBatchForm = () => {
        setShowNewBatchForm(!showNewBatchForm)
    }

    const onSubmit = () => {
        fetchMaterials()
        setModalInfo(MODAL_INIT_PROPS)
    }

    const fetchMaterialBatches = async() => {
        setIsLoading(true)
        setIndex(0)
        const response = await getMaterialBatch(fetchBatchFilters)
        setFilteredMaterialBatcher(response.data.results)
        setIsLoading(false)
    }

    useEffect(() => {
        fetchMaterialBatches()
        
    },[fetchBatchFilters])

    return (
        <div className='MaterialStockInfoModal'>
            <div className='MaterialStockInfoModal-StockInfo'>
            <div className='MaterialStockInfoModal-StockInfo-Header'>
                <p className='MaterialStockInfoModal-Title' style={{margin: '0'}}>Informações do material:</p>
                <div className='MaterialStockInfoModal-StockInfo-Status'>
                    <div className='ListInventoryMaterials-Status-Subtitle' style={{...expirationStatus}}>{expirationStatus.label}</div>
                    <div className='ListInventoryMaterials-Status-Subtitle' style={{...stockStatus}}>{stockStatus.label}</div>
                </div>
            </div>   
            <div className='MaterialStockInfoModal-Form-Body'>
            <div className='MaterialStockInfoModal-Form-Inputs'>
                <div className='MaterialStockInfoModal-Input MaterialStockInfoModal-Input-2Col' title={initMaterialData?.name}>
                <span> Nome</span>
                <Input
                    name='name'
                    disabled={true}
                    value={initMaterialData?.name}
                    maxLength='100'
                />
                </div>
                <div className='MaterialStockInfoModal-Input MaterialStockInfoModal-Input-W'>
                <span> Código</span>
                <Input
                    name='code'
                    value={initMaterialData?.code || 'Não informado'}
                    maxLength='50'
                    disabled={true}
                />
                </div>
                <div className='MaterialStockInfoModal-Select MaterialStockInfoModal-Input-W'>
                <span> Categoria</span>
                <Input
                    id='category'
                    value={initMaterialData?.category?.name || 'Não informado'}
                    disabled={true}
                />
                </div>
                <div className='MaterialStockInfoModal-Select MaterialStockInfoModal-Input-W'>
                <span> Estoque mínimo para aviso</span>
                <Input
                    name='minimum_stock'
                    value={initMaterialData?.minimum_stock || 'Não informado'}
                    maxLength='4'
                    type='number'
                    min='0'
                    max='10000000'
                    placeholder='Ex.: 10, 25, 50'
                    disabled={true}
                />
                </div>
                <div className='MaterialStockInfoModal-Input MaterialStockInfoModal-Input-W'>
                <span> Aviso de vencimento (Dias)</span>
                <Input
                    name='expiration_notice'
                    value={initMaterialData?.expiration_notice || ''}
                    maxLength='4'
                    type='number'
                    min='0'
                    max='1095'
                    placeholder='Ex.: 10, 25, 50'
                    disabled={true}
                />
                </div>
            </div>
            </div>
            </div>
            {!isLoading ?
                (materialBatches.length > 0 && !showNewBatchForm ?
                
                <div className='MaterialStockInfoModal-Batches'>
                    <div className='MaterialStockInfoModal-Batches-Header'>
                        <div>
                            <p className='MaterialStockInfoModal-Title'>Lotes do material:</p>
                            <div className='MaterialStockInfoModal-NumberBox'>{filteredMaterialBatches.length}</div>
                            <div onClick={handleToggleNewBatchForm} title='Entrada de material' className='MaterialStockInfoModal-Batches-IconBox'>
                            <BoxEnter/>
                        </div>
                        </div>
                        <div>
                            <MaterialStockFilters fetchBatchFilters={fetchBatchFilters} setFetchBatchFilters={setFetchBatchFilters}/>
                        </div>
                    </div>
                    <div className='MaterialStockInfoModal-Batches-Carrousel-Container'>
                        <button className='MaterialStockInfoModal-Batches-Carrousel-Arrow' onClick={prevPage} disabled={index === 0}><IcoArrowBack/></button>
                        <div className='MaterialStockInfoModal-Batches-Carrousel-Wrapper'>
                            <div className='MaterialStockInfoModal-Batches-Carrousel'
                                ref={carrouselRef}
                                style={{ transform: `translateX(-${index * 100}%)` }}
                            >
                                {filteredMaterialBatches.map((batch, idx) => (
                                    <MaterialBatchChart key={idx} materialBatchInfo={batch} onSubmit={onSubmit} />
                                ))}
                            </div>
                        </div>
                        <button className='MaterialStockInfoModal-Batches-Carrousel-Arrow' onClick={nextPage} disabled={index >= totalPages - 1}><IcoArrowForward/></button>
                    </div>
                </div>
                : 
                <div>
                    {showNewBatchForm ? 
                    <div>
                        <div className='MaterialStockInfoModal-BatchForm-Header'>
                            <p className='MaterialStockInfoModal-Title'>Entrada de material:</p>
                            <div onClick={handleToggleNewBatchForm} title='Cancelar entrada de material' >
                                <IcoClose></IcoClose>
                            </div>
                        </div>
                        <MaterialFlowModal initMaterialData={initMaterialData} flowType={'entry'} fetchMaterials={fetchMaterials} onSubmit={onSubmit} setModalInfo={setModalInfo}></MaterialFlowModal>
                    </div>
                :
                    <div className='MaterialStockInfoModal-NoBatches'>
                        <p>Material sem estoque...</p>
                        <div onClick={handleToggleNewBatchForm} title='Entrada de material' className='MaterialStockInfoModal-NoBatches-IconBox'>
                            <BoxEnter/>
                        </div>
                    </div>
                }
                </div>
                )
            : 
                <div className='MaterialStockInfoModal-Loading-Container'>
                    <Loading></Loading>
                </div>
            }
            </div>
      );
}

