export default class Receipt {
    constructor(patient, attendance) {
        this.patient = {
            id: patient?.pk,
            name: patient?.name
        }
        this.category = attendance?.classification
        this.type = attendance?.result
        this.insurance = {
            id: 0, 
            name: attendance?.health_insurance || 'Nenhum'
        }
        this.procedures = []
        this.discount = 0
        this.paidByAnother = false
        this.payer = {
            name: '',
            document: ''
        }
        this.paymentMethod = 'CASH'
        this.expireDate = new Date()
        this.payOverTime = false
        this.upfrontValue = 0
        this.installmentsNumber = 1
        this.installments = [
            {
                value: 0,
                paid: false,
                paidDate: new Date()
            }
        ]
    }
}