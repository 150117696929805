import React from "react";
import ToolTip from "../../component/tooltip/tooltip";
import Button, { ButtonModel } from "../../newcomponents/button/button";
import { IcoAdd } from "../../component";

export default function FinanceAddButton({onClick}) {
    return <div className='finance-add-button'>

        <ToolTip message={"Adicionar"}>
            <Button 
                Icon={<IcoAdd></IcoAdd>}
                model={ButtonModel.ROUNDICON}
                onClick={() => onClick()}
            ></Button>
        </ToolTip>
    </div>
}