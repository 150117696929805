import './ListInventoryMaterials.css'
import React, { useState, useEffect } from "react";
import { IcoPencil, List, MaskDataHora, Paginate } from '../../../../../../component';
import { getInventoryMaterials } from '../../http';
import { useInventoryMaterials } from '../../InventoryMaterials';
import InsertInventoryMaterialsModal from './subcomponents/InsertInventoryMaterialsModal/InsertInventoryMaterialsModal';
import MaterialFlowModal from './subcomponents/MaterialFlowModal/MaterialFlowModal'
import NewRequestModal from './subcomponents/NewRequestModal/NewRequestModal';
import { getExpirationStatus, getExpirationSummaryStatus, getStockStatus } from '../../helpers';
import FilterInventoryParams from './subcomponents/FilterInventoryParams/FilterInventoryParams';
import IcoBoxBarcode from '../../../../../../component/icon/box_barcode';
import IcoBoxDelete from '../../../../../../component/icon/box_delete';
import IcoBoxEdit from '../../../../../../component/icon/box_edit';
import { BoxIcon } from '../../../../../../component';
import DeleteInventoryMaterialModal from './subcomponents/DeleteInventoryMaterialModal/DeleteInventoryMaterialModal';
import BoxEnter from '../../../../../../component/icon/box_enter';
import { getMaterialBatch } from '../../http';
import MaterialStockInfoModal from './subcomponents/MaterialStockInfoModal/MaterialStockInfoModal';
import Loading from '../../../../../../newcomponents/loading/loading';
import IcoBoxSearch from '../../../../../../component/icon/box-search';

const INIT_FILTER_PARAMS = { name__icontains: '', code__icontains: '', category: null, manufacturer: null, ordering: '', offset: 0 }

export default function ListInventoryMaterials() {
    const limit = 20
    const [materials, setMaterials] = useState([])
    const [filterParams, setFilterParams] = useState(INIT_FILTER_PARAMS)
    const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: limit, totalElements: 0 })
    const { setModalInfo, canReadAndWrite } = useInventoryMaterials()
    const [isLoading, setIsLoading] = useState(true)

    const fetchMaterials = async (params={}) => {
        setIsLoading(true)
        let res = await getInventoryMaterials({ ...params, limit, active: true })

        setMaterials(res.data.results)
        setPagination({
            totalElements: res.data.count, 
            totalPages: Math.ceil(res.data.count / limit),
            pageNumber: (params.offset / limit),
        })
        setIsLoading(false)
    }

    const handleNextPage = offset => {
		fetchMaterials({ ...filterParams, "offset": offset * limit })
	}

    const openEditInsertInventoryModal = (holdingInventoryMaterial) => {
        setModalInfo(prev => ({ ...prev, 
            open: true, 
            title: 'Editar material', 
            content: <InsertInventoryMaterialsModal initHoldingInventoryMaterial={holdingInventoryMaterial} fetchMaterials={fetchMaterials} openEditInsertInventoryModal={openEditInsertInventoryModal}/> 
        }))
    }

    const openMaterialFlowModal = (holdingInventoryMaterial) => {
        setModalInfo(prev => ({ ...prev, 
            open: true, 
            title: 'Entrada de material', 
            content: <MaterialFlowModal initMaterialData={holdingInventoryMaterial} flowType={'entry'} initHoldingInventoryMaterial={holdingInventoryMaterial} setModalInfo={setModalInfo} fetchMaterials={fetchMaterials}/> 
        }))
    }

    const handleViewMaterialStock = async (materialStock, expirationStatus, stockStatus) => {

    const response = await getMaterialBatch({material: materialStock.id})
    const materialBatches = response.data.results
        setModalInfo(prev => ({ ...prev, 
            open: true, 
            title: 'Estoque do material', 
            content: <MaterialStockInfoModal initMaterialData={materialStock} expirationStatus={expirationStatus} stockStatus={stockStatus} materialBatches={materialBatches} fetchMaterials={fetchMaterials} setModalInfo={setModalInfo}/>
        }))
        
    }

    

    return <div className='ListInventoryMaterials'>
        <FilterInventoryParams 
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            fetchMaterials={fetchMaterials} 
            setModalInfo={setModalInfo}
            INIT_FILTER_PARAMS={INIT_FILTER_PARAMS}
        />
        <div>
            {!isLoading ?
                <div className='ListInventoryMaterials-List-Container'>
                    <List 
                        head={
                            canReadAndWrite
                            ? [
                                { colunm: 'h_name', text: 'Material' },
                                { colunm: 'h_code', text: 'Código', className: 'ListInventoryMaterials-List-Code' },
                                { colunm: 'h_category', text: 'Categoria', className: 'ListInventoryMaterials-List-Category' },
                                { colunm: 'h_expiration_status', text: 'Status', width: '200px' },
                                { colunm: 'h_remaining', text: 'Restante', width: '200px' },
                                { colunm: 'h_action', text: <div className='ListInventoryMaterials-HeaderRow-Action'>Ações</div>, width: '200px'  }
                            ]
                            : [
                                { colunm: 'h_name', text: 'Material' },
                                { colunm: 'h_code', text: 'Código', className: 'ListInventoryMaterials-List-Code' },
                                { colunm: 'h_category', text: 'Categoria', className: 'ListInventoryMaterials-List-Category' },
                                { colunm: 'h_presentation', text: 'Apresentação', className: 'ListInventoryMaterials-List-Presentation' },
                                { colunm: 'h_expiration_status', text: 'Status', width: '200px' },
                                { colunm: 'h_remaining', text: 'Restante', width: '200px' },
                            ]
                        }
                        data={materials}
                        listCustom={custom => {
                            let cursor = custom
                            let expirationStatus = getExpirationSummaryStatus(cursor?.expiration_status)
                            let stockStatus = getStockStatus(cursor?.remaining, cursor?.minimum_stock)
                            cursor['h_name'] = cursor?.name || '-'
                            cursor['h_code'] = cursor?.code || '-'
                            cursor['h_category'] = cursor?.category?.name || '-'
                            cursor['h_manufacturer'] = cursor?.manufacturer?.name || '-'
                            cursor['h_expiration_date'] = MaskDataHora(cursor?.expiration_date, 'DD/MMM/AAAA', '-')?.data || '-'
                            cursor['h_expiration_status'] = <div className='ListInventoryMaterials-Status-Subtitle' style={{...expirationStatus}}>{expirationStatus.label}</div>
                            cursor['h_remaining'] = <div className='ListInventoryMaterials-Status-Subtitle' style={{...stockStatus}}>{stockStatus.label}</div>
                            custom['h_action'] = canReadAndWrite
                                ? <div className='ListInventoryMaterials-ActionRow'>

                                    <div 
                                        title='Entrada de material'
                                        onClick={() => openMaterialFlowModal(cursor)}                            
                                    >
                                        <BoxEnter style={{ width: '1.7rem', height: '1.7rem'}} />
                                    </div>
                                    <div
                                        title='Ver estoque do material'
                                        onClick={() => {handleViewMaterialStock(cursor, expirationStatus, stockStatus)}} 
                                    >
                                            <IcoBoxSearch style={{width: '1.6rem', height: '1.6rem', fill: 'none'}} />
                                        </div>
                                    <div 
                                        title='Editar material'
                                        onClick={() => openEditInsertInventoryModal(cursor)}                            
                                    >
                                        <IcoBoxEdit style={{ width: '1.5rem', height: '1.6rem', fill: 'none'}} />
                                    </div>
                                </div>
                                : null
                            return custom
                        }}
                    />
                    <div>
                        <Paginate data={pagination} action={handleNextPage} />
                    </div>
                </div>
            :   <div className='ListInventoryMaterials-Loading-Container'>
                    <Loading/>
                </div> 
            }
        </div>
    </div>
}