import './AttendanceObservations.css';

export default function AttendanceObservations({observations={}}) {
    
    return (
        <div className="Attendance-Observations-Container">
            <h1>Observações do agendamento:</h1>
            <div>{observations}</div>
        </div>
    );
}